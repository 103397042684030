import React from 'react';
import MarkdownView from 'react-showdown';
import Carousel from 'react-multi-carousel';

const CarouselImg = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      paritialVisibilityGutter: 50,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };
  return (
    <div className="landing-carousel-image carousel-w-image">
      <Carousel
        arrows={false}
        infinite={true}
        keyBoardControl
        centerMode={false}
        swipeable
        draggable
        showDots={true}
        containerClass="multi-carousel-container"
        sliderClass="multi-carousel-slider"
        itemClass="multi-carousel-item"
        responsive={responsive}
      >
        {data &&
          data.map((result, key) => (
            <div className="landing-carousel-image--item" key={key}>
              {result.image && (
                <img
                  src={process.env.STRAPI_URL + result.image.url}
                  className="landing-carousel-image--image"
                  alt={result.text}
                />
              )}
              {result.title && (
                <MarkdownView
                  markdown={result.title}
                  className="landing-carousel-image--title"
                />
              )}
              {result.text && (
                <MarkdownView
                  markdown={result.text}
                  className="landing-carousel-image--text"
                />
              )}
              {!result.title && <div style={{ height: '2em' }}></div>}
              {!result.text && <div style={{ height: '2em' }}></div>}
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default CarouselImg;
