import React from 'react';

const Slide = ({ label, previous, next }) => {
  return (
    <div className="slide">
      <button className="btn btn-left" onClick={previous}>
        <i className="fas fa-chevron-left" />
      </button>
      <span className="slide-text">{label}</span>
      <button className="btn btn-right" onClick={next}>
        <i className="fas fa-chevron-right" />
      </button>
    </div>
  );
};

export default Slide;
