import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ModalErrorPremiumMessage = ({ message, isOpen, toggle }) => {
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>{message}</ModalBody>
    </Modal>
  );
};

export default ModalErrorPremiumMessage;
