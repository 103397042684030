import React from 'react';

import Link from './Link';
import Book from './Book';
import ChapterBook from './ChapterBook';
import Paper from './Paper';
import Tesis from './Tesis';
import CapituloTesis from './CapituloTesis';
import Informe from './Informe';
import PaperConferencia from './PaperConferencia';
import Caso from './Caso';

const Quote = ({ type, ...rest }) => {
  switch (type) {
    case 'link':
      return <Link {...rest} />;
    case 'book':
      return <Book {...rest} />;
    case 'chapterbook':
      return <ChapterBook {...rest} />;
    case 'thesis':
      return <Tesis {...rest} />;
    case 'paper':
      return <Paper {...rest} />;
    case 'chapterthesis':
      return <CapituloTesis {...rest} />;
    case 'informe':
      return <Informe {...rest} />;
    case 'conferencepaper':
      return <PaperConferencia {...rest} />;
    case 'case':
      return <Caso {...rest} />;
    default:
      return null;
  }
};

export default Quote;
