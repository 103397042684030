import React from 'react';

import { TaskNumber } from '../task';
import Quote from './Quote';
import { CreativeCommonLogo } from '../layout';

const Title = ({ name }) => {
  return (
    <div className="research-more-card-header-title">
      {name.split(' ').length > 1 ? (
        <>
          <h5 className="text-center">{name.split(' ')[0]}</h5>
          <h5 className="bold text-center">
            {name
              .split(' ')
              .map((val, i) => {
                if (i === 0) {
                  return '';
                }
                return val;
              })
              .join(' ')}
          </h5>
        </>
      ) : (
        <h5 className="bold">{name}</h5>
      )}
    </div>
  );
};

const Card = ({ content, label, id }) => {
  const { bibliography, name, theme } = content;
  const cardTheme = theme === 'theme-black' ? 'theme-grey' : 'dark-' + theme;
  //Filtramos para separar los links
  const bibliography_notlink = bibliography.filter(
    filtered => filtered.type != 'link'
  );
  const bibliography_link = bibliography.filter(
    filtered => filtered.type === 'link'
  );
  return (
    <div className={`research-more-card ${cardTheme}`}>
      <div className="research-more-card-header">
        <TaskNumber
          active={id}
          prevColor={theme.replace('theme-', '')}
          nextColor={theme.replace('theme-', '')}
          activeColor={theme.replace('theme-', '')}
        />
        <Title name={name} />
      </div>
      <div className="research-more-card-body">
        <div className="w-100">
          <h4 className="research-more-card-body-label">
            {label.investigarMasLabel.title}
          </h4>
          <ul className="quotes">
            {bibliography_notlink &&
              bibliography_notlink.map((quote, i) => (
                <Quote key={i} {...quote} />
              ))}
          </ul>
          {bibliography_link ? (
            <div>
              <h4 className="research-more-card-body-label">Links</h4>
              <ul className="quotes">
                {bibliography_link.map((quote, i) => (
                  <Quote key={i} {...quote} />
                ))}
              </ul>
            </div>
          ) : null}
          <div className="logo-container">
            <CreativeCommonLogo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
