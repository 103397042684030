import React from 'react';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { Container } from '../components/contact';

const ContactoLayout = ({ data, loading, error, currentLang }) => {
  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <Container labels={data.labels} currentLang={currentLang} />
    );
  }

  return (
    content &&
    !loading && (
      <BaseLayout title="(defi)² - Restablecer contraseña">
        <div className="d-flex flex-column background-defi-default">
          <div className="password-recovery p-2">
            <Header to="/menu" previous="/login" logo="blanco" />
            {content}
          </div>
        </div>
      </BaseLayout>
    )
  );
};

export default ContactoLayout;
