import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import BaseLayout from './BaseLayout';
import { Error, Footer, Header, Loading } from '../components/layout';
import {
  Container,
  LanguageChanger,
  ModalErrorPremiumMessage,
} from '../components/menu';
import { useState } from 'react';

/**
 *
 * @param {Object} obj
 * @param {Boolean} obj.loading
 * @param {Object} obj.data
 * @param {String} obj.error
 * @param {Function} obj.onChangeLanguage
 * @param {String} obj.prevPath
 * @param {Object} obj.premiumData
 * @param {Boolean} obj.errorPremiumMessage
 * @param {Boolean} obj.premiumHasExpired
 */
const MenuLayout = ({
  loading,
  data,
  error,
  onChangeLanguage,
  prevPath,
  errorPremiumMessage,
  premiumHasExpired,
}) => {
  const [firsTimeShowModal, setFirsTimeShowModal] = useState(true);
  const [open, setOpen] = useState(false);
  const isLogged = useSelector(({ login }) => login.isLogged);

  const toggleErrorModal = () => setOpen(!open);

  // Check if premium account has expired so can show a message modal
  useEffect(() => {
    if (premiumHasExpired && firsTimeShowModal) {
      setOpen(true);
      setFirsTimeShowModal(false);
    }
  });

  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading />
      </div>
    ) : (
      <div className="container d-flex flex-column pt-5">
        <Container
          data={data.menu}
          glossary={data.glossary}
          menuStore={data.menuStore}
          loginMenu={data.loginMenu}
          isLogged={isLogged}
          config={data.config}
        />
        <LanguageChanger
          languages={data.languages}
          changeLanguage={onChangeLanguage}
          lastLang={data.currentLang}
        />
        <ModalErrorPremiumMessage
          isOpen={open}
          toggle={toggleErrorModal}
          message={errorPremiumMessage}
        />
      </div>
    );
  }

  return (
    <BaseLayout title="(defi)² - Menú">
      <div
        className="d-flex flex-column background-defi-default"
        style={{ minHeight: '90vh' }}
      >
        <Header to={prevPath} logo="blanco" isActive={true} />
        {content}
      </div>
      <Footer />
    </BaseLayout>
  );
};

export default MenuLayout;
