import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import BaseLayout from './BaseLayout';
import { Header, Loading, Error } from '../components/layout';
import {
  Indicator,
  Selector,
  Carousel,
  MessageModal,
} from '../components/steps';
import { ModalCongratulations } from '../components/task';
import { setActiveStep } from '../store/actions/step';
import { setActiveTask, setAccessLastTask } from '../store/actions/task';
import { api } from '../services/';
import { useRedirectStep } from '../hooks';

const EtapaLayout = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showStepModal, setShowStepModal] = useState(false);
  const [showCongratModal, setShowCongratModal] = useState(false);
  const dispatch = useDispatch();
  const activeTask = useSelector(({ task }) => task.activeTask);
  const lastTask = useSelector(({ task }) => task.lastTask);
  const lastTaskAccess = useSelector(({ task }) => task.lastTaskAccess);
  const stepStore = useSelector(store => store.step);
  const currentLang = useSelector(({ config }) => config.language);
  const { fetchStepData } = api();

  // Array of step unlocks
  const unlockedSteps = stepStore.unlockedSteps.map(step => step.stepId);
  // Array of task unlocks
  const unlockedTasks = stepStore.unlockedSteps
    .map(step => step.tasks)
    .reduce((obj, val) => [...obj, ...val], []);

  // Event handler
  const onStepChange = step => dispatch(setActiveStep(step));
  const onTaskChange = (taskId, stepId) => {
    const isUnlocked = unlockedTasks.includes(taskId);
    if (!isUnlocked) {
      const lastTaskId = Math.max(...unlockedTasks);
      dispatch(setActiveTask(lastTaskId));
    } else {
      const lastTaskId = Math.max(...unlockedTasks);
      if (taskId === lastTask && !lastTaskAccess) {
        toggleModalCongrat();
      }
      dispatch(setActiveTask(taskId));
      console.debug('taskId', lastTask, lastTaskId, taskId);
    }
    dispatch(setActiveStep(stepId));
    navigate(`/etapa/${stepId}/tarea/${taskId}`);
  };
  // toggle task modal
  const toggleTaskModal = () => {
    setShowTaskModal(!showTaskModal);
  };

  // toggle step modal
  const toggleStepModal = () => {
    setShowStepModal(!showStepModal);
  };

  // modifica el estado el modal de felicitaciones
  const toggleModalCongrat = () => {
    dispatch(setAccessLastTask());
    setShowCongratModal(!showCongratModal);
  };

  // check if step is available and redirect to last step
  useRedirectStep(props.stepId, props.taskId);

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        const response = await fetchStepData(currentLang, props.stepId);
        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [loading, currentLang, props.stepId, fetchStepData]);

  useEffect(() => {
    const stepId = parseInt(props.stepId, 10);
    const taskId = parseInt(props.taskId, 10);
    if (unlockedSteps.includes(stepId)) {
      dispatch(setActiveStep(stepId));
    }
    if (unlockedTasks.includes(taskId)) {
      dispatch(setActiveTask(taskId));
    }
  }, [props.taskId, props.stepId]);
  console.log(data);
  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <Fragment>
        <Indicator active={activeTask} unlocked={unlockedTasks} />
        <Carousel
          data={data.content}
          unlockedTasks={unlockedTasks}
          onTaskChange={onTaskChange}
          selectedSlide={props.taskId}
          toggleModal={toggleTaskModal}
        />
        <div className="d-flex flex-direction-row align-items-center justify-content-center">
          <Selector
            active={stepStore.activeStep}
            unlocked={unlockedSteps}
            onStepChange={onStepChange}
            toggleModal={toggleStepModal}
          />
        </div>
        <MessageModal
          showModal={showTaskModal}
          toggle={toggleTaskModal}
          label={data.label.modal}
        />
        <MessageModal
          showModal={showStepModal}
          toggle={toggleStepModal}
          label={data.label.stepModal}
        />
        <ModalCongratulations
          open={showCongratModal}
          toggle={toggleModalCongrat}
          labels={data.labels}
        />
      </Fragment>
    );
  }

  return (
    <BaseLayout title="(defi)² - Etapa">
      <section className="step-container background-step-default">
        <Header to="/menu" previous="/etapa" logo="amarillo" />
        <div className="container-fluid">
          <div>{content}</div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default EtapaLayout;
