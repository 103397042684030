import React from 'react';
import { Link } from 'gatsby';
import { stepsColors } from '../../constants';

const Selector = ({ unlocked, active, onStepChange, toggleModal }) => {
  const steps = stepsColors;

  return (
    <div className="step-selector-container">
      {steps.map((step, i) => {
        const claseBase = 'step-selector ';
        const link = unlocked.includes(step.number)
          ? `/etapa/${step.number}`
          : '#';
        const claseColor = unlocked.includes(step.number)
          ? 'step-' + step.color
          : 'step-grey';
        const claseActive =
          active === step.number ? ' step-selector-active' : '';

        const item = unlocked.includes(step.number) ? (
          <Link
            key={i}
            className={claseBase + claseColor + claseActive}
            to={link}
            onClick={() => onStepChange(step.number)}
          />
        ) : (
          <Link
            key={i}
            className={claseBase + claseColor + claseActive}
            to="#"
            onClick={toggleModal}
          />
        );
        return item;
      })}
    </div>
  );
};

export default Selector;
