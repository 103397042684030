import React from 'react';

const Tesis = props => {
  const { author, year, title, university, url } = props;
  return (
    <li>
      <div className="quote">
        <span className="thesis">
          {
            // autor
            author && author
          }
          {
            // año
            year && ` (${year}). `
          }
          {
            // titulo
            title && <em>{`${title}, `}</em>
          }
          {
            // university
            university && `${university}. `
          }
        </span>
        {
          // url
          url && (
            <a className="quote-url" href={url}>
              Link
            </a>
          )
        }
      </div>
    </li>
  );
};

export default Tesis;
