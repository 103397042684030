import React from 'react';
import MarkdownView from 'react-showdown';

const Lists = ({ data }) => {
  return (
    <div className="landing-list">
      <ul className="landing-list--list list-unstyled">
        {data &&
          data.map((res, key) => (
            <li className="text-left" key={key}>
              <div className="icon-why">
                {res.icon && (
                  <img
                    src={process.env.STRAPI_URL + res.icon.url}
                    className="mx-auto d-block"
                    alt="why list"
                  />
                )}
              </div>
              <div className="text-why text-left font-weight-bold">
                <MarkdownView markdown={res.title} className="pt-1" />
              </div>
              <div className="text-why">
                <MarkdownView markdown={res.content} className="pt-1" />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Lists;
