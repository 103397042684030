import React from 'react';

const Logo = () => {
  return (
    <div className="splash-logo-container">
      <picture className="splash-logo">
        <img src="/img/logo_defi_splash.svg" alt="Logo (defi)²" />
      </picture>
    </div>
  );
};

export default Logo;
