import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';

import TaskCard from './TaskCard';

const StepCarousel = ({
  data,
  unlockedTasks,
  onTaskChange,
  selectedSlide,
  toggleModal,
}) => {
  //Simulamos todas las tareas desbloqueadas
  // unlockedTasks = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41]
  const [currentClassCarousel, setCurrentClassCarousel] = useState(
    'carousel-loading-disable'
  );
  const carouselRef = useRef(null);
  const responsive = {
    xxlarge: {
      breakpoint: { max: 3000, min: 1920 },
      items: 9,
      partialVisibilityGutter: 20,
    },
    xlarge: {
      breakpoint: { max: 1920, min: 1440 },
      items: 7,
      partialVisibilityGutter: 20,
    },
    large: {
      breakpoint: { max: 1440, min: 1024 },
      items: 5,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  const beforeChange = nextSlide => {
    //Esta función se puede usar para gatillar algo cuando cambie la tarjeta
    //Adicionalmente, se le podría pasar como propiedad
    onTaskChange(nextSlide + 1, parseInt(data[nextSlide].step.id, 10)); // taskId, stepId
  };

  useEffect(() => {
    if (selectedSlide) {
      const currentSlideIndex = parseInt(selectedSlide, 10) - 1;
      carouselRef.current.goToSlide(currentSlideIndex);
    }
  }, [selectedSlide]);

  useEffect(() => {
    const classCarousel =
      parseInt(selectedSlide, 10) - 1 === carouselRef.current.state.currentSlide
        ? 'carousel-loading-enable'
        : 'carousel-loading-disable fa-spin';
    setCurrentClassCarousel(classCarousel);
  }, [data]);

  return (
    <Carousel
      className={currentClassCarousel}
      responsive={responsive}
      partialVisible={false}
      arrows={true}
      keyBoardControl={true}
      swipeable={true}
      showDots={false}
      beforeChange={beforeChange}
      ref={carouselRef}
    >
      {data &&
        data.map(({ task, step }) => (
          <TaskCard
            key={task.taskNumber}
            icon={task.icon}
            name={task.name}
            currentTask={selectedSlide}
            taskNumber={task.taskNumber}
            isAvailable={unlockedTasks.includes(task.taskNumber)}
            stepId={step.id}
            theme={step.theme}
            toggleModal={toggleModal}
          />
        ))}
    </Carousel>
  );
};

export default StepCarousel;
