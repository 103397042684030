import React from 'react';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { CheckInForm } from '../components/checkIn';

const RegistroLayout = ({ labels, loading, error, currentLang }) => {
  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <CheckInForm labels={labels} currentLang={currentLang} />
    );
  }
  return (
    <BaseLayout title="(defi)² - Registro de usuario">
      <div className="container-fluid registro background-defi-default ">
        <div className="password-recovery">
          <Header to="/menu" previous="/registro" logo="blanco" />
          {content}
        </div>
      </div>
    </BaseLayout>
  );
};

export default RegistroLayout;
