import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { Indicator } from '../components/steps';
import { TaskCard } from '../components/task';

const TareaLayout = ({ id, data, loading, error }) => {
  const taskStore = useSelector(store => store.task);
  const stepStore = useSelector(store => store.step);

  // Array of task unlocks
  const unlockedTasks = stepStore.unlockedSteps
    .map(step => step.tasks)
    .reduce((obj, val) => [...obj, ...val], []);

  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <div>
        <Indicator active={data.taskNumber} unlocked={unlockedTasks} />
        <TaskCard data={data} />
      </div>
    );
  }
  return (
    <BaseLayout title={data ? `(defi)² - ${data.name}` : '(defi)² - tarea'}>
      <section className="step-container background-step-default">
        <Header to="/menu" previous={`/tarea/${id}`} logo="amarillo" />
        <div className="container-fluid">{content}</div>
      </section>
    </BaseLayout>
  );
};

export default TareaLayout;
