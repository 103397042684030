import React, { useState } from 'react';

const DefiPay = ({ data, labels, loading }) => {
  const DateF = ({ date }) => {
    const dt = new Date(date);
    console.debug('date', date, dt);
    return (
      <span>
        {`${dt.getDate().toString().padStart(2, '0')}-${(dt.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${dt
          .getFullYear()
          .toString()
          .padStart(4, '0')} ${dt
          .getHours()
          .toString()
          .padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`}
      </span>
    );
  };
  return (
    <>
      {data ? (
        <>
          <div className="container justify-content-center result-payment pt-1 pl-4 pr-4 pb-4">
            <div
              className={`font-weight-bold text-center status-payment-img status-payment-${
                data.status_pay && data.status_pay.id
              }`}
            />
            <h2 className="text-center font-weight-bold">
              {data.status_pay &&
                data.status_pay.id === '1' &&
                labels.textPendingPayment}
              {data.status_pay &&
                data.status_pay.id === '2' &&
                labels.textCanceledPayment}
              {data.status_pay &&
                data.status_pay.id === '3' &&
                labels.textRejectedPayment}
              {data.status_pay &&
                data.status_pay.id === '4' &&
                labels.textAcceptedPayment}
            </h2>

            {data.status_pay.id === '4' && labels.subTitlePayment ? (
              <h6 className="text-center">{labels.subTitlePayment}</h6>
            ) : (
              ''
            )}

            {data.status_pay.id === '4' && (
              <>
                <div className="row justify-content-center">
                  <div className="col-10 col-lg-7 name-data pl-0 pt-1 pb-0">
                    {labels.idPayment ? labels.idPayment : 'NULL'}:
                  </div>
                  <div className="col-10 col-lg-7 value-data pl-0 pt-0 pb-1 border-value">
                    {data ? data.id_pay : ''}
                  </div>
                </div>
                {/* <div className="row justify-content-center">
                <div className="col-md-10 col-lg-4 name-data pl-0 pt-1 pb-0">
                  {labels.idSubscription
                    ? labels.idSubscription
                    : 'idSubscription'}:
                </div>
                <div className="col-md-10 col-lg-6 value-data pl-0 pt-0 pb-1 border-value">
                  {data ? data.id_subscription : ''}
                </div>
              </div> */}
                <div className="row justify-content-center">
                  <div className="col-10 col-lg-7 name-data pl-0 pt-1 pb-0">
                    {labels.datePayment ? labels.datePayment : 'NULL'}:
                  </div>
                  <div className="col-10 col-lg-7 value-data pl-0 pt-0 pb-1 border-value">
                    {data ? <DateF date={data.date_pay} /> : ''}
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 col-lg-7 name-data pl-0 pt-1 pb-0">
                    {labels.amountPayment ? labels.amountPayment : 'NULL'}:
                  </div>
                  <div className="col-10 col-lg-7 value-data pl-0 pt-0 pb-1 border-value">
                    {/* monto definido por el medio de pago, es el monto real pagado no el del plan */}
                    {data ? data.amount : ''}
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 col-lg-7 name-data pl-0 pt-1 pb-0">
                    {labels.methodPayment ? labels.methodPayment : 'NULL'}:
                  </div>
                  <div className="col-10 col-lg-7 value-data pl-0 pt-0 pb-1 border-value">
                    {data ? data.type_pay.name : ''}
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 col-lg-7 name-data pl-0 pt-1 pb-0">
                    {labels.statusPayment ? labels.statusPayment : 'NULL'}:
                  </div>
                  <div className="col-10 col-lg-7 value-data pl-0 pt-0 pb-1">
                    {data ? data.status_pay.status : ''}
                  </div>
                </div>
              </>
            )}
            {/* <div className="row justify-content-center">
              <div className="col-md-10 col-lg-4 name-data">
                {labels.userLimitPayment ? labels.userLimitPayment : 'NULL'}
              </div>
              <div className="col-md-10 col-lg-6 value-data">
                {data ? data.id_plan.user_limit : ''}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-4 name-data">
                {labels.monthDurationPayment
                  ? labels.monthDurationPayment
                  : 'NULL'}
              </div>
              <div className="col-md-10 col-lg-6 value-data">
                {data ? data.id_plan.months_of_duration : ''}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-4 name-data">
                {labels.startDatePayment ? labels.startDatePayment : 'NULL'}
              </div>
              <div className="col-md-10 col-lg-6 value-data">
                {data && data.start_date ? (
                  <DateF date={data.start_date} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-4 name-data">
                {labels.endDatePayment ? labels.endDatePayment : 'NULL'}
              </div>
              <div className="col-md-10 col-lg-6 value-data">
                {data && data.end_date ? <DateF date={data.end_date} /> : ''}
              </div>
            </div> */}
            {/* si ocurrio un problema muestra mensaje de contacto con la administración en caso de dudas */}
            {data.status_pay && data.status_pay.id !== '4' && (
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-6 text-center m-3 p-4 footer-data-pay">
                  <p>
                    {labels.footerProblemPayment
                      ? labels.footerProblemPayment
                      : 'footerProblemPayment'}
                  </p>
                </div>
                <a
                  className="btn-general-yellow pt-2 pb-2 pl-4 pr-4"
                  href="/planes"
                >
                  {labels.paymentBack ? labels.paymentBack : 'paymentBack'}
                </a>
              </div>
            )}
            {data.status_pay && data.status_pay.id === '4' && (
              <div className="row justify-content-center pt-2">
                <div className="col-md-10 col-lg-6 text-center">
                  <a
                    className="btn-general-yellow pt-2 pb-2 pl-4 pr-4"
                    href="#"
                    onClick={() => window.print()}
                  >
                    {labels.paymentPrint ? labels.paymentPrint : 'paymentPrint'}
                  </a>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="container">
          <div className="row justify-content-center result-payment">
            <div className="col-md-10 col-lg-6 text-center p-4 no-data-pay">
              <p>
                {labels.noDataPayment ? labels.noDataPayment : 'noDataPayment'}
              </p>
              <a className="btn-general-white p-2" href="/planes">
                {labels.noDataPaymentBack
                  ? labels.noDataPaymentBack
                  : 'noDataPaymentBack'}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DefiPay;
