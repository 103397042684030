import React from 'react';
import MarkdownView from 'react-showdown';
import { Link } from 'gatsby';

const ready = ({ interfaces, data }) => {
  const { title, sub_title } = data;
  return (
    <>
      <div className="ready p-3">
        <div className="h4 text-center">{title}</div>
        <div className="text-center">
          <MarkdownView
            markdown={sub_title}
            className="before-starting-content"
          />
        </div>
      </div>

      {/* botones de redireccion */}
      <div className="row justify-content-center p-2">
        <div className="col-4 col-md-2 text-center">
          <Link to="/etapa" className="btn-general-white p-2">
            {interfaces.beginBtn || 'beginBtn'}
          </Link>
        </div>
        <div className="col-4 col-md-2 text-center">
          <Link to="/planes" className="btn-general-yellow p-2">
            {interfaces.viewPlansBtn || 'viewPlansBtn'}
          </Link>
        </div>
      </div>

      <hr className="landing-hr m-2 p-1" />
    </>
  );
};

export default ready;
