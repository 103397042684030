import React from 'react';

const PaperConferencia = props => {
  const {
    author,
    year,
    title,
    conference_name,
    city,
    state,
    country,
    date,
    published_place,
    editorial,
    url,
  } = props;
  return (
    <li>
      <div className="quote">
        <span className="conference-paper">
          {
            // autor
            author && author
          }
          {
            // año
            year && ` (${year}). `
          }
          {
            // titulo
            title && <em>{`${title}, `}</em>
          }
          {
            // nombre de la conferencia
            conference_name && `${conference_name}. `
          }
          {
            // ciudad
            city && `${city}, `
          }
          {
            // estado
            state && `${state}, `
          }
          {
            // pais
            country && `${country}, `
          }
          {
            // date
            date && `${date}, `
          }
          {
            // lugar de publicacion
            published_place && `${published_place}: `
          }
          {
            // casa editorial
            editorial && `${editorial}.`
          }
        </span>
        {
          // url
          url && (
            <a className="quote-url" href={url}>
              Link
            </a>
          )
        }
      </div>
    </li>
  );
};

export default PaperConferencia;
