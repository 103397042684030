import React from 'react';
import TitleIcon from './TitleIcon';

const Section = ({ title, icon, theme }) => {
  return (
    <div className="task-card-section">
      <TitleIcon icon={icon} theme={theme} />
      <span>{title}</span>
    </div>
  );
};

export default Section;
