import React from 'react';

const Image = ({ url, width = 80, height = 80 }) => {
  return (
    <div className="container pb-4 pt-2">
      <div className="justify-content-md-center">
        <div className="mx-auto">
          {url && (
            <img
              src={process.env.STRAPI_URL + url}
              className="mx-auto d-block"
              alt=""
              style={{ height: `${height}%`, width: `${width}%` }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Image;
