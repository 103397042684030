import React from 'react';
import { Link } from 'gatsby';

const Button = ({ link, label }) => {
  return (
    <div className="splash-btn-container">
      <Link className="btn splash-btn" to="/antes-de-comenzar">
        <span>{label}</span>
      </Link>
    </div>
  );
};

export default Button;
