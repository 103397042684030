import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'gatsby';

import { useLoginForm } from '../../hooks';

const LoginForm = ({ label, toggleModal }) => {
  const {
    title,
    subtitle,
    forgot,
    email,
    password,
    submitButton,
    error,
    noAccount,
    registerLink,
  } = label;
  const { inputs, showError, handleSubmit, handleInputChange } = useLoginForm({
    email: '',
    password: '',
  });

  return (
    <div className="login-form-container">
      <h2>{title.label}</h2>
      <p>{subtitle.label}</p>
      {showError && (
        <div className="alert alert-danger">
          <small className="form-text text-danger">
            <b>ERROR:</b> {error.label}
          </small>
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>{email.label}</Label>
          <Input
            type="email"
            className=""
            name="email"
            aria-describedby="emailHelp"
            value={inputs.email}
            onChange={handleInputChange}
            invalid={showError}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>{password.label}</Label>
          <Input
            type="password"
            className=""
            name="password"
            aria-describedby="descripcionContra"
            value={inputs.password}
            onChange={handleInputChange}
            invalid={showError}
            required
          />
        </FormGroup>
        <div className="login-btn-container">
          <Button type="submit" className="btn-general-white text-uppercase">
            {submitButton.label}
          </Button>
        </div>
      </Form>
      <div className="registro-terms">
        <p className="pt-3 m-0">
          {noAccount.label}&nbsp;
          <Link to="/registro">{registerLink.label}</Link>
        </p>
      </div>
      <Link
        to="?passwordRecovery=true"
        className="login-recovery"
        onClick={toggleModal}
      >
        {forgot.label}
      </Link>
    </div>
  );
};

export default LoginForm;
