import React, { useEffect, useState, useRef } from 'react';
import Carousel from 'react-multi-carousel';

import Button from './Button';
import Content from './Content';
import Slide from './Slide';

const SplashCarousel = ({ data, onChangeLanguage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [btnLabel, setBtnLabel] = useState('');
  const [slideLabel, setSlideLabel] = useState('');
  const carouselRef = useRef(null);

  const setStuff = index => {
    const currentElement = data[index];
    setSlideLabel(currentElement.interfaces.slide);
    setBtnLabel(currentElement.interfaces.btn);
    onChangeLanguage(currentElement.language.abbr);
    setActiveIndex(index);
  };

  const next = () => {
    const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1;
    setStuff(nextIndex);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1;
    setStuff(nextIndex);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const beforeChange = (nextSlide, { currentSlide }) => {
    if (nextSlide > currentSlide) {
      next();
    } else {
      previous();
    }
  };

  useEffect(() => {
    setStuff(activeIndex);
  }, [activeIndex]);

  return (
    <div className="carouselContainer">
      <Carousel
        arrows={false}
        infinite={true}
        keyBoardControl
        centerMode={false}
        swipeable
        draggable
        showDots={false}
        containerClass="multi-carousel-container"
        sliderClass="multi-carousel-slider"
        itemClass="multi-carousel-item"
        responsive={responsive}
        beforeChange={beforeChange}
        ref={carouselRef}
      >
        {Array.isArray(data) &&
          data.map(({ contents }, i) => (
            <Content
              key={i}
              title={contents.title}
              paragraph={contents.paragraph}
            />
          ))}
      </Carousel>
      <Slide
        label={slideLabel}
        previous={() => {
          carouselRef.current.previous();
          previous();
        }}
        next={() => {
          carouselRef.current.next();
          next();
        }}
      />
      <Button label={btnLabel} />
    </div>
  );
};

export default SplashCarousel;
