import React from 'react';
import MarkdownView from 'react-showdown';
import VideoExample from '../Video';

const LetterIndicator = ({ letter, theme, last }) => {
  return (
    <div className="letter-indicator-container">
      <span className={`letter-indicator-icon ${theme}`}>{letter}</span>
      {letter != last ? <hr className="letter-indicator-line" /> : null}
    </div>
  );
};
const LetterNote = ({ note }) => {
  return (
    <div className="step-note">
      {/* <p> */}
      <img className="" src="/img/alerta.svg" alt="alerta nota" />
      {/* </p> */}
      <MarkdownView className="detail-task-section-content" markdown={note} />
    </div>
  );
};

const StepCard = ({
  letter,
  title,
  content,
  note,
  theme,
  last,
  url_video,
  intro_image,
  credit_image,
  subtitle_video,
  title_video_pro,
  url_video_pro,
  url_subtitle_video_pro,
  image_intro_video_pro,
  image_ending_video_pro,
  replace_free_video_pro,
  currentLang,
  proRole,
}) => {
  const letterTheme = theme ? theme : 'theme-grey';
  return (
    <>
      <div className="step-card-container">
        <LetterIndicator letter={letter} theme={letterTheme} last={last} />
        <div>
          <h5 className="step-card-title">{title}</h5>
          <MarkdownView className="step-content" markdown={content} />
          {((url_video && !proRole) ||
            (url_video && proRole && !replace_free_video_pro)) && (
            <VideoExample
              url={url_video}
              img_intro={intro_image}
              img_credit={credit_image}
              subtitle_url={subtitle_video}
            />
          )}
          {proRole && url_video_pro && (
            <>
              {title_video_pro && <h4>{title_video_pro}</h4>}

              <VideoExample
                url={url_video_pro}
                img_intro={image_intro_video_pro && image_intro_video_pro.url}
                img_credit={
                  image_ending_video_pro && image_ending_video_pro.url
                }
                subtitle_url={url_subtitle_video_pro}
                lang={currentLang}
              />
            </>
          )}
          {note && <LetterNote note={note} />}
        </div>
      </div>
    </>
  );
};

export default StepCard;
