import React, { Fragment } from 'react';
import { Link } from 'gatsby';

import { CreativeCommonLogo } from '../layout';
import { TaskNumber } from '../task/';
import Title from './Title';
import Section from './Section';
import Block from './Block';
import RelationalsTask from './RelationalsTask';
import HowTo from './HowTo';

import VideoExample from './Video';

const Container = ({
  label,
  content,
  contentPro,
  unlockedTasks,
  toggleModal,
  currentLang,
  proRole,
}) => {
  const showRelations = false;
  const cardTheme =
    content.theme === 'theme-black' ? 'theme-grey' : content.theme;
  const howToLabel = {
    title: label.howToTitle,
    discoverButton: label.howToDiscoverButton,
    nextButton: label.howToNextButton,
  };
  const contenidoPro = contentPro;

  return (
    <div className={` detail-task-card ${cardTheme}`}>
      <div className="detail-task-card-header">
        <TaskNumber
          active={content.numberTask}
          prevColor={content.theme.replace('theme-', '')}
          nextColor={content.theme.replace('theme-', '')}
          activeColor={content.theme.replace('theme-', '')}
        />
        {contenidoPro &&
          contenidoPro.length > 0 &&
          contenidoPro.map((pro, key) => (
            <Link
              key={key}
              className="contenido-pro-btn"
              to={`/contenido-premium/${pro.uid_title}`}
            >
              {pro.title}
            </Link>
          ))}
        <img
          className="detail-task-card-header-icon"
          src={content.icon}
          alt={content.name}
        />
        <Title name={content.name} />
      </div>
      <div className="container detail-task-card-body">
        {content.sections.whatIs && content.sections.whatIs !== 'NULL' && (
          <Section
            title={label.whatIs}
            icon="quees"
            theme={cardTheme}
            content={content.sections.whatIs}
          />
        )}
        {content.sections.forWhat && content.sections.forWhat !== 'NULL' && (
          <Fragment>
            <Section
              title={label.forWhat}
              icon="paraque"
              theme={cardTheme}
              content={content.sections.forWhat}
            />
            {((content.sections.forWhatVideo && !proRole) ||
              (content.sections.forWhatVideo &&
                proRole &&
                (!content.sections.forWhatVideoPro ||
                  !content.sections.forWhatVideoPro.replace_free))) && (
              <VideoExample
                url={content.sections.forWhatVideo}
                img_intro={content.sections.forWhatImgIntro}
                img_credit={content.sections.forWhatImgCredit}
                subtitle_url={content.sections.forWhatVideoSubtitle}
                lang={currentLang}
              />
            )}
            {proRole && content.sections.forWhatVideoPro && (
              <>
                {content.sections.forWhatVideoPro &&
                  content.sections.forWhatVideoPro.title && (
                    <h4>{content.sections.forWhatVideoPro.title}</h4>
                  )}
                <VideoExample
                  url={content.sections.forWhatVideoPro.url_video}
                  img_intro={
                    content.sections.forWhatVideoPro.image_intro &&
                    content.sections.forWhatVideoPro.image_intro.url
                  }
                  img_credit={
                    content.sections.forWhatVideoPro.image_ending &&
                    content.sections.forWhatVideoPro.image_ending.url
                  }
                  subtitle_url={content.sections.forWhatVideoPro.url_subtitle}
                  lang={currentLang}
                />
              </>
            )}
          </Fragment>
        )}
        {content.sections.expectedResults &&
          content.sections.expectedResults !== 'NULL' && (
            <Fragment>
              <Section
                title={label.expectedResults}
                icon="resultados"
                theme={cardTheme}
                content={content.sections.expectedResults}
              />
              {((content.sections.expectedResultsVideo && !proRole) ||
                (content.sections.expectedResultsVideo &&
                  proRole &&
                  (!content.sections.expectedResultsVideoPro ||
                    !content.sections.expectedResultsVideoPro
                      .replace_free))) && (
                <VideoExample
                  url={content.sections.expectedResultsVideo}
                  img_intro={content.sections.expectedResultsImgIntro}
                  img_credit={content.sections.expectedResultsImgCredit}
                  subtitle_url={content.sections.expectedResultsVideoSubtitle}
                  lang={currentLang}
                />
              )}
              {proRole && content.sections.expectedResultsVideoPro && (
                <>
                  {content.sections.expectedResultsVideoPro &&
                    content.sections.expectedResultsVideoPro.title && (
                      <h4>{content.sections.expectedResultsVideoPro.title}</h4>
                    )}
                  <VideoExample
                    url={content.sections.expectedResultsVideoPro.url_video}
                    img_intro={
                      content.sections.expectedResultsVideoPro.image_intro &&
                      content.sections.expectedResultsVideoPro.image_intro.url
                    }
                    img_credit={
                      content.sections.expectedResultsVideoPro.image_ending &&
                      content.sections.expectedResultsVideoPro.image_ending.url
                    }
                    subtitle_url={
                      content.sections.expectedResultsVideoPro.url_subtitle
                    }
                    lang={currentLang}
                  />
                </>
              )}
            </Fragment>
          )}
        <div className="detail-task-block-container resources-time-people-container">
          <div className="resources-container">
            <Block
              title={label.resources}
              icon="resource"
              content={content.block.resources}
            />
          </div>
          <div className="detail-task-block-line">&nbsp;</div>
          <div className="detail-task-block-divisor">
            <div className="time-people-container">
              <Block
                title={label.time}
                icon="time"
                content={content.block.time}
              />
              <Block
                title={label.people}
                icon="people"
                content={content.block.people}
              />
            </div>
          </div>
        </div>
        {showRelations ? (
          <RelationalsTask
            title={label.relationalTasksTitle}
            subTitle={label.relationalTasksSubTitle}
            carousel={content.relationalsTasks}
            unlockedTasks={unlockedTasks}
            toggleModal={toggleModal}
          />
        ) : null}
        {content.howTo.length > 0 && (
          <HowTo
            content={content.howTo}
            interface={howToLabel}
            theme={content.theme}
            currentLang={currentLang}
            proRole={proRole}
          />
        )}
        {content.no_infographic && (
          <img
            className="resources-container-image p-4 d-none d-lg-block"
            src={process.env.STRAPI_URL + content.no_infographic.url}
            alt="Infographic desktop"
          />
        )}
        {content.infographic_mobile && (
          <img
            className="resources-container-image p-4 d-block d-lg-none"
            src={process.env.STRAPI_URL + content.infographic_mobile.url}
            alt="Infographic mobile"
          />
        )}
      </div>
      <div className="detail-task-card-footer">
        <div className="detail-task-card-footer-button-container">
          <Link
            className={`footer-button-icon ${cardTheme}`}
            to={`/tarea/${content.numberTask}/investigar-mas`}
          />
          <span className="footer-button-label">{label.footerTitle}</span>
        </div>
        <div className={`detail-task-card-footer-body dark-${cardTheme}`}>
          <CreativeCommonLogo />
        </div>
      </div>
    </div>
  );
};

export default Container;
