import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

const MessageModal = ({ label, showModal, toggle, displayHeader = false }) => {
  return (
    <Modal className="message-modal" isOpen={showModal} toggle={toggle}>
      {displayHeader ? <ModalHeader toggle={toggle} /> : null}
      <ModalBody>
        {label}
        <Button className="btn modal-close-btn" onClick={toggle}>
          Cerrar
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default MessageModal;
