import React from 'react';
import StepsSvgMobile from './StepsSvg.mobile';
import StepsSvgDesktop from './StepsSvg.desktop';

const Indicator = ({ active, unlocked }) => {
  return (
    <div className="step-indicator">
      <StepsSvgMobile active={active} unlocked={unlocked} />
      <StepsSvgDesktop active={active} unlocked={unlocked} />
    </div>
  );
};

export default Indicator;
