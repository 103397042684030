import React from 'react';
import { Link } from 'gatsby';

const Buttons = ({ data }) => {
  return (
    <div className="landing-buttons">
      {data &&
        data.map((btn, key) => (
          <div className="landing-buttons--button" key={key}>
            <Link
              to={btn.link}
              className={`p-3 ${
                btn.color ? 'btn-general-yellow' : 'btn-general-white'
              }`}
            >
              {btn.label || 'beginBtn'}
            </Link>
          </div>
        ))}
    </div>
  );
};

export default Buttons;
