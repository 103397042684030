import React from 'react';
import MarkdownView from 'react-showdown';
import { WhyList } from './index';

const Why = ({ data }) => {
  const { title, sub_title, why_list } = data;
  return (
    <div className="why p-3">
      <div className="h4 text-center">{title}</div>
      <div className="text-center">
        <MarkdownView
          markdown={sub_title}
          className="before-starting-content"
        />
      </div>
      <hr className="landing-hr m-2 p-1" />
      {why_list && why_list.length > 0 && <WhyList data={why_list} />}
    </div>
  );
};

export default Why;
