import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import MarkdownView from 'react-showdown';

import { usePasswordRecoveryForm } from '../../hooks';

const Container = ({ label, code, currentLang }) => {
  const {
    inputs,
    message,
    handleInputChange,
    handleSubmit,
  } = usePasswordRecoveryForm(code, currentLang, label);
  return (
    <div className="password-recovery-container">
      <h2 className="text-center">{label ? label.title : 'title'}</h2>
      <Form onSubmit={handleSubmit}>
        <Label>{label ? label.password : 'NULL'}</Label>
        <FormGroup>
          <Input
            type="password"
            name="password"
            required
            value={inputs.password}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>{label ? label.passwordConfirmation : ''}</Label>
          <Input
            type="password"
            name="passwordConfirmation"
            value={inputs.passwordConfirmation}
            onChange={handleInputChange}
          />
        </FormGroup>
        <Input type="hidden" name="code" value={code} />
        <div className="text-center">
          <Button type="submit" className="btn-general-white text-uppercase">
            {label ? label.button : ''}
          </Button>
        </div>
      </Form>
      {message && (
        <div className="alert alert-danger p-2 mt-2">
          <MarkdownView
            markdown={message}
            className="static-content-container"
          />
        </div>
      )}
    </div>
  );
};

export default Container;
