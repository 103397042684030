import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { BackButton, Card } from '../components/detailTask';
import { MessageModal } from '../components/steps';

const DetalleTareaLayout = ({
  id,
  data,
  loading,
  error,
  currentLang,
  proRole,
}) => {
  const [showModal, setShowModal] = useState(false);
  const stepStore = useSelector(store => store.step);

  // Array of task unlocks
  const unlockedTasks = stepStore.unlockedSteps
    .map(step => step.tasks)
    .reduce((obj, val) => [...obj, ...val], []);

  // Toggle modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <Fragment>
        <BackButton id={id} />
        <Card
          label={data.label}
          content={data.content}
          unlockedTasks={unlockedTasks}
          toggleModal={toggleModal}
          contentPro={data.contentPro}
          currentLang={currentLang}
          proRole={proRole}
        />
        <MessageModal
          label={data.label.modal}
          showModal={showModal}
          toggle={toggleModal}
        />
      </Fragment>
    );
  }

  return (
    <BaseLayout
      title={
        data ? `(defi)² - ${data.content.name}` : '(defi)² - Detalle tarea'
      }
    >
      <div className="background-proceso">
        <Header to="/menu" previous={`/tarea/${id}/detalle`} logo="amarillo" />
      </div>
      <div className="background-proceso">
        <div className="detail-task-container">{content}</div>
      </div>
    </BaseLayout>
  );
};

export default DetalleTareaLayout;
