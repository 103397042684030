import React from 'react';
import { Link } from 'gatsby';
import MarkdownView from 'react-showdown';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Loading } from '../layout';

const ModalLoginRegister = ({ open, toggle, labels }) => {
  return (
    <Modal isOpen={open} toggle={toggle} className="pay-modal">
      <ModalHeader className="text-center" toggle={toggle}>
        {labels.plansLoginRegisterTitle || 'plansLoginRegisterTitle'}
      </ModalHeader>
      <ModalBody>
        <MarkdownView
          markdown={
            labels.plansLoginRegisterContent || 'plansLoginRegisterContent'
          }
          className="before-starting-content"
        />
        <div className="text-center p-1 pb-3">
          <Link className="btn btn-general-white text-uppercase" to="/login">
            {labels.plansLoginButton || 'plansLoginButton'}
          </Link>
        </div>
        <div className="text-center p-1">
          <Link
            className="btn btn-general-yellow text-uppercase"
            to="/registro"
          >
            {labels.plansRegisterButton || 'plansRegisterButton'}
          </Link>
        </div>
      </ModalBody>
      {/* <ModalFooter>
        <div className="text-justify font-italic">
          {url}
        </div>
      </ModalFooter> */}
    </Modal>
  );
};

export default ModalLoginRegister;
