import React from 'react';

const Link = ({ title, url }) => {
  return (
    <li>
      <div className="quote">
        <span className="link">
          {
            // titulo
            title && title
          }
        </span>
        {
          // url
          url && (
            <a className="quote-url" href={url}>
              {url}
            </a>
          )
        }
      </div>
    </li>
  );
};

export default Link;
