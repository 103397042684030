import React from 'react';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { Container } from '../components/premiumContent';

const ContenidoPremiumLayout = ({ id, data, loading, error }) => {
  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <Container {...data} />
    );
  }

  return (
    <BaseLayout
      title={data ? `(defi)² - ${data.title}` : '(defi)² - Contenido premium'}
    >
      <div className="">
        <Header
          to="/menu"
          previous={`/contenido-premium/${id}`}
          logo="amarillo"
        />
        {content}
      </div>
    </BaseLayout>
  );
};

export default ContenidoPremiumLayout;
