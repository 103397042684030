import React, { Fragment } from 'react';

const DisplayLanguage = ({ name, abbr, lastLang, onChangeLanguage }) => {
  const activeLang = abbr === lastLang ? 'font-weight-bolder' : '';
  return (
    <button className="btn" onClick={onChangeLanguage}>
      <span className={activeLang}>{name}</span>
    </button>
  );
};

const LanguageChanger = ({ languages, changeLanguage, lastLang }) => {
  return (
    <div className="language-changer d-flex flex-row justify-content-center align-items-center">
      {Array.isArray(languages) &&
        languages.map(({ name, abbr }, i) => {
          const content =
            i % 2 ? (
              <DisplayLanguage
                key={i}
                name={name}
                onChangeLanguage={() => changeLanguage(abbr)}
                abbr={abbr}
                lastLang={lastLang}
              />
            ) : (
              <Fragment key={i}>
                <DisplayLanguage
                  key={i}
                  name={name}
                  onChangeLanguage={() => changeLanguage(abbr)}
                  abbr={abbr}
                  lastLang={lastLang}
                />
                <span>{' / '}</span>
              </Fragment>
            );
          return content;
        })}
      <div style={{ marginBottom: '35vh' }}></div>
    </div>
  );
};

export default LanguageChanger;
