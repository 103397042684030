import React from 'react';
import { Link } from 'gatsby';

import BaseLayout from './BaseLayout';
import { Error, Footer, Header, Loading } from '../components/layout';
import { Profile, ChangePass } from '../components/myCount';

const MiCuentaLayout = ({ data, interfaceContent, loading, error }) => {
  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <div>
        <Profile
          interfaceContent={interfaceContent}
          data={data}
          loading={loading}
        />
        <hr />
        <ChangePass
          interfaceContent={interfaceContent}
          data={data}
          loading={loading}
        />
      </div>
    );
  }
  return (
    <BaseLayout title="(defi)² - Menú">
      <Header to="/menu" previous={'/mi-cuenta'} logo="amarillo" />
      {content}
      <hr />
      <div className="text-center mt-2 mb-2">
        <Link className="btn-mycount-yellow p-2" to="/logout">
          Cerrar sesión
        </Link>
      </div>
      <div style={{ height: '20vh' }}></div>
      <Footer />
    </BaseLayout>
  );
};

export default MiCuentaLayout;
