import React from 'react';
import MarkdownView from 'react-showdown';
import TitleIcon from '../task/TitleIcon';

const Block = ({ icon, title, theme, content }) => {
  return (
    <div className="detail-task-block">
      <div className="detail-task-block-title-container">
        <TitleIcon icon={icon} theme="black" />
        <span className="detail-task-block-title">{title}</span>
      </div>
      <MarkdownView
        className="detail-task-block-content"
        markdown={content}
        options={{ openLinksInNewWindow: true }}
      />
    </div>
  );
};

export default Block;
