import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import MarkdownView from 'react-showdown';

import Title from './Title';
import ButtonDiscover from './ButtonDiscover';
import Section from './Section';

import TaskNumber from './TaskNumber';

import { useDispatch, useSelector } from 'react-redux';

import { tareasSinDetalle, maxPreviewTextLength } from '../../constants';
import { MessageModal } from '../steps';
import { setAccessLastTask } from '../..//store/actions/task';
import { ModalCongratulations } from '.';

const Card = ({ data }) => {
  const dispatch = useDispatch();

  const stepStore = useSelector(({ step }) => step);
  const lastTaskAccess = useSelector(({ task }) => task.lastTaskAccess);
  const lastTask = useSelector(({ task }) => task.lastTask);
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [showCongratModal, setShowCongratModal] = useState(false);
  const { contenidoPro } = data;
  const isOrphan = tareasSinDetalle.includes(data.taskNumber) ? true : false;
  const maxLength = maxPreviewTextLength;
  const quesEs = !isOrphan
    ? data.whatIs.substring(0, maxLength) + '...'
    : data.whatIs;
  const cardTheme = data.theme === 'theme-black' ? 'theme-grey' : data.theme;
  const cardClasses = data.theme
    ? 'container task-card ' + cardTheme
    : 'container task-card theme-grey';

  const toggleLockedTaskModal = () => {
    setShowLockedModal(!showLockedModal);
  };

  // modifica el estado el modal de felicitaciones
  const toggleModalCongrat = () => {
    dispatch(setAccessLastTask());
    setShowCongratModal(!showCongratModal);
  };
  const prevTaskNumber = data.taskNumber - 1;
  const prevTaskUnlocked = stepStore.unlockedSteps.filter(obj =>
    obj.tasks.includes(prevTaskNumber)
  );

  const prevTask =
    prevTaskNumber > 0 ? (
      prevTaskUnlocked.length > 0 ? (
        <Link
          to={`/tarea/${prevTaskNumber}`}
          className="task-link-btn task-link-prev"
        ></Link>
      ) : (
        <span className="task-link-btn task-link-prev task-link-locked"></span>
      )
    ) : null;

  const nextTaskNumber = data.taskNumber + 1;
  const nextTaskUnlocked = stepStore.unlockedSteps.filter(obj =>
    obj.tasks.includes(nextTaskNumber)
  );
  const nextTask =
    nextTaskNumber <= 41 ? (
      nextTaskUnlocked.length > 0 ? (
        <Link
          to={`/tarea/${nextTaskNumber}`}
          className="task-link-btn task-link-next"
        ></Link>
      ) : (
        <a
          className="task-link-btn task-link-next task-link-locked"
          onClick={toggleLockedTaskModal}
        ></a>
      )
    ) : null;

  useEffect(() => {
    if (data.taskNumber === lastTask && !lastTaskAccess) {
      toggleModalCongrat();
    }
  }, [data.taskNumber]);

  return (
    <>
      <div className={cardClasses}>
        <div className="d-flex flex-direction-row justify-content-end task-card-back-btn">
          <Link
            className="btn"
            to={`/etapa/${data.stepId}/tarea/${data.taskNumber}`}
          >
            <i className="fas fa-times" />
          </Link>
        </div>

        <div className="task-card-header d-flex">
          <TaskNumber
            active={data.taskNumber}
            prevColor={data.theme.replace('theme-', '')}
            activeColor={data.theme.replace('theme-', '')}
            nextColor={data.theme.replace('theme-', '')}
            showPrevLine={data.taskNumber == 41 ? false : true}
            showLastLine={data.taskNumber == 41 ? false : true}
          />
        </div>

        <div className="task-card-container">
          <div className="task-card-title-container">
            <img className="step-img" src={data.icon} />
            <Title className="task-card-title" name={data.name} />
          </div>
          <div>
            <Section title="¿Qué es?" icon="quees" theme={cardTheme} />
            <div
              className={
                !isOrphan ? 'task-card-content' : 'task-card-content-orphan'
              }
            >
              <MarkdownView className="markdown-content" markdown={quesEs} />
              {!isOrphan ? (
                <ButtonDiscover id={data.taskNumber} />
              ) : (
                <ButtonDiscover id={data.taskNumber + 1} orphan="orphan" />
              )}
            </div>
          </div>
          {prevTask}
          {nextTask}
        </div>
        <MessageModal
          showModal={showLockedModal}
          toggle={toggleLockedTaskModal}
          label={data.modal}
        />
        <ModalCongratulations
          open={showCongratModal}
          toggle={toggleModalCongrat}
          labels={data.labels}
        />
      </div>
    </>
  );
};

export default Card;
