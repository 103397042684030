import React from 'react';
import { Loading } from '../../components/layout';

import {
  Form,
  Alert,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from 'reactstrap';

const ModalAddUser = ({
  handleSubmit,
  handleChange,
  open,
  toggle,
  plan,
  labels,
  message,
  email,
  loadingHook,
}) => {
  return plan ? (
    <Modal isOpen={open} toggle={toggle} className="modal-user-group">
      <ModalHeader className="text-center" toggle={toggle}>
        {labels.addUserTitleGroupAccount || 'NULL'}
      </ModalHeader>
      <ModalBody>
        {loadingHook ? (
          <div className="d-flex flex-column justify-content-center align-items-center p-5">
            <Loading iconColor="amarillo" />
          </div>
        ) : (
          <Form onSubmit={handleSubmit} className="">
            <div className="d-flex justify-content-center col-12">
              <div className="col-sm-5 p-2 text-center">
                <Label
                  for="email"
                  className="d-flex flex-wrap align-items-center"
                >
                  {labels.emailGroupAccount || 'NULL'}
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={email}
                />
              </div>
            </div>
            {message ? <Alert color="warning">{message}</Alert> : ''}

            <div className="d-flex justify-content-center col-12 mt-3">
              <Button type="submit" className="add-btn">
                {labels.addUserTitleGroupAccount || 'NULL'}
              </Button>
            </div>
          </Form>
        )}
      </ModalBody>
    </Modal>
  ) : (
    ''
  );
};

export default ModalAddUser;
