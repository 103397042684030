import React, { useState, useRef, useEffect } from 'react';
import { useScript } from '../../hooks';

const ButtonPayPal = ({ clientID, planID, approvePaypal, errorPaypal }) => {
  const paypalRef = useRef();

  const statusPaypal = useScript(
    clientID
      ? `https://www.paypal.com/sdk/js?client-id=${clientID}&vault=true&intent=subscription`
      : '',
    'paypal'
  );
  const createSubscription = (data, actions) => {
    return actions.subscription.create({
      plan_id: planID,
    });
  };
  const onApprove = (data, actions) => {
    // alert(data.subscriptionID);
    approvePaypal(data.subscriptionID);
  };

  useEffect(() => {
    console.debug('statusPaypal', statusPaypal, window.paypal);
    if (statusPaypal === 'ready' && window.paypal) {
      window.paypal
        .Buttons({
          style: {
            shape: 'rect',
            color: 'white',
            layout: 'horizontal',
            label: 'subscribe',
          },
          createSubscription: createSubscription,
          onApprove: onApprove,
          onError: err => {
            errorPaypal();
            console.error(err);
          },
        })
        .render(paypalRef.current);
    }
  }, [statusPaypal]);
  return <>{statusPaypal === 'ready' && <div ref={paypalRef}></div>}</>;
};

export default ButtonPayPal;
