import React, { useEffect } from 'react';

import Content from './Content';

const Container = ({ data, letter }) => {
  useEffect(() => {
    if (letter) {
      const element = document.querySelector(`#${letter}`);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [letter]);
  return (
    <div className="glossary-container">
      {data && data.map((content, i) => <Content key={i} {...content} />)}
    </div>
  );
};

export default Container;
