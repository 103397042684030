import React from 'react';

const ChapterBook = props => {
  const {
    author,
    year,
    title_chapter,
    title_book,
    editor,
    url,
    pages,
    city,
    state,
    country,
    editorial,
  } = props;
  return (
    <li>
      <div className="quote">
        <span className="chapter-book">
          {
            // autor
            author && author
          }
          {
            // year
            year && ` (${year}). `
          }
          {
            // titulo de capitulo
            `${title_chapter}. `
          }
          {
            // editor
            editor && `In ${editor} (Ed.), `
          }
          {
            // titulo de libro
            title_book && <em>{title_book}</em>
          }
          {
            // paginas
            pages && `(pp. ${pages}). `
          }
          {
            // ciudad
            city && `${city}, `
          }
          {
            // estado
            state && `${state}, `
          }
          {
            // pais
            country && `${country}: `
          }
          {
            // editorial
            editorial && `${editorial}.`
          }
        </span>
        {
          // url
          url && (
            <a className="quote-url" href={url}>
              Link
            </a>
          )
        }
      </div>
    </li>
  );
};

export default ChapterBook;
