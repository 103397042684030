import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DefaultPlayer as Videohtml5 } from 'react-html5video';
import 'react-html5video/dist/styles.css';

const Video = ({
  url,
  img_intro,
  img_credit,
  subtitle_url,
  lang,
  width = 100,
}) => {
  const [videoEnded, setVideoEnded] = useState(false);
  // console.log('datos V', url, img_intro, img_credit, subtitle_url);
  // const logosConf = false;
  const logosConf = useSelector(({ config }) => config.logos);
  let videoType = '';
  if (url.includes('.mp4')) {
    videoType = 'video/mp4';
  }
  if (url.includes('.webm')) {
    videoType = 'video/webm';
  }

  return (
    <>
      <div
        className={`${
          videoEnded ? 'p-2 videoPlayer videoHidden' : 'p-2 videoPlayer'
        } mx-auto`}
        style={{ width: `${width}%` }}
      >
        {
          <Videohtml5
            muted
            controls={['PlayPause', 'Seek', 'Time', 'Fullscreen']}
            poster={
              img_intro
                ? process.env.STRAPI_URL + img_intro.url
                : process.env.STRAPI_URL + logosConf.amarillo.url ||
                  '/img/logo_defi_flechas_amarillas.svg'
            }
            onCanPlayThrough={() => {
              console.log('Can play');
            }}
            onEnded={() => {
              console.log('Ended playing');
              setVideoEnded(true);
              console.log(Video);
            }}
            crossorigin="anonymous"
            playsInline={true}
          >
            <source src={url} type={videoType} />
            {subtitle_url && (
              <track
                label={lang}
                kind="subtitles"
                srcLang={lang}
                src={subtitle_url}
                default
              />
            )}
          </Videohtml5>
        }
      </div>

      {logosConf && (
        <img
          onClick={() => {
            setVideoEnded(false);
          }}
          className={videoEnded ? 'videoImg' : 'videoImg videoHidden'}
          src={
            img_credit
              ? process.env.STRAPI_URL + img_credit.url
              : process.env.STRAPI_URL + logosConf.amarillo.url ||
                '/img/logo_defi_flechas_amarillas.svg'
          }
        />
      )}
    </>
  );
};

export default Video;
