import React from 'react';
import { Link } from 'gatsby';

const Button = ({ label, toLastStepTask }) => {
  return (
    <Link className="btn before-starting-start-button" to={toLastStepTask}>
      <span>{label}</span>
    </Link>
  );
};

export default Button;
