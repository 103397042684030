import React from 'react';

const Informe = props => {
  const {
    author,
    year,
    title,
    report_number,
    city,
    state,
    country,
    publisher,
    url,
  } = props;
  return (
    <li>
      <div className="quote">
        <span className="inform">
          {
            // autor
            author && author
          }
          {
            // año
            year && ` (${year}). `
          }
          {
            // titulo
            title && <em>{`${title}, `}</em>
          }
          {
            // numero de reporte
            report_number && `(${report_number}). `
          }
          {
            // ciudad
            city && `${city}, `
          }
          {
            // estado
            state && `${state}, `
          }
          {
            // pais
            country && `${country}: `
          }
          {
            // organismo que publica
            publisher && `${publisher}.`
          }
        </span>
        {
          // url
          url && (
            <a className="quote-url" href={url}>
              Link
            </a>
          )
        }
      </div>
    </li>
  );
};

export default Informe;
