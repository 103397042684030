import React from 'react';

const StepsSvgMobile = ({ active, unlocked }) => {
  return (
    <svg
      className="step-indicator-svg d-block d-xl-none"
      viewBox="-15 -15 400 200"
    >
      <g id="lineas">
        <path
          className={unlocked.includes(7) ? 'step-teal' : 'step-grey'}
          d="M112.73,104.07C91,103.83,66.81,97,61.88,82.38l1.43-.47c4.69,13.94,28.21,20.42,49.44,20.66Z"
          transform="translate(-11.12 -10.66)"
        />
        <rect
          className={unlocked.includes(2) ? 'step-black' : 'step-grey'}
          x="4.3"
          y="90.09"
          width="20.85"
          height="1.5"
        />

        <path
          className={unlocked.includes(3) ? 'step-teal' : 'step-grey'}
          d="M36.27,102.24v-1.5c4.25,0,8.32-.1,12.1-.29C75.76,99.08,90.43,93,93.2,81.91l1.46.37c-3,11.83-18.07,18.26-46.21,19.67C44.64,102.14,40.55,102.24,36.27,102.24Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(4) ? 'step-teal' : 'step-grey'}
          d="M94.66,82.28l-1.46-.37a21,21,0,0,0,.57-5.23,16.31,16.31,0,0,0-5.49-12.29l1-1.12a17.8,17.8,0,0,1,6,13.41A22.65,22.65,0,0,1,94.66,82.28Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(5) ? 'step-teal' : 'step-grey'}
          d="M67.52,64.7l-1-1.09A16.69,16.69,0,0,1,78,59a17,17,0,0,1,11.25,4.24l-1,1.12A15.55,15.55,0,0,0,78,60.53,15.17,15.17,0,0,0,67.52,64.7Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(6) ? 'step-teal' : 'step-grey'}
          d="M61.88,82.38a17.54,17.54,0,0,1-.9-5.55,18,18,0,0,1,5.51-13.22l1,1.09a16.5,16.5,0,0,0-5,12.13,15.82,15.82,0,0,0,.83,5.08Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(14) ? 'step-blue' : 'step-grey'}
          d="M190.64,106.4c-28.35,0-51.87-8.44-60-21.56l1.27-.79c7.86,12.68,30.89,20.85,58.73,20.85h.4v1.5Z"
          transform="translate(-11.12 -10.66)"
        />

        <path
          className={unlocked.includes(8) ? 'step-blue' : 'step-grey'}
          d="M114.22,104.08h-1.48v-1.5c23.42.26,43.75-6.48,51.8-17.19l1.2.9C159.24,95,142.31,104.08,114.22,104.08Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(9) ? 'step-blue' : 'step-grey'}
          d="M165.75,86.28l-1.2-.9a19.82,19.82,0,0,0,4.12-12.16,21.18,21.18,0,0,0-1.09-6.73L169,66a22.46,22.46,0,0,1,1.17,7.21A21.28,21.28,0,0,1,165.75,86.28Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(10) ? 'step-blue' : 'step-grey'}
          d="M167.58,66.49A20.48,20.48,0,0,0,157.5,54.83l.67-1.34A22,22,0,0,1,169,66Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(11) ? 'step-blue' : 'step-grey'}
          d="M157.5,54.83a19.51,19.51,0,0,0-8.78-2.07,18.87,18.87,0,0,0-8.53,2.05l-.67-1.34a20.27,20.27,0,0,1,9.2-2.21,21,21,0,0,1,9.45,2.23Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(12) ? 'step-blue' : 'step-grey'}
          d="M129.94,66.39l-1.41-.5a22.51,22.51,0,0,1,11-12.42l.67,1.34A21,21,0,0,0,129.94,66.39Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(13) ? 'step-blue' : 'step-grey'}
          d="M130.64,84.84a22,22,0,0,1-3.38-11.75,21.17,21.17,0,0,1,1.27-7.2l1.41.5a19.74,19.74,0,0,0-1.18,6.7,20.47,20.47,0,0,0,3.15,11Z"
          transform="translate(-11.12 -10.66)"
        />

        <path
          className={unlocked.includes(21) ? 'step-magenta' : 'step-grey'}
          d="M274.27,108.64c-32.34-1.68-56.75-10.81-67-25l1.22-.87c10,13.86,34,22.76,65.85,24.42Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(15) ? 'step-magenta' : 'step-grey'}
          d="M191.05,106.4v-1.5c28.42-.08,52.72-8.38,63.44-21.64l1.17.94C247,94.87,225.54,106.3,191.05,106.4Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(16) ? 'step-magenta' : 'step-grey'}
          d="M255.65,84.2l-1.17-.94a26.66,26.66,0,0,0,6.19-17.11,32,32,0,0,0-.28-4.18l1.48-.21a31.84,31.84,0,0,1,.3,4.39A28.18,28.18,0,0,1,255.65,84.2Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(17) ? 'step-magenta' : 'step-grey'}
          d="M260.39,62a27.17,27.17,0,0,0-11.25-18.73L250,42a28.66,28.66,0,0,1,11.86,19.75Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(18) ? 'step-magenta' : 'step-grey'}
          d="M249.14,43.24a30.44,30.44,0,0,0-17.72-5.34,29.06,29.06,0,0,0-17.08,5.16l-.87-1.22a30.56,30.56,0,0,1,17.95-5.44A32,32,0,0,1,250,42Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(19) ? 'step-magenta' : 'step-grey'}
          d="M203.42,62.05l-1.49-.18a28.6,28.6,0,0,1,11.54-20l.87,1.22A27.07,27.07,0,0,0,203.42,62.05Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(20) ? 'step-magenta' : 'step-grey'}
          d="M207.28,83.59a29.33,29.33,0,0,1-5.61-17.44,35.39,35.39,0,0,1,.26-4.28l1.49.19a32.43,32.43,0,0,0-.25,4.09,27.85,27.85,0,0,0,5.33,16.57Z"
          transform="translate(-11.12 -10.66)"
        />

        <path
          className={unlocked.includes(22) ? 'step-magenta' : 'step-grey'}
          d="M284.42,108.9c-3.39,0-6.8-.09-10.15-.26l.08-1.5c3.32.17,6.71.26,10.07.26a81.73,81.73,0,0,0,8.43-.46l.16,1.49A82.05,82.05,0,0,1,284.42,108.9Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(41) ? 'step-orange' : 'step-grey'}
          d="M321.67,114.13l-1-1.08c11.67-11.18,27.49-17.33,44.56-17.33h17.23v1.5H365.19C348.51,97.22,333.05,103.22,321.67,114.13Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(30) ? 'step-red' : 'step-grey'}
          d="M359.15,114.48c-6-7-13.65-13.61-21-20-9-7.8-18.33-15.87-24.59-24.6l1.22-.87c6.16,8.58,15.41,16.59,24.35,24.34,7.44,6.44,15.13,13.1,21.19,20.18Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(23) ? 'step-red' : 'step-grey'}
          d="M293,108.43l-.16-1.49C319.61,104.08,349.11,88.14,363,69l1.22.88C350.09,89.33,320.16,105.53,293,108.43Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(24) ? 'step-red' : 'step-grey'}
          d="M364.19,69.91,363,69c5.32-7.32,8-14.57,8-21.54,0-.4,0-.81,0-1.21l1.5-.06c0,.43,0,.85,0,1.27C372.48,54.79,369.69,62.33,364.19,69.91Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(25) ? 'step-red' : 'step-grey'}
          d="M371,46.28A32,32,0,0,0,362.65,26l1.11-1a33.54,33.54,0,0,1,8.7,21.28Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(26) ? 'step-red' : 'step-grey'}
          d="M362.65,26A32.3,32.3,0,0,0,338.8,15.48V14a33.82,33.82,0,0,1,25,11Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(27) ? 'step-red' : 'step-grey'}
          d="M315.16,26.2l-1.12-1A33.33,33.33,0,0,1,338.8,14v1.5A31.83,31.83,0,0,0,315.16,26.2Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(28) ? 'step-red' : 'step-grey'}
          d="M307,46.63l-1.5,0A33.48,33.48,0,0,1,314,25.2l1.12,1A31.94,31.94,0,0,0,307,46.63Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(29) ? 'step-red' : 'step-grey'}
          d="M313.52,69.86c-5.42-7.54-8-14.87-8-22.39,0-.3,0-.59,0-.88l1.5,0c0,.28,0,.56,0,.84,0,7.2,2.54,14.24,7.77,21.52Z"
          transform="translate(-11.12 -10.66)"
        />

        <path
          className={unlocked.includes(31) ? 'step-orange' : 'step-grey'}
          d="M368.29,128.46a57.15,57.15,0,0,0-9.14-14l1.13-1a57.85,57.85,0,0,1,9.38,14.35Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(32) ? 'step-orange' : 'step-grey'}
          d="M372.81,144.25l-1.5-.07c0-.53,0-1.06,0-1.6a34.57,34.57,0,0,0-3.06-14.12l1.37-.6a36,36,0,0,1,3.19,14.72C372.85,143.14,372.84,143.69,372.81,144.25Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(33) ? 'step-orange' : 'step-grey'}
          d="M367.49,160.79l-1.26-.81a32,32,0,0,0,5.08-15.8l1.5.07A33.58,33.58,0,0,1,367.49,160.79Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(34) ? 'step-orange' : 'step-grey'}
          d="M355.16,172.19l-.71-1.32A32.48,32.48,0,0,0,366.23,160l1.26.81A33.89,33.89,0,0,1,355.16,172.19Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(35) ? 'step-orange' : 'step-grey'}
          d="M339.57,176.15v-1.5a31.45,31.45,0,0,0,14.88-3.78l.71,1.32A32.92,32.92,0,0,1,339.57,176.15Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(36) ? 'step-orange' : 'step-grey'}
          d="M339.57,176.15a32.78,32.78,0,0,1-16.74-4.72l.77-1.28a31.36,31.36,0,0,0,16,4.5Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(37) ? 'step-orange' : 'step-grey'}
          d="M322.83,171.43A35.25,35.25,0,0,1,311.07,160l1.27-.8a33.82,33.82,0,0,0,11.26,11Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(38) ? 'step-orange' : 'step-grey'}
          d="M311.07,160a32.78,32.78,0,0,1-5-15.81l1.5-.08a31.33,31.33,0,0,0,4.8,15.09Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(39) ? 'step-orange' : 'step-grey'}
          d="M306,144.18c0-.53,0-1.06,0-1.6a34.37,34.37,0,0,1,3.58-14.51l1.35.65a32.87,32.87,0,0,0-3.43,13.86c0,.51,0,1,0,1.52Z"
          transform="translate(-11.12 -10.66)"
        />
        <path
          className={unlocked.includes(40) ? 'step-orange' : 'step-grey'}
          d="M310.93,128.72l-1.35-.65a52.37,52.37,0,0,1,11.05-15l1,1.08A51,51,0,0,0,310.93,128.72Z"
          transform="translate(-11.12 -10.66)"
        />
      </g>
      <g id="puntos">
        <g>
          <path
            className={
              unlocked.includes(1)
                ? active === 1
                  ? 'step-black step-indicator-active'
                  : 'step-black'
                : 'step-grey'
            }
            d="M15.37,105.54a4.24,4.24,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(2)
                ? active === 2
                  ? 'step-black step-indicator-active'
                  : 'step-black'
                : 'step-grey'
            }
            d="M36.27,105.54A4.24,4.24,0,1,0,32,101.3a4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />

          <path
            className={
              unlocked.includes(3)
                ? active === 3
                  ? 'step-teal step-indicator-active'
                  : 'step-teal'
                : 'step-grey'
            }
            d="M93.73,86.25A4.24,4.24,0,1,0,89.48,82a4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(4)
                ? active === 4
                  ? 'step-teal step-indicator-active'
                  : 'step-teal'
                : 'step-grey'
            }
            d="M88.88,67.91a4.24,4.24,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(5)
                ? active === 5
                  ? 'step-teal step-indicator-active'
                  : 'step-teal'
                : 'step-grey'
            }
            d="M66.67,67.91a4.24,4.24,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(6)
                ? active === 6
                  ? 'step-teal step-indicator-active'
                  : 'step-teal'
                : 'step-grey'
            }
            d="M62.17,86.3a4.24,4.24,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />

          <path
            className={
              unlocked.includes(7)
                ? active === 7
                  ? 'step-blue step-indicator-active'
                  : 'step-blue'
                : 'step-grey'
            }
            d="M112.61,107.8a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(8)
                ? active === 8
                  ? 'step-blue step-indicator-active'
                  : 'step-blue'
                : 'step-grey'
            }
            d="M165.59,89.71a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(9)
                ? active === 9
                  ? 'step-blue step-indicator-active'
                  : 'step-blue'
                : 'step-grey'
            }
            d="M168.49,70.24A4.24,4.24,0,1,0,164.25,66a4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(10)
                ? active === 10
                  ? 'step-blue step-indicator-active'
                  : 'step-blue'
                : 'step-grey'
            }
            d="M158.13,58.57a4.25,4.25,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(11)
                ? active === 11
                  ? 'step-blue step-indicator-active'
                  : 'step-blue'
                : 'step-grey'
            }
            d="M139.71,58.49a4.25,4.25,0,1,0-4.24-4.25,4.25,4.25,0,0,0,4.24,4.25"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(12)
                ? active === 12
                  ? 'step-blue step-indicator-active'
                  : 'step-blue'
                : 'step-grey'
            }
            d="M129.4,70.24A4.24,4.24,0,1,0,125.16,66a4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(13)
                ? active === 13
                  ? 'step-blue step-indicator-active'
                  : 'step-blue'
                : 'step-grey'
            }
            d="M131.18,88.12a4.25,4.25,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(14)
                ? active === 14
                  ? 'step-blue step-indicator-active'
                  : 'step-blue'
                : 'step-grey'
            }
            d="M191,109.75a4.24,4.24,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />

          <path
            className={
              unlocked.includes(15)
                ? active === 15
                  ? 'step-magenta step-indicator-active'
                  : 'step-magenta'
                : 'step-grey'
            }
            d="M255.11,87.59a4.25,4.25,0,1,0-4.24-4.25,4.24,4.24,0,0,0,4.24,4.25"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(16)
                ? active === 16
                  ? 'step-magenta step-indicator-active'
                  : 'step-magenta'
                : 'step-grey'
            }
            d="M260.93,66.06a4.24,4.24,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(17)
                ? active === 17
                  ? 'step-magenta step-indicator-active'
                  : 'step-magenta'
                : 'step-grey'
            }
            d="M249.71,46.6a4.25,4.25,0,1,0-4.24-4.25,4.25,4.25,0,0,0,4.24,4.25"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(18)
                ? active === 18
                  ? 'step-magenta step-indicator-active'
                  : 'step-magenta'
                : 'step-grey'
            }
            d="M214,46.6a4.25,4.25,0,1,0-4.24-4.25A4.25,4.25,0,0,0,214,46.6"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(19)
                ? active === 19
                  ? 'step-magenta step-indicator-active'
                  : 'step-magenta'
                : 'step-grey'
            }
            d="M202.54,66.06a4.24,4.24,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(20)
                ? active === 20
                  ? 'step-magenta step-indicator-active'
                  : 'step-magenta'
                : 'step-grey'
            }
            d="M207.75,87.5a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(21)
                ? active === 21
                  ? 'step-magenta step-indicator-active'
                  : 'step-magenta'
                : 'step-grey'
            }
            d="M274.39,112.48a4.24,4.24,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(22)
                ? active === 22
                  ? 'step-magenta step-indicator-active'
                  : 'step-magenta'
                : 'step-grey'
            }
            d="M293,112.48a4.24,4.24,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />

          <path
            className={
              unlocked.includes(23)
                ? active === 23
                  ? 'step-red step-indicator-active'
                  : 'step-red'
                : 'step-grey'
            }
            d="M363.79,73.87a4.24,4.24,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(24)
                ? active === 24
                  ? 'step-red step-indicator-active'
                  : 'step-red'
                : 'step-grey'
            }
            d="M372,50.91a4.25,4.25,0,1,0-4.24-4.25A4.24,4.24,0,0,0,372,50.91"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(25)
                ? active === 25
                  ? 'step-red step-indicator-active'
                  : 'step-red'
                : 'step-grey'
            }
            d="M363.25,29.87A4.25,4.25,0,1,0,359,25.63a4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(26)
                ? active === 26
                  ? 'step-red step-indicator-active'
                  : 'step-red'
                : 'step-grey'
            }
            d="M339,19.14a4.24,4.24,0,1,0-4.25-4.24A4.24,4.24,0,0,0,339,19.14"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(27)
                ? active === 27
                  ? 'step-red step-indicator-active'
                  : 'step-red'
                : 'step-grey'
            }
            d="M314.87,29.87a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(28)
                ? active === 28
                  ? 'step-red step-indicator-active'
                  : 'step-red'
                : 'step-grey'
            }
            d="M306,50.84a4.25,4.25,0,1,0-4.24-4.24A4.24,4.24,0,0,0,306,50.84"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(29)
                ? active === 29
                  ? 'step-red step-indicator-active'
                  : 'step-red'
                : 'step-grey'
            }
            d="M314,73.87a4.25,4.25,0,1,0-4.25-4.24A4.24,4.24,0,0,0,314,73.87"
            transform="translate(-11.12 -10.66)"
          />

          <path
            className={
              unlocked.includes(30)
                ? active === 30
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M359.86,118.11a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(31)
                ? active === 31
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M369.1,132.37a4.24,4.24,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(32)
                ? active === 32
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M372.16,148.35a4.24,4.24,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(33)
                ? active === 33
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M367.13,164.55a4.25,4.25,0,1,0-4.24-4.25,4.24,4.24,0,0,0,4.24,4.25"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(34)
                ? active === 34
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M355.27,175.5a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(35)
                ? active === 35
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M339.29,179.61a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(36)
                ? active === 36
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M323.13,175.5a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(37)
                ? active === 37
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M312.08,164.55a4.25,4.25,0,1,0-4.24-4.25,4.25,4.25,0,0,0,4.24,4.25"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(38)
                ? active === 38
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M306.69,148.35a4.24,4.24,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(39)
                ? active === 39
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M309.93,132.38a4.24,4.24,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />
          <path
            className={
              unlocked.includes(40)
                ? active === 40
                  ? 'step-orange step-indicator-active'
                  : 'step-orange'
                : 'step-grey'
            }
            d="M321.16,118a4.25,4.25,0,1,0-4.24-4.24,4.24,4.24,0,0,0,4.24,4.24"
            transform="translate(-11.12 -10.66)"
          />

          <path
            className={
              unlocked.includes(41)
                ? active === 41
                  ? 'step-black step-indicator-active'
                  : 'step-black'
                : 'step-grey'
            }
            d="M383.05,100.5a4.24,4.24,0,1,0-4.25-4.24,4.24,4.24,0,0,0,4.25,4.24"
            transform="translate(-11.12 -10.66)"
          />
        </g>
      </g>
    </svg>
  );
};

export default StepsSvgMobile;
