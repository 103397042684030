import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import { Link, navigate } from 'gatsby';

import { logOut } from '../../store/actions/login';

const LoginMenuItem = ({ loginMenu, isLogged, config }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [idSelected, setISelected] = useState('');
  const currentRole = useSelector(({ login }) => login.user);

  const toggle = () => setIsOpen(!isOpen);

  const onLogout = () => {
    dispatch(logOut());
    navigate('/landing');
  };
  console.log('el menu:', loginMenu, config);

  const content = (
    <>
      <div className="menu-item">
        <button className="btn menu-item-button" onClick={toggle}>
          <span>
            {loginMenu.find(menu => menu.key === 'baseMenuTitle').value}
          </span>
          {!config.viewPro || config.viewPro !== 'true' ? (
            <span className="font-italic font-weight-light">{`(${
              loginMenu.find(menu => menu.key === 'defiProSoon').value
            })`}</span>
          ) : (
            ''
          )}
        </button>
        <Collapse isOpen={isOpen}>
          <ul className="sub-menu">
            {isLogged && config.viewPro && config.viewPro === 'true' && (
              <>
                {/* Menú usuario premiun Premium Admin y Premium para contenido premium */}
                {(currentRole.roleName === 'Premium Admin' ||
                  currentRole.roleName === 'Premium') && (
                  <>
                    <li
                      className={
                        idSelected === 'contentProMenuTitle'
                          ? 'font-weight-bolder'
                          : ''
                      }
                      onClick={toggle}
                    >
                      <Link className="" to="/contenido-pro">
                        <span>
                          {
                            loginMenu.find(
                              menu => menu.key === 'contentProMenuTitle'
                            ).value
                          }
                        </span>
                      </Link>
                    </li>
                  </>
                )}
                {/* Menú usuario premiun Premium Admin y Registered para ver planes */}
                {(currentRole.roleName === 'Premium Admin' ||
                  currentRole.roleName === 'Registered') && (
                  <>
                    <li
                      className={
                        idSelected === 'plansMenuTitle'
                          ? 'font-weight-bolder'
                          : ''
                      }
                      onClick={toggle}
                    >
                      <Link className="" to="/planes">
                        <span>
                          {
                            loginMenu.find(
                              menu => menu.key === 'plansMenuTitle'
                            ).value
                          }
                        </span>
                      </Link>
                    </li>
                  </>
                )}

                {/* Menú usuario premiun Premium Admin plan y cuanta grupal */}
                {currentRole.roleName === 'Premium Admin' && (
                  <>
                    <li
                      className={
                        idSelected === 'myPlanMenuTitle'
                          ? 'font-weight-bolder'
                          : ''
                      }
                      onClick={toggle}
                    >
                      <Link className="" to="/mi-plan">
                        <span>
                          {
                            loginMenu.find(
                              menu => menu.key === 'myPlanMenuTitle'
                            ).value
                          }
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        idSelected === 'groupMenuTitle'
                          ? 'font-weight-bolder'
                          : ''
                      }
                      onClick={toggle}
                    >
                      <Link className="" to="/cuenta-grupal">
                        <span>
                          {
                            loginMenu.find(
                              menu => menu.key === 'groupMenuTitle'
                            ).value
                          }
                        </span>
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}

            {/* Accede a lista de planes sin necesidad de estar logeado, para luego redirecionar al login */}
            {!isLogged && (
              <li
                className={
                  idSelected === 'plansMenuTitle' ? 'font-weight-bolder' : ''
                }
                onClick={toggle}
              >
                <Link className="" to="/planes">
                  <span>
                    {
                      loginMenu.find(menu => menu.key === 'plansMenuTitle')
                        .value
                    }
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </Collapse>
      </div>
      {/* items únicos en primer nivel */}
      {isLogged ? (
        <>
          <div className="menu-item">
            <Link className="btn menu-item-button" to="/mi-cuenta">
              <span>
                {loginMenu.find(menu => menu.key === 'myCountMenuTitle').value}
              </span>
            </Link>
          </div>
          <div className="menu-item">
            <Link
              className="btn menu-item-button"
              to="/landing"
              onClick={onLogout}
            >
              <span>
                {loginMenu.find(menu => menu.key === 'logoutMenuTitle').value}
              </span>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="menu-item">
            <Link
              className="btn menu-item-button"
              to="/registro"
              onClick={onLogout}
            >
              <span>
                {loginMenu.find(menu => menu.key === 'singUpMenuTitle').value}
              </span>
            </Link>
          </div>
          <div className="menu-item">
            <Link
              className="btn menu-item-button"
              to="/login"
              onClick={onLogout}
            >
              <span>
                {loginMenu.find(menu => menu.key === 'loginMenuTitle').value}
              </span>
            </Link>
          </div>
        </>
      )}
    </>
  );
  return <div className="menu-item">{content}</div>;
};

export default LoginMenuItem;
