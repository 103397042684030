import React from 'react';

import BaseLayout from './BaseLayout';
import { Header, Footer, Loading, Error } from '../components/layout';
import { LandingPC } from '../components/landingPage';

const LandingPCLayout = ({ loading, data, error }) => {
  const { contents } = data;
  console.debug('contents', loading, error, contents);

  return (
    <BaseLayout title="(defi)² Home">
      <div
        className="d-flex flex-column background-proceso"
        style={{ minHeight: '90vh' }}
      >
        <Header to="/menu" previous="/landing" logo="amarillo" />
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center p-5">
            <Loading iconColor="amarillo" />
          </div>
        ) : error ? (
          <Error msg={error} />
        ) : (
          <div className="pb-4">
            <LandingPC contents={contents} />
          </div>
        )}
      </div>

      <Footer viewLinks={true} complete={true} />
    </BaseLayout>
  );
};

export default LandingPCLayout;
