import React from 'react';
import { Button, Form, FormGroup, Input, Alert, Label } from 'reactstrap';
import { Loading } from '../../components/layout';

import { useMyPassword } from '../../hooks';

const ChangePass = ({ interfaceContent, data }) => {
  const {
    inputs,
    message,
    loading,
    handleInputChange,
    handleSubmit,
  } = useMyPassword({ labels: interfaceContent });
  return (
    <div className="container pl-4 pr-4 pt-0 pb-1">
      <h5 className="font-weight-bold mb-2 pl-2">
        {interfaceContent.changePassTitleProfile || 'NULL'}
      </h5>

      <Form onSubmit={handleSubmit}>
        <Label className="mb-0 pl-2">
          {interfaceContent.actualPassChangeProfile || 'NULL'}
        </Label>
        <FormGroup>
          <Input
            className="only-border-btn pt-0 pl-2"
            type="password"
            name="password"
            required
            value={inputs.password}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label className="mb-0 pl-2">
            {interfaceContent.newPassChangeProfile || 'NULL'}
          </Label>
          <Input
            className="only-border-btn pt-0 pl-2"
            type="password"
            name="newPassword"
            required
            value={inputs.newPassword}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label className="mb-0 pl-2">
            {interfaceContent.reNewPassChangeProfile || 'NULL'}
          </Label>
          <Input
            className="only-border-btn pt-0 pl-2"
            type="password"
            name="newPasswordRepeat"
            required
            value={inputs.newPasswordRepeat}
            onChange={handleInputChange}
          />
        </FormGroup>
        {loading ? (
          <Loading iconColor="amarillo" className="text-center" />
        ) : (
          <>
            {message ? <Alert color="danger">{message}</Alert> : ''}
            <Button type="submit" className="btn-mycount-yellow">
              {interfaceContent.changePassTitleProfile || 'NULL'}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default ChangePass;
