import React, { useState, useEffect } from 'react';

import ButtonAddUser from './ButtonAddUser';
import ButtonRemoveUser from './ButtonRemoveUser';
import ModalRemoveUser from './ModalRemoveUser';

import { useRemoveUserGroup } from '../../hooks';

const ListGroup = ({ data, plan, labels, currentLang, setSearchData }) => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    handleSet,
    message,
    email,
    loadingHook,
  } = useRemoveUserGroup({ setOpen, setSearchData });

  const toggleModal = (name = '', email = '') => {
    handleSet(email);
    setOpen(!open);
    console.debug('open?', open);
  };
  return (
    <div className="container group-account p-2">
      {data && plan && data.length + 1 < plan.limit ? (
        <>
          <div className="text-center">
            <ButtonAddUser
              color="link"
              className="user-add"
              data={data}
              labels={labels}
              setSearchData={setSearchData}
            />
          </div>
          <div className="text-center">
            {labels.remainingUserGroupAccount
              ? labels.remainingUserGroupAccount
              : 'NULL'}
            : {plan.limit - (data.length + 1)}
          </div>
        </>
      ) : (
        ''
      )}
      <h6 className="font-weight-bold pt-3">
        {labels.listUserGroupAccount ? labels.listUserGroupAccount : 'NULL'}
      </h6>
      <div className="row header pb-2">
        <div className="col-5">
          {labels.nameUserGroupAccount ? labels.nameUserGroupAccount : 'NULL'}
        </div>
        <div className="col-5">
          {labels.emailGroupAccount ? labels.emailGroupAccount : 'NULL'}
        </div>
        <div className="col-2"></div>
      </div>
      {data && data.length > 0
        ? data.map((user, ind) => (
            <div className="row list" key={ind}>
              <div className="col-5 pt-2">{user.name}</div>
              <div className="col-5 pt-2">{user.email}</div>
              <div className="col-2">
                <ButtonRemoveUser
                  color="link"
                  className="pt-1"
                  toggleModal={toggleModal}
                  user={user.name}
                  email={user.email}
                />
              </div>
            </div>
          ))
        : ''}

      <ModalRemoveUser
        handleSubmit={handleSubmit}
        open={open}
        email={email}
        toggle={toggleModal}
        currentLang={currentLang}
        labels={labels}
        message={message}
        loadingHook={loadingHook}
      />
    </div>
  );
};

export default ListGroup;
