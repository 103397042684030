import React from 'react';
import MarkdownView from 'react-showdown';
import { Link } from 'gatsby';
import { Banner, Video, Methodology, Why, Ready, Result } from './index';
import { LanguageChanger } from '../menu';

const LandingPage = ({ interfaces, contents }) => {
  const {
    title,
    sub_title,
    banner,
    url_video,
    methodology,
    why,
    ready,
    result,
  } = contents;
  console.log('contents', contents);
  return (
    <div className="pb-4">
      <div className="h4 text-center">{title}</div>
      <div className="text-center">
        <MarkdownView
          markdown={sub_title}
          className="before-starting-content"
        />
      </div>

      {/* video o banner */}
      {url_video && <Video url={url_video} />}
      {!url_video && banner && banner.length > 0 && <Banner data={banner} />}

      {/* metodologia */}
      {methodology && <Methodology data={methodology} />}

      {/* por qué defi */}
      {why && <Why data={why} />}

      {/* listo para comenzar */}
      {ready && <Ready interfaces={interfaces} data={ready} />}

      {/* qué dicen de defi */}
      {result && <Result data={result} />}

      {/* cambio de lenguage */}
      {/* <LanguageChanger /> */}
      <br />
    </div>
  );
};

export default LandingPage;
