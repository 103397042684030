import React from 'react';
import { Link } from 'gatsby';

//Agregar número de tarea al props para el Link

const Title = ({ name, active, current, theme, available }) => {
  theme = theme.replace('theme-', 'theme-color-');
  const titleActive = current == active ? ' step-title-active' : '';
  return name.split(' ').length > 1 ? (
    <div className="step-title-container">
      <div
        className={
          !available
            ? 'theme-color-grey' + ' step-title'
            : theme + ' step-title' + titleActive
        }
      >
        <Link to={`/tarea/${active}`}>
          <span>{name.split(' ')[0]}</span>
          <span className="bold">
            {name
              .split(' ')
              .map((val, i) => {
                if (i === 0) {
                  return '';
                }
                return val;
              })
              .join(' ')}
          </span>
        </Link>
      </div>
    </div>
  ) : (
    <div className="step-title-container">
      <div
        className={
          !available
            ? 'theme-color-grey' + ' step-title'
            : theme + ' step-title' + titleActive
        }
      >
        <Link to={`/tarea/${active}`}>
          <span className="bold">{name}</span>
        </Link>
      </div>
    </div>
  );
};

export default Title;
