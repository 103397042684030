import React from 'react';
const FormatMoney = ({ number, format, className }) => {
  console.log('parametros ', number, format);
  const n = number;
  const decimales = format == 'es-CL' ? 0 : 2;
  const currency = format == 'es-CL' ? 'CLP' : 'USD';
  const format_number = new Intl.NumberFormat(format, {
    minimumFractionDigits: decimales,
    style: 'currency',
    currency: currency,
  }).format(Number(n));
  return (
    <span className={className ? 'plan_price ' + className : 'plan_price'}>
      {format_number}
    </span>
  );
};
export default FormatMoney;
