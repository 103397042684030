import React from 'react';
import { Form, Alert, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { Loading } from '../../components/layout';

const ModalRemoveUser = ({
  handleSubmit,
  open,
  toggle,
  labels,
  message,
  email,
  loadingHook,
}) => {
  return email ? (
    <Modal isOpen={open} className="modal-user-group">
      <ModalHeader className="text-center" toggle={toggle}>
        {labels.removeUserGroupAccount || 'NULL'}
      </ModalHeader>
      <ModalBody>
        {loadingHook ? (
          <div className="d-flex flex-column justify-content-center align-items-center p-5">
            <Loading iconColor="amarillo" />
          </div>
        ) : (
          <Form onSubmit={handleSubmit} className="">
            <div className="justify-content-center col-12">
              <div className="p-2 text-center font-weight-bold">
                {labels.removeUserConfirmGroupAccount || 'NULL'}
              </div>
              <div className="p-2 text-center">{email}</div>
            </div>
            {message ? <Alert color="warning">{message}</Alert> : ''}

            <div className="d-flex justify-content-center col-12 mt-3">
              <Button type="submit" className="remove-btn">
                {labels.removeUserGroupAccount || 'NULL'}
              </Button>
            </div>
          </Form>
        )}
      </ModalBody>
    </Modal>
  ) : (
    ''
  );
};

export default ModalRemoveUser;
