import React from 'react';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';

const ModalHasPlan = ({ open, toggle, labels }) => {
  return open && labels ? (
    <Modal isOpen={open} className="modal-user-group">
      <ModalHeader className="text-center" toggle={toggle}>
        {labels.hasPlanMessageTitle || 'hasPlanMessageTitle'}
      </ModalHeader>
      <ModalBody className="">
        <div className="row p-4">
          {labels.hasPlanMessageContent || 'hasPlanMessageContent'}
        </div>

        <div className="">
          <div className="text-center">
            <Button
              className="btn-plan-yellow font-weight-bold text-uppercase text-center"
              onClick={() => toggle(!open)}
            >
              Ok
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  ) : (
    ''
  );
};

export default ModalHasPlan;
