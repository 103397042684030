import React from 'react';
import { Link } from 'gatsby';

const ButtonDiscover = ({ id, orphan }) => {
  return orphan ? (
    <Link
      className="btn task-card-button-discover orphan"
      to={`/tarea/${id}`}
    />
  ) : (
    <Link
      className="btn task-card-button-discover"
      to={`/tarea/${id}/detalle`}
    />
  );
};

export default ButtonDiscover;
