import React from 'react';
import MarkdownView from 'react-showdown';

const MethodologyList = ({ data }) => {
  return (
    <div className="row justify-content-md-center">
      {data &&
        data.map((method, key) => (
          <div className="text-center col-6 col-md-2" key={key}>
            <div className="text-center font-weight-bold">{method.title}</div>
            <div>
              <MarkdownView
                markdown={method.sub_title}
                className="before-starting-content"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default MethodologyList;
