import React from 'react';
import MarkdownView from 'react-showdown';

const Content = ({ paragraph }) => {
  return (
    <MarkdownView markdown={paragraph} className="before-starting-content" />
  );
};

export default Content;
