import React from 'react';
import MarkdownView from 'react-showdown';
import Carousel from 'react-multi-carousel';

const CarouselText = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 80,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };
  return (
    <div className="landing-carousel-text">
      <Carousel
        arrows={false}
        infinite={true}
        keyBoardControl
        centerMode={false}
        swipeable
        showDots={true}
        containerClass="multi-carousel-container"
        sliderClass="multi-carousel-slider"
        itemClass="multi-carousel-item"
        responsive={responsive}
      >
        {data &&
          data.map((result, key) => (
            <div className="landing-carousel-text--item" key={key}>
              <div>
                <MarkdownView
                  markdown={result.title}
                  className="landing-carousel-text--title"
                />
              </div>
              {result.subtitle && (
                <MarkdownView
                  markdown={result.subtitle}
                  className="landing-carousel-text--text"
                />
              )}
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default CarouselText;
