import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse } from 'reactstrap';
import { Link } from 'gatsby';

import { setLastSubMenu } from '../../store/actions/menu';

const SubMenuItem = ({ label, uid, id, isActive }) => {
  const dispatch = useDispatch();
  const toggle = () => dispatch(setLastSubMenu(uid, id));
  return (
    <li className={isActive ? 'font-weight-bolder' : ''} onClick={toggle}>
      <Link to={`/contenidos/${uid}/${id}`}>{label}</Link>
    </li>
  );
};

const MenuItem = ({ title, uid, subItems, isCollapse, subMenuId }) => {
  const [isOpen, setIsOpen] = useState(false || isCollapse);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="menu-item">
      <button className="btn menu-item-button" onClick={toggle}>
        <span>{title}</span>
      </button>
      <Collapse isOpen={isOpen}>
        <ul className="sub-menu">
          {Array.isArray(subItems) &&
            subItems.map(({ title, id }) => {
              // const isActive = id === subMenuId ? true : false;
              return (
                <SubMenuItem
                  key={id}
                  uid={uid}
                  label={title}
                  id={id}
                  // isActive={isActive}
                />
              );
            })}
        </ul>
      </Collapse>
    </div>
  );
};

export default MenuItem;
