import React, { Fragment, useState } from 'react';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { BackButton, Card } from '../components/researchMore';
import { RelationalTask } from '../components/detailTask';
import { MessageModal } from '../components/steps';

const InvestigarMasLayout = ({
  id,
  data,
  theme,
  loading,
  error,
  unlockedTasks,
}) => {
  const [showModal, setShowModal] = useState(false);

  // Toggle modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <Fragment>
        <BackButton id={id} />
        <Card content={data.content} label={data.label} id={id} />
        <div className="relational-tasks-bg">
          <div className="relational-tasks-container">
            <RelationalTask
              carousel={data.content.relationalsTasks}
              title={data.label.relationalTaskLabel.title}
              subTitle={data.label.relationalTaskLabel.subTitle}
              unlockedTasks={unlockedTasks}
              toggleModal={toggleModal}
            />
          </div>
        </div>
        <MessageModal
          label={data.label.modal}
          showModal={showModal}
          toggle={toggleModal}
        />
      </Fragment>
    );
  }
  return (
    <BaseLayout
      title={
        data ? `(defi)² - ${data.content.name}` : '(defi)² - Investigar más'
      }
    >
      <div className={'background-proceso background-investigar-mas ' + theme}>
        <Header
          to="/menu"
          previous={`/tarea/${id}/investigar-mas`}
          logo="amarillo"
        />
        <div className="research-more-container">{content}</div>
      </div>
    </BaseLayout>
  );
};

export default InvestigarMasLayout;
