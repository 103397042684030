import React, { useState } from 'react';
import { Link } from 'gatsby';

const LoginMenuItemTop = ({ loginMenu, isLogged }) => {
  console.log('el menu:', loginMenu);
  return (
    isLogged && (
      <div className="menu-item">
        <Link className="btn menu-item-button" to="/etapa">
          <span>
            {loginMenu.find(menu => menu.key === 'myProgressMenuTitle').value}
          </span>
        </Link>
      </div>
    )
  );
};

export default LoginMenuItemTop;
