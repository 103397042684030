import React from 'react';
import MarkdownView from 'react-showdown';

const Titles = ({ data }) => {
  const { title, sub_title } = data;
  return (
    <div className="landing-title">
      <div className="landing-title--title">
        <MarkdownView markdown={title} className="before-starting-content" />
      </div>
      <div className="landing-title--subtitle">
        <MarkdownView
          markdown={sub_title}
          className="before-starting-content"
        />
      </div>
    </div>
  );
};

export default Titles;
