import React from 'react';

import icons from './title-icons.svg';

const TitleIcon = props => {
  const { icon, theme } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={`title-icon title-icon-${theme}`}
    >
      <use xlinkHref={`${icons}#${icon}`} />
    </svg>
  );
};

export default TitleIcon;
