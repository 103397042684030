import React from 'react';
import { Form, Alert, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { Loading } from '../../components/layout';

import { useUnsubscribe } from '../../hooks';

const ModalUnsubscribePlan = ({ open, toggle, labels }) => {
  const { loading, message, ok, handleSubmit } = useUnsubscribe({ labels });
  return open && labels ? (
    <Modal isOpen={open} className="">
      <ModalHeader className="text-center" toggle={toggle}>
        {labels.unsubscribeBillingPlan || 'NULL'}
      </ModalHeader>
      <ModalBody className="">
        <div className="p-3 font-weight-bold text-center">
          {labels.unsubscribeConfirmMessagePlan || 'NULL'}
        </div>
        <div className="p-3 text-center">
          {labels.unsubscribeConfirmNotePlan || 'NULL'}
        </div>

        <div className="">
          <div className="text-center">
            {loading ? (
              <div className="d-flex flex-column justify-content-center align-items-center p-2">
                <Loading iconColor="amarillo" />
              </div>
            ) : (
              !ok && (
                <Button
                  className="btn-plan-yellow font-weight-bold text-uppercase text-center"
                  onClick={() => handleSubmit()}
                >
                  {labels.unsubscribeYesConfirmPlan || 'NULL'}
                </Button>
              )
            )}
          </div>
          {message ? <Alert color="warning">{message}</Alert> : ''}
        </div>
      </ModalBody>
    </Modal>
  ) : (
    ''
  );
};

export default ModalUnsubscribePlan;
