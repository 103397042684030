import React from 'react';
import Carousel from 'react-multi-carousel';
import { Link } from 'gatsby';

const Title = ({ name, theme }) => {
  return (
    <div className={`relational-task-card-name ${theme}`}>
      {name.split(' ').length > 1 ? (
        <div className="relational-task-title">
          <h5 className="text-center">{name.split(' ')[0]}</h5>
          <h5 className="bold text-center">
            {name
              .split(' ')
              .map((val, i) => {
                if (i === 0) {
                  return '';
                }
                return val;
              })
              .join(' ')}
          </h5>
        </div>
      ) : (
        <div className="relational-task-title">
          <h5 className="bold">{name}</h5>
        </div>
      )}
    </div>
  );
};

const Card = ({ numberTask, icon, name, theme, isAvailable, toggleModal }) => {
  const content = isAvailable ? (
    <Link className="btn" to={`/tarea/${numberTask}`}>
      <div className="relational-task-card">
        <span
          className={`relational-task-number-circle relational-task-number-${theme}`}
        >
          {numberTask}
        </span>
        <img
          className="relational-task-card-icon"
          src={icon}
          alt={`Icono - ${name}`}
        />
        <Title name={name} theme={theme} />
      </div>
    </Link>
  ) : (
    <button className="btn" onClick={toggleModal}>
      <div className="relational-task-card">
        <span
          className={`relational-task-number-circle relational-task-number-theme-grey`}
        >
          {numberTask}
        </span>
        <img
          className="relational-task-card-icon"
          src={icon}
          alt={`Icono - ${name}`}
        />
        <Title name={name} theme="theme-grey" />
      </div>
    </button>
  );
  return content;
};

const RelationalsTask = ({
  title,
  subTitle,
  carousel,
  unlockedTasks,
  toggleModal,
}) => {
  const responsive = {
    xxlarge: {
      breakpoint: { max: 3000, min: 1920 },
      items: 5,
      partialVisibilityGutter: 20,
    },
    xlarge: {
      breakpoint: { max: 1920, min: 1440 },
      items: 5,
      partialVisibilityGutter: 20,
    },
    large: {
      breakpoint: { max: 1440, min: 1024 },
      items: 5,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <div className="detail-task-relational-task-container">
      <div className="detail-task-relational-task-header">
        <h3 className="detail-task-relational-task-title">{title}</h3>
        <h4 className="detail-task-relational-task-sub-title">{subTitle}</h4>
      </div>
      <Carousel
        responsive={responsive}
        arrows={true}
        keyBoardControl={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        containerClass="detail-task-relational-task-carousel-container"
        itemClass="relational-task-card-container"
      >
        {carousel &&
          carousel.map(task => (
            <Card
              key={task.numberTask}
              toggleModal={toggleModal}
              isAvailable={unlockedTasks.includes(task.numberTask)}
              {...task}
            />
          ))}
      </Carousel>
    </div>
  );
};

export default RelationalsTask;
