import React from 'react';
import { Link } from 'gatsby';

import Title from './Title';
import TaskNumber from '../../../task/TaskNumber';

const Card = ({
  icon,
  name,
  taskNumber,
  currentTask,
  isAvailable,
  theme,
  toggleModal,
}) => {
  const taskSelected =
    currentTask == taskNumber ? `${theme} step-selected` : '';
  const handler = isAvailable ? (
    <Link className="btn" to={`/tarea/${taskNumber}`}>
      <img
        className={
          isAvailable
            ? `${taskSelected} step-img`
            : `${taskSelected} step-img step-locked`
        }
        src={icon}
        alt="icono de la etapa"
        crossOrigin="true"
      />
    </Link>
  ) : (
    <button className="btn" onClick={toggleModal}>
      <img
        className={
          isAvailable
            ? `${taskSelected} step-img`
            : `${taskSelected} step-img step-locked`
        }
        src={icon}
        alt="icono de la etapa"
        crossOrigin="true"
      />
    </button>
  );
  return (
    <div className="step-card">
      {handler}
      <Title
        name={name}
        active={taskNumber}
        current={currentTask}
        theme={theme}
        available={isAvailable}
      />
      <TaskNumber
        active={taskNumber}
        current={currentTask}
        prevColor={isAvailable ? theme.replace('theme-', '') : 'grey'}
        activeColor={isAvailable ? theme.replace('theme-', '') : 'grey'}
        nextColor={isAvailable ? theme.replace('theme-', '') : 'grey'}
        showPrevLine={taskNumber == 41 ? false : true}
        showLastLine={taskNumber == 41 ? false : true}
      />
    </div>
  );
};

export default Card;
