import React from 'react';
import {
  Banner,
  Video,
  Buttons,
  CarouselText,
  CarouselImg,
  CarouselComment,
  Lists,
  Titles,
  Image,
  Separator,
} from './index';

const LandingPC = ({ contents }) => {
  return (
    <div className="landing-sections">
      {contents &&
        contents.map(
          (seccion, key) => (
            <div
              className="container-fluid pb-0 pt-1"
              key={key}
              style={{ backgroundColor: seccion.color || 'transparent' }}
            >
              {seccion.titulo && <Titles data={seccion.titulo} />}

              {seccion.video && (
                <Video
                  url={seccion.video.url_video}
                  subtitle_url={seccion.video.url_subtitle}
                  img_intro={
                    seccion.video.img_before && seccion.video.img_before
                  }
                  img_credit={
                    seccion.video.img_after && seccion.video.img_after
                  }
                  lang={seccion.video.lenguaje.abbr}
                  width={seccion.video.width}
                />
              )}

              {seccion.banner && (
                <Banner data={seccion.banner && seccion.banner.lista} />
              )}

              {seccion.carrusel_txt && (
                <CarouselText
                  data={seccion.carrusel_txt && seccion.carrusel_txt.lista}
                />
              )}

              {seccion.carrusel_imagen && (
                <CarouselImg
                  data={
                    seccion.carrusel_imagen && seccion.carrusel_imagen.lista
                  }
                />
              )}

              {seccion.carrusel_comentarios && (
                <CarouselComment
                  data={
                    seccion.carrusel_comentarios &&
                    seccion.carrusel_comentarios.lista
                  }
                />
              )}

              {seccion.listas && (
                <Lists data={seccion.listas && seccion.listas.lista} />
              )}

              {seccion.imagen && (
                <Image url={seccion.imagen && seccion.imagen.image.url} />
              )}

              {seccion.botones && (
                <Buttons data={seccion.botones && seccion.botones.lista} />
              )}

              {seccion.separador && (
                <Separator color={seccion.separador.color} />
              )}
            </div>
          )
          // seccion.titulo && <Titles data={seccion.titulo} />
        )}
    </div>
  );
};

export default LandingPC;
