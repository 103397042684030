import React from 'react';

import BaseLayout from './BaseLayout';
import { Footer } from '../components/layout';

const DefaultLayout = ({ children, title }) => {
  return (
    <BaseLayout title={title}>
      <div
        className="d-flex flex-column align-items-center justify-content-center background-defi-default"
        style={{ minHeight: '90vh' }}
      >
        {children}
      </div>
      <Footer />
    </BaseLayout>
  );
};

export default DefaultLayout;
