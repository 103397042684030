import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import MarkdownView from 'react-showdown';
import { Link } from 'gatsby';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import { useContactForm } from '../../hooks';
import { Loading } from '../../components/layout';

const Container = ({ labels, currentLang }) => {
  const {
    inputs,
    message,
    error,
    loading,
    sendOk,
    handleInputChange,
    handleSubmit,
    handleVerifyCaptcha,
    setVerifiyCaptcha,
  } = useContactForm({ currentLang, labels });
  return (
    <div className="password-recovery-container">
      <h2 className="text-center">{labels.contactTitle || 'contactTitle'}</h2>
      {!sendOk && (
        <Form onSubmit={handleSubmit}>
          <Label>{labels.nameUserContact || 'NULL'}</Label>
          <FormGroup>
            <Input
              type="text"
              name="name"
              required
              value={inputs.name}
              disabled={loading}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>{labels.emaiUserContact || 'NULL'}</Label>
            <Input
              type="email"
              name="email"
              required
              value={inputs.email}
              disabled={loading}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              {labels.messageUserContact || 'NULL'} (
              {inputs.limit - inputs.messageContact.length || 0})
            </Label>
            <Input
              type="textarea"
              name="messageContact"
              required
              value={inputs.messageContact}
              disabled={loading}
              onChange={handleInputChange}
              rows={4}
            />
          </FormGroup>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
            <GoogleReCaptcha onVerify={token => setVerifiyCaptcha(true)} />
          </GoogleReCaptchaProvider>
          <div className="text-center">
            {loading ? (
              <Loading />
            ) : (
              <Button
                type="submit"
                className="btn-general-white text-uppercase"
              >
                {labels.buttonSendMessage || 'NULL'}
              </Button>
            )}
          </div>
        </Form>
      )}
      {error && (
        <div className="alert alert-danger p-2 mt-2">
          <MarkdownView markdown={error} className="" />
        </div>
      )}
      {message && (
        <>
          <div className="alert alert-info p-2 mt-2">
            <MarkdownView markdown={message} className="" />
          </div>
          <Link to="/landing" className="btn-general-white p-2">
            {labels.backHomeContact || 'backHomeContact'}
          </Link>
        </>
      )}
    </div>
  );
};

export default Container;
