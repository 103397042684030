import React, { useState } from 'react';
import { navigate } from 'gatsby';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { LoginForm, ModalRecoveryPassword } from '../components/login';

const LoginLayout = ({
  data,
  loading,
  error,
  openRecoveryPassword,
  currentLang,
}) => {
  const [open, setOpen] = useState(openRecoveryPassword || false);

  // toggle modal
  const toggleModal = () => {
    setOpen(!open);
    navigate('#');
  };
  console.log(data);

  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <div className="login-container pt-5">
        <LoginForm label={data.login.label} toggleModal={toggleModal} />
        <ModalRecoveryPassword
          label={data.forgotPassword.label}
          open={open}
          toggle={toggleModal}
          currentLang={currentLang}
        />
      </div>
    );
  }

  return (
    <BaseLayout title="(defi)² - Login">
      <div className="d-flex flex-column background-defi-default">
        <div className="login">
          <Header to="/menu" previous="/etapa" logo="blanco" />
          {content}
        </div>
      </div>
    </BaseLayout>
  );
};

export default LoginLayout;
