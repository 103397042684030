import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { DefiPay } from '../components/plans';
import { api } from '../services';

import { updateRole } from '../store/actions/login';

const PagoDefiLayout = ({ payment }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [labels, setLabels] = useState({});
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const currentLang = useSelector(({ config }) => config.language);
  const currentToken = useSelector(({ login }) => login.token);

  const { fetchPlanSubscribe } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        if (!payment) return null;
        const response = await fetchPlanSubscribe(
          currentToken,
          payment,
          currentLang
        );

        if (!ignore) {
          setData(response.payPlans);
          setLabels(response.labels);
          console.debug(response.userRole);
          if (response.userRole) dispatch(updateRole(response.userRole));
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [payment]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      if (error) {
        setContent(<Error msg={error} />);
      } else {
        setContent(
          loading ? (
            <div className="d-flex flex-column justify-content-center align-items-center p-5">
              <Loading iconColor="amarillo" />
            </div>
          ) : (
            <div>
              <DefiPay data={data} labels={labels} loading={loading} />
            </div>
          )
        );
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [loading, error]);

  return (
    <BaseLayout title="(defi)² - Payment">
      <section className="">
        <Header
          to="/etapa"
          previous={`/pago-defi/${payment}`}
          logo="amarillo"
          isActive={true}
        />
        <div className="container-fluid">{content}</div>
      </section>
    </BaseLayout>
  );
};

export default PagoDefiLayout;
