import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import FormatMoney from './FormatMoney';
import MarkdownView from 'react-showdown';

import { api } from '../../services';

import { Card, CardTitle, CardSubtitle, Button } from 'reactstrap';

const Plan = ({
  plan,
  labels,
  currentLang,
  country,
  handleSelected,
  toggleModal,
  toggleModalHasPlan,
  toggleModalChangePlan,
  enabledPlan,
}) => {
  const currentToken = useSelector(({ login }) => login.token);
  const { roleName } = useSelector(({ login }) => login.user);
  const isLogged = useSelector(({ login }) => login.isLogged);
  const { assignPlanFree } = api();

  const freePlan = useCallback(async () => {
    const data = await assignPlanFree(currentToken);
    console.debug(data);
    try {
      if (data && data.status_pay) navigate('/etapa');
    } catch (err) {
      // navigate('/404');
      navigate('/login', { state: { previousPath: '/logout' } });
    }
  });

  const setSelected = plan => {
    console.debug('planes:', plan.id, enabledPlan, roleName);
    handleSelected(plan);
    // si tiene plan contratado y coincide con el plan seleccionado, muestra modal de plan ya contratado
    if (
      enabledPlan &&
      enabledPlan.plan &&
      plan.id &&
      parseInt(enabledPlan.plan) === parseInt(plan.id)
    ) {
      toggleModalHasPlan();
    }
    // de 'statusPayPlan' indica un plan activo y es usuario Premium Admin
    else if (enabledPlan && enabledPlan.plan && roleName === 'Premium Admin') {
      toggleModalChangePlan();
    }
    // si NO esta logeado o el plan no es free, se depliega el modal original
    else if ((plan.price_clp > 0 && plan.price_usd > 0) || !isLogged) {
      toggleModal();
    }
    // de lo contrario de contrata el plan free
    else {
      freePlan();
    }
  };
  return (
    <Card
      className={`card-plan col-12 col-md-4 p-4 mt-2 ${
        parseInt(enabledPlan.plan) === parseInt(plan.id)
          ? 'card-plan-selected'
          : ''
      }`}
    >
      <CardTitle tag="h2" className="text-center">
        {plan.description_plan.title}
        {enabledPlan.plan &&
          plan.id &&
          parseInt(enabledPlan.plan) === parseInt(plan.id) && (
            <span className="far fa-check-circle plan-actual"></span>
          )}
      </CardTitle>
      <CardSubtitle tag="h3" className="mb-2 text-center">
        {country === 'CL' ? (
          plan.offer_price_clp ? (
            <div>
              <span className="plan_offer_container">
                <FormatMoney
                  className="plan_offer"
                  number={plan.price_clp}
                  format={'es-CL'}
                />
                <sup className="plan_currency" title="Pesos chilenos">
                  CLP
                </sup>
              </span>
              <span>
                <FormatMoney number={plan.offer_price_clp} format={'es-CL'} />
                <sup className="plan_currency" title="Pesos chilenos">
                  CLP
                </sup>
              </span>
            </div>
          ) : (
            <span>
              <FormatMoney number={plan.price_clp} format={'es-CL'} />
              <sup className="plan_currency" title="Pesos chilenos">
                CLP
              </sup>
            </span>
          )
        ) : plan.offer_price_usd ? (
          <div>
            <span className="plan_offer_container">
              <sup className="plan_currency" title="US Dollars">
                USD
              </sup>
              <FormatMoney
                className="plan_offer"
                number={plan.price_usd}
                format={'en-EN'}
              />
            </span>
            <span>
              <sup className="plan_currency" title="US Dollars">
                USD
              </sup>
              <FormatMoney number={plan.price_usd} format={'en-EN'} />
            </span>
          </div>
        ) : (
          <span>
            <sup className="plan_currency" title="US Dollars">
              USD
            </sup>
            <FormatMoney number={plan.price_usd} format={'en-EN'} />
          </span>
        )}
      </CardSubtitle>
      {!isNaN(plan.user_limit) && plan.user_limit > 0 && (
        <CardSubtitle tag="h4" className="mb-2 text-muted">
          {currentLang === 'ES' ? 'Limite de usuarios ' : 'Users limit'}
          {plan.user_limit > 0 ? plan.user_limit : 1}
        </CardSubtitle>
      )}
      <CardSubtitle tag="span">{plan.description_plan.subtitle}</CardSubtitle>
      <hr className="p-1 m-1" />
      <MarkdownView markdown={plan.description_plan.description} />
      <Button
        className={
          plan.price_clp > 0 && plan.price_usd > 0
            ? 'card-plan-btn'
            : 'card-plan-free-btn'
        }
        onClick={() => setSelected(plan)}
      >
        {plan.price_clp > 0 && plan.price_usd > 0
          ? labels.button
          : labels.buttonFree}
      </Button>
    </Card>
  );
};

export default Plan;
