import React from 'react';
import MarkdownView from 'react-showdown';

const Content = ({ letter, words }) => {
  return (
    <div className="container static-content glossary-content-container">
      <h2 id={letter} className="glossary-content-letter">
        {letter}
      </h2>
      {words &&
        words.map(({ definition }) => (
          <div className="glossary-content-word-container">
            <MarkdownView
              className="glossary-content-definition"
              markdown={definition}
            />
          </div>
        ))}
    </div>
  );
};

export default Content;
