import React from 'react';
import MarkdownView from 'react-showdown';
import Carousel from 'react-multi-carousel';

const CarouselComment = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 80,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };
  return (
    <div className="carousel-comment">
      <Carousel
        arrows={true}
        infinite={true}
        keyBoardControl
        centerMode={false}
        swipeable
        draggable
        showDots={false}
        containerClass="multi-carousel-container"
        sliderClass="multi-carousel-slider"
        itemClass="multi-carousel-item"
        responsive={responsive}
      >
        {data &&
          data.map((result, key) => (
            <div className="text-center text-box col-12" key={key}>
              <div className="">
                <MarkdownView
                  markdown={result.comment}
                  className="before-starting-content pl-4 pr-4 pt-3"
                />
              </div>
              <div className="text-center font-weight-bold">{result.title}</div>
              <div className="">
                <MarkdownView
                  markdown={result.subtitle}
                  className="text-center font-weight-light pl-4 pr-4"
                />
              </div>
              {result.image && (
                <div className="carousel-comment-image-container">
                  <img
                    src={process.env.STRAPI_URL + result.image.url}
                    className="img-result rounded-circle mx-auto d-block"
                    alt="result.name"
                  />
                </div>
              )}
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default CarouselComment;
