import React from 'react';
import { Link } from 'gatsby';

const TaskNumber = ({
  prevColor,
  activeColor,
  active,
  current,
  nextColor,
  showPrevLine,
  showLastLine,
}) => {
  const prev = (
    <span
      className={`task-number-line task-number-color-${
        active > 1 ? prevColor : 'transparent'
      }`}
    ></span>
  );
  //Acá la última tarea queda fija, se debería cambiar si es que en el futuro se agregasen más
  const next = (
    <span
      className={`task-number-line task-number-color-${
        active < 41 ? nextColor : 'transparent'
      }`}
    ></span>
  );

  return (
    <div className="task-number-container">
      {showPrevLine ? prev : null}
      <Link to={`/tarea/${active}`}>
        <span
          className={
            current == active
              ? `task-number-circle task-number-current task-number-color-${activeColor}`
              : `task-number-circle task-number-color-${activeColor}`
          }
        >
          {active}
        </span>
      </Link>
      {showLastLine ? next : null}
    </div>
  );
};

export default TaskNumber;
