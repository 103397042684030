import React from 'react';
import { Link } from 'gatsby';

const BackButton = ({ id }) => {
  return (
    <Link className="research-more-back-button" to={`/tarea/${id}/detalle`} />
  );
};

export default BackButton;
