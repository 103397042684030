import React, { useState, useEffect } from 'react';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { ListGroup } from '../components/plans';

const CuentaGrupalLayout = ({
  data,
  plan,
  labels,
  loading,
  currentLang,
  error,
  setSearchData,
}) => {
  const [contenido, setcontenido] = useState(null);
  useEffect(() => {
    let mounted = true;
    if (error) {
      setcontenido(<Error msg={error} />);
    } else if (mounted) {
      console.debug('listando usuarios');
      loading
        ? setcontenido(
            <div className="d-flex flex-column justify-content-center align-items-center p-5">
              <Loading iconColor="amarillo" />
            </div>
          )
        : setcontenido(
            <div>
              <h2 className="font-weight-bold text-center">
                {labels.titleGroupAccount ? labels.titleGroupAccount : 'NULL'}
              </h2>
              <ListGroup
                data={data}
                plan={plan}
                labels={labels}
                currentLang={currentLang}
                setSearchData={setSearchData}
              />
            </div>
          );
    } else {
      setcontenido('');
    }
    return () => (mounted = false);
  }, [data, loading, error, setSearchData]);

  return (
    <BaseLayout title="(defi)² - Payment">
      <section className="step-container p-2 background-step-default">
        <Header to="/menu" previous={`/cuenta-grupal/`} logo="amarillo" />
        <div className="container-fluid">{contenido}</div>
      </section>
    </BaseLayout>
  );
};

export default CuentaGrupalLayout;
