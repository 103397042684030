import React from 'react';

const Separator = ({ color }) => {
  return (
    <div className="col-12 pl-4 pr-4">
      <hr
        className="landing-hr m-0 p-2 pl-4 pr-4"
        style={{ borderColor: color }}
      />
    </div>
  );
};

export default Separator;
