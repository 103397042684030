import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import MarkdownView from 'react-showdown';
import { CommentGroup } from '../../components/comments';
import { Video } from '../landingPage';

const Container = ({
  title,
  content,
  files,
  categoria,
  urlVideo,
  urlSub,
  imageBefore,
  imageAfter,
  language,
}) => {
  const [classVisible, setClassVisible] = useState('hide');

  useEffect(() => {
    const onScroll = e => {
      if (e.target.documentElement.scrollTop > 0) {
        setClassVisible('show');
      } else {
        setClassVisible('hide');
      }
    };
    window.addEventListener('scroll', onScroll);
    console.log(
      'datos',
      urlVideo,
      urlSub,
      imageBefore,
      imageAfter,
      language,
      process.env.STRAPI_URL,
      '-'
    );

    return () => window.removeEventListener('scroll', onScroll);
  }, [classVisible]);

  return (
    <div className="row position-relative justify-content-center m-0">
      <div className="premium-content col-12 col-md-10">
        <div className="text-center pb-2">
          <Link
            className="btn btn-light badge-pill font-weight-bold pl-3 pr-3 pt-0 pb-0"
            to={`/contenido-pro/category/${encodeURI(categoria)}`}
          >
            {categoria}
          </Link>
        </div>
        <h2 className="premium-content-title text-center">{title}</h2>
        {urlVideo && (
          <Video
            url={urlVideo}
            subtitle_url={urlSub}
            img_intro={imageBefore}
            img_credit={imageAfter}
            lang={language}
          />
        )}
        {files.length > 0 && !urlVideo ? (
          <div className="premium-content-image-container">
            <img
              className="premium-content-image"
              alt={title}
              src={process.env.STRAPI_URL + files[0].url}
            />
          </div>
        ) : null}
        <MarkdownView
          markdown={content.replace(
            '/uploads/',
            process.env.STRAPI_URL + '/uploads/'
          )}
          className="premium-content-container"
        />
        <CommentGroup />
      </div>
      <div className="background-top-premium col-xs-12"></div>
    </div>
  );
};

export default Container;
