import React from 'react';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from 'reactstrap';

import { useForgotPasswordForm } from '../../hooks';

const ModalRecoveryPassword = ({ open, toggle, label, currentLang }) => {
  const { title, emailLabel, button, messages, recoveryIntro } = label;
  const { email, message, handleSubmit, emailHandler } = useForgotPasswordForm(
    messages,
    toggle,
    currentLang
  );

  return (
    <Modal isOpen={open} toggle={toggle} className="login-modal">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <p>{recoveryIntro}</p>

        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label className="login-modal-label">{emailLabel}</Label>
            <Input
              type="email"
              name="email"
              aria-describedby="emailHelp"
              required
              value={email}
              onChange={emailHandler}
            />
          </FormGroup>
          <Button type="submit" className="login-btn">
            {button}
          </Button>
        </Form>
        <small>{message ? message : ''}</small>
      </ModalBody>
    </Modal>
  );
};

export default ModalRecoveryPassword;
