import React from 'react';
import { ResultList } from './index';

const Result = ({ data }) => {
  const { title, sub_title, result_list } = data;
  return (
    <div className="methodology p-3">
      <div className="h4 text-center">{title}</div>

      {result_list && result_list.length > 0 && (
        <ResultList data={result_list} />
      )}
    </div>
  );
};

export default Result;
