import React from 'react';
import { Link } from 'gatsby';
import { Video } from '../landingPage';
const ListContent = ({ data }) => {
  //Estos valores se deberían obtener desde strapi
  const categoria = 'Categoría'; //Se obtiene desde el item
  const destacado = 'Destacado'; //Se obtiene desde colección de contenidos de interfaz: contenido-pro/destacado

  const data_destacado = data.filter(item => item.destacado === true);
  const data_normal = data.filter(item => item.destacado !== true);
  return (
    <div className="pr-2 pl-3">
      {data_destacado &&
        data_destacado.map((item, i) => (
          <div className="contenido-pro-destacados col-12 pb-2" key={i}>
            <div className="contenido-pro-item">
              <div className="contenido-pro-item-content row">
                <div className="contenido-pro-item-content-img col-12 col-md-6">
                  {item.urlVideo && (
                    <Video
                      url={item.urlVideo}
                      subtitle_url={item.urlSub}
                      img_intro={item.imageBefore || ''}
                      img_credit={item.imageAfter || ''}
                      lang={item.language}
                    />
                  )}
                  {item.imagen.length > 0 && !item.urlVideo ? (
                    <Link to={`/contenido-premium/${item.uid}`}>
                      <img
                        className="contenido-pro-item-image"
                        alt={item.title}
                        src={process.env.STRAPI_URL + item.imagen[0].url}
                      />
                    </Link>
                  ) : null}
                </div>
                <div className="contenido-pro-item-content-text col-12 col-md-6">
                  <h5 className="contenido-pro-item-destacado-label">
                    {destacado}
                  </h5>

                  <h6 className="contenido-pro-item-category">
                    <Link
                      to={`/contenido-pro/category/${encodeURI(
                        item.categoria
                      )}`}
                    >
                      {item.categoria}
                    </Link>
                  </h6>
                  <h3 className="contenido-pro-item-title">
                    <Link to={`/contenido-premium/${item.uid}`}>
                      {item.title}
                    </Link>
                  </h3>
                  <p className="contenido-pro-item-preview">{item.preview}</p>
                  <Link
                    className="contenido-pro-item-link"
                    to={`/contenido-premium/${item.uid}`}
                  >
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className="contenido-pro-items row col-12 pt-2">
        {data_normal &&
          data_normal.map((item, i) => (
            <div
              className="contenido-pro-item col-12 col-md-4 d-flex p-3"
              key={i}
            >
              <div className="contenido-pro-item-content col-12 d-flex flex-column p-1">
                {/* <div className="contenido-pro-item-content-img col-12"> */}
                {item.urlVideo && (
                  <Video
                    url={item.urlVideo}
                    subtitle_url={item.urlSub}
                    img_intro={item.imageBefore || ''}
                    img_credit={item.imageAfter || ''}
                    lang={item.language}
                  />
                )}
                {item.imagen.length > 0 && !item.urlVideo ? (
                  <Link to={`/contenido-premium/${item.uid}`}>
                    <img
                      className="contenido-pro-item-image"
                      alt={item.title}
                      src={process.env.STRAPI_URL + item.imagen[0].url}
                    />
                  </Link>
                ) : null}
                {/* </div>
                <div className="contenido-pro-item-content-text col-12"> */}
                <h6 className="contenido-pro-item-category">
                  <Link
                    to={`/contenido-pro/category/${encodeURI(item.categoria)}`}
                  >
                    {item.categoria}
                  </Link>
                </h6>
                <h3 className="contenido-pro-item-title">
                  <Link to={`/contenido-premium/${item.uid}`}>
                    {item.title}
                  </Link>
                </h3>
                <p className="contenido-pro-item-preview">{item.preview}</p>
                <Link
                  className="contenido-pro-item-link"
                  to={`/contenido-premium/${item.uid}`}
                >
                  <i className="fas fa-long-arrow-alt-right"></i>
                </Link>
                {/* </div> */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ListContent;
