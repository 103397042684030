import React from 'react';
import MarkdownView from 'react-showdown';

const Content = ({ title, paragraph }) => {
  return (
    <div className="">
      <h2 className="splash-title">{title}</h2>
      <MarkdownView markdown={paragraph} className="splash-content" />
    </div>
  );
};

export default Content;
