import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { MyPlan, MyBilling } from '../components/plans';

const MiPlanLayout = ({ data, labels, loading, currentLang, error }) => {
  const [contenido, setcontenido] = useState(null);
  useEffect(() => {
    if (error) {
      // content = <Error msg={error} />;
      setcontenido(<Error msg={error} />);
    } else {
      loading
        ? setcontenido(
            <div className="d-flex flex-column justify-content-center align-items-center p-5">
              <Loading iconColor="amarillo" />
            </div>
          )
        : setcontenido(
            <div>
              <h2 className="font-weight-bold text-center">
                {labels.titleMyPlan ? labels.titleMyPlan : 'NULL'}
              </h2>
              <MyPlan
                data={data}
                labels={labels}
                loading={loading}
                currentLang={currentLang}
              />
              <hr className="pl-4 pr-4" />
              <MyBilling
                data={data}
                labels={labels}
                loading={loading}
                currentLang={currentLang}
              />
            </div>
          );
    }
  }, [data, loading, error]);

  return (
    <BaseLayout title="(defi)² - Payment">
      <section className="">
        <Header to="/menu" previous={`/mi-plan/`} logo="amarillo" />
        <div className="container-fluid">{contenido}</div>
      </section>
    </BaseLayout>
  );
};

export default MiPlanLayout;
