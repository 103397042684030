import React from 'react';

const DiscoverButton = ({ label, onClick }) => {
  return (
    <div className="discover-container">
      <div className="discover-button-container">
        <button className="btn discover-button-icon" onClick={onClick} />
        <span className="discover-label">{label}</span>
      </div>
    </div>
  );
};

export default DiscoverButton;
