import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ModalChangePlan from './ModalChangePlan';

const MyPlan = ({ data, labels, loading, currentLang }) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };
  const FormatMoney = ({ number, decPlaces, decSep, thouSep }) => {
    console.log('APLICANDO FUNCION FORMAT MONEY');
    decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
    decSep = typeof decSep === 'undefined' ? '.' : decSep;
    thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
    let j;
    let sign = number < 0 ? '-' : '';
    let i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    j = (j = i.length) > 3 ? j % 3 : 0;

    return (
      sign +
      (j ? i.substr(0, j) + thouSep : '') +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : '')
    );
  };
  return (
    <div className="container pl-4 pr-4 pt-2 pb-2">
      {!loading && data ? (
        data && data !== {} && data.description ? (
          <>
            <h5 className="font-weight-bold">
              {labels.titleMyPlan ? labels.titleMyPlan : 'NULL'}
            </h5>
            <h4 className="mb-0">{data.title}</h4>
            <h5 className="mb-0">{data.subtitle}</h5>
            <div>
              <span>
                {currentLang === 'ES' ? '$' : 'US $'}
                {currentLang === 'ES' ? (
                  <FormatMoney
                    number={data.price_clp}
                    decPlaces={0}
                    decSep={''}
                    thouSep={'.'}
                  />
                ) : (
                  <FormatMoney
                    number={data.price_usd}
                    decPlaces={0}
                    decSep={''}
                    thouSep={','}
                  />
                )}
              </span>
              &nbsp;/&nbsp;
              <span>
                {data.user_limit}{' '}
                {labels.userLimitMyPlan ? labels.userLimitMyPlan : 'NULL'}
              </span>
            </div>
            <div>
              <Button
                className="btn-plan-yellow font-weight-bold text-uppercase text-center mt-2"
                onClick={() => toggleModal(!open)}
              >
                {labels.changeMessageTitleMyPlan
                  ? labels.changeMessageTitleMyPlan
                  : 'NULL'}
              </Button>
            </div>

            <ModalChangePlan open={open} toggle={toggleModal} labels={labels} />
          </>
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </div>
  );
};

export default MyPlan;
