import React from 'react';
import MarkdownView from 'react-showdown';
import {
  Form,
  Alert,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap';
import { Loading } from '../layout';

import FormatMoney from './FormatMoney';

import ButtonPayPal from './ButtonPayPal';

const ModalPayPlans = ({
  handlePaypal,
  handleSubmit,
  handleMethod,
  method,
  url,
  loadingPayment,
  open,
  toggle,
  plan,
  currentLang,
  country,
  labels,
  message,
}) => {
  return plan ? (
    <Modal isOpen={open} toggle={toggle} className="pay-modal">
      <ModalHeader className="text-center" toggle={toggle}>
        {plan.description_plan.title}
      </ModalHeader>
      <ModalBody>
        <h5 className="text-center">{plan.description_plan.subtitle}</h5>
        <h3 className="text-center">
          {country === 'CL' ? (
            plan.offer_price_clp ? (
              <div>
                <span className="plan_offer_container">
                  <FormatMoney
                    className="plan_offer"
                    number={plan.price_clp}
                    format={'es-CL'}
                  />
                  <sup className="plan_currency" title="Pesos chilenos">
                    CLP
                  </sup>
                </span>
                <span>
                  <FormatMoney
                    number={plan.offer_price_clp}
                    format={'es-CL'}
                    className="font-weight-bold"
                  />
                  <sup className="plan_currency" title="Pesos chilenos">
                    CLP
                  </sup>
                </span>
              </div>
            ) : (
              <span>
                <FormatMoney
                  number={plan.price_clp}
                  format={'es-CL'}
                  className="font-weight-bold"
                />
                <sup className="plan_currency" title="Pesos chilenos">
                  CLP
                </sup>
              </span>
            )
          ) : plan.offer_price_usd ? (
            <div>
              <span className="plan_offer_container">
                <sup className="plan_currency" title="US Dollars">
                  USD
                </sup>
                <FormatMoney
                  className="plan_offer"
                  number={plan.price_usd}
                  format={'en-EN'}
                />
              </span>
              <span>
                <sup className="plan_currency" title="US Dollars">
                  USD
                </sup>
                <FormatMoney
                  number={plan.price_usd}
                  format={'en-EN'}
                  className="font-weight-bold"
                />
              </span>
            </div>
          ) : (
            <span>
              <sup className="plan_currency" title="US Dollars">
                USD
              </sup>
              <FormatMoney
                number={plan.price_usd}
                format={'en-EN'}
                className="font-weight-bold"
              />
            </span>
          )}
        </h3>
        <div className="text-center">{plan.description_plan.description}</div>

        <h5 className="text-center pt-4">{labels.plansPayMethod || 'NULL'}</h5>

        <Form onSubmit={handleSubmit} className="modal-plan">
          <div className="plans-payments-buttons">
            <div className="plans-payments-button">
              <Input
                type="radio"
                name="paymethod"
                className="sr-only"
                id="btn-paypal"
                onChange={handleMethod}
                value="paypal"
                disabled={loadingPayment}
              />
              <Label for="btn-paypal" className="btn-payment">
                <img
                  src="/img/logo-paypal.svg"
                  alt="paypal"
                  className="mx-auto d-block"
                />
              </Label>
            </div>
            <div className="plans-payments-button">
              <Input
                type="radio"
                name="paymethod"
                className="sr-only"
                id="btn-flow"
                onChange={handleMethod}
                value="flow"
                disabled={loadingPayment}
              />
              <Label for="btn-flow" className="btn-payment">
                <img
                  src="/img/logo-flow.svg"
                  alt="flow"
                  className="mx-auto d-block"
                />
              </Label>
            </div>
          </div>
          {message ? <Alert color="warning">{message || 'NULL'}</Alert> : ''}

          <div className="d-flex justify-content-center col-12 mt-3">
            {loadingPayment ? (
              <Loading iconColor="amarillo" />
            ) : (
              <div style={{ minHeight: '50px' }} className="w-100 text-center">
                {(method === 'flow' || method === 'paypal') &&
                  labels.goToPaySubscribe && <h4>{labels.goToPaySubscribe}</h4>}
                {method === 'flow' && (
                  <Button
                    type="submit"
                    color="link"
                    className="button-flow p-0"
                  >
                    <img
                      src="https://www.flow.cl/img/botones/btn-pagar-negro.png"
                      alt="pago-flow"
                    />
                  </Button>
                )}
                {method === 'paypal' && (
                  <ButtonPayPal
                    clientID={process.env.PAYPAL_CLIEND_ID}
                    planID={plan.id_paypal}
                    approvePaypal={handlePaypal}
                    errorPaypal={() => alert(labels.errorPayment)}
                  />
                )}
              </div>
            )}
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="text-justify font-italic">
          <MarkdownView
            markdown={labels.plansFootNote || 'NULL'}
            className="before-starting-content"
          />
          {/* {url} */}
        </div>
      </ModalFooter>
    </Modal>
  ) : (
    ''
  );
};

export default ModalPayPlans;
