import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'gatsby';

const SubMenuItem = ({ letter, hasWords }) => {
  const activeLink = hasWords ? 'word-active' : 'word-inactive';
  const link = `/glosario/${letter}`;
  if (hasWords) {
    return (
      <Link className={`glossary-sub-menu-item ${activeLink}`} to={link}>
        {letter}
      </Link>
    );
  } else {
    return <span className="glossary-sub-menu-item">{letter}</span>;
  }
};

const GlossaryMenuItem = ({
  title,
  subItems,
  isCollapse,
  uid,
  subMenuLetter,
}) => {
  const [isOpen, setIsOpen] = useState(false || isCollapse);

  const toggle = () => setIsOpen(!isOpen);
  const letters = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'.split('');

  return (
    <div className="menu-item">
      <button className="btn menu-item-button" onClick={toggle}>
        <span>{title}</span>
      </button>
      <Collapse isOpen={isOpen}>
        <div className="glossary-sub-menu">
          <div>
            {letters.map(letter => {
              const subMenuData = subItems.find(
                subMenu => subMenu.letter === letter
              );
              const hasWords = subMenuData ? true : false;
              return (
                <SubMenuItem key={letter} letter={letter} hasWords={hasWords} />
              );
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default GlossaryMenuItem;
