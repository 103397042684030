import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { Content } from '../components/staticContents';
import { api } from '../services';

const ContenidoEstaticoLayout = ({ staticContent, subContent }) => {
  const currentLang = useSelector(({ config }) => config.language);
  const [data, setData] = useState({ content: '', title: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { fetchStaticContentData } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        const response = await fetchStaticContentData(
          currentLang,
          staticContent,
          subContent
        );
        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, fetchStaticContentData]);

  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <Content content={data.content} title={data.title} />
    );
  }

  return (
    <BaseLayout
      title={data ? `(defi)² - ${data.title}` : '(defi)² - Contenido'}
    >
      <div className="">
        <Header to="/menu" previous="/antes-de-comenzar" logo="amarillo" />
        {content}
      </div>
    </BaseLayout>
  );
};

export default ContenidoEstaticoLayout;
