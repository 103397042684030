import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import axios from 'axios';

import Plan from './Plan';
import ModalPayPlans from './ModalPayPlans';
import ModalLoginRegister from './ModalLoginRegister';
import ModalHasPlan from './ModalHasPlan';
import ModalChangePlan from './ModalChangePlan';

import { usePlanPay } from '../../hooks';

const Plans = ({ data, labels, loading, currentLang, enabledPlan = false }) => {
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openHasPlan, setOpenHasPlan] = useState(false);
  const [openChangePlan, setOpenChangePlan] = useState(false);

  const [planes, setPlanes] = useState([]);
  const [periodo, setPeriodo] = useState('meses');

  const [countryCode, setCountryCode] = useState();
  const isLogged = useSelector(({ login }) => login.isLogged);

  let clases = 'btn-period font-weight-bold text-uppercase';
  let clases_mes =
    periodo === 'meses'
      ? clases + ' selected btn-month'
      : clases + ' btn-month';
  let clases_anio =
    periodo === 'anios' ? clases + ' selected btn-year' : clases + ' btn-year';

  const {
    inputs,
    message,
    url,
    loadingPayment,
    handleSubmit,
    handleSelected,
    handleMethod,
    handlePaypal,
  } = usePlanPay(labels);

  useEffect(() => {
    //Ordenamos la data por precio ASC
    let dataSorted = data.slice(0);
    dataSorted.sort((a, b) => {
      const x = a.price_clp;
      const y = b.price_clp;
      return x < y ? -1 : x > y ? 1 : 0;
    });
    if (periodo === 'meses') {
      setPlanes(
        dataSorted.filter(
          obj => obj.months_of_duration < 12 || obj.price_clp === 0
        )
      );
    } else {
      setPlanes(
        dataSorted.filter(
          obj => obj.months_of_duration >= 12 || obj.price_clp === 0
        )
      );
    }
  }, [periodo, data]);

  useEffect(() => {
    if (!countryCode) {
      axios
        .get('https://ipapi.co/json')
        .then(response => {
          setCountryCode(response.data.country_code);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [countryCode]);

  const toggleModal = () => {
    console.debug('plan isLogged', isLogged);
    if (isLogged) {
      setOpen(!open);
    } else {
      setOpenLogin(!openLogin);
    }
  };

  const toggleModalHasPlan = () => {
    setOpenHasPlan(!openHasPlan);
  };

  const toggleModalChangePlan = () => {
    setOpenChangePlan(!openChangePlan);
  };

  console.log(labels);

  return (
    <div className="plans-container">
      <h2 className="font-weight-bold text-center">
        {labels.plansTitle ? labels.plansTitle : 'NULL'}
      </h2>
      <h6 className="text-center">
        {labels.plansSubTitle ? labels.plansSubTitle : 'NULL'}
      </h6>

      <div className="plans-selector p-2">
        <InputGroup className="plans-header">
          <InputGroupAddon addonType="prepend">
            <Button className={clases_mes} onClick={() => setPeriodo('meses')}>
              {labels.plansPeriodMonth ? labels.plansPeriodMonth : 'NULL'}
            </Button>
          </InputGroupAddon>
          <InputGroupAddon addonType="append">
            <Button className={clases_anio} onClick={() => setPeriodo('anios')}>
              {labels.plansPeriodYear ? labels.plansPeriodYear : 'NULL'}
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <div className="plans-planes col-12">
        {planes && planes.length > 0
          ? planes.map((plan, ind) => (
              <Plan
                key={ind}
                plan={plan}
                labels={{
                  button: labels.plansButton,
                  buttonFree: labels.plansButtonFree,
                }}
                currentLang={currentLang}
                country={countryCode}
                handleSelected={handleSelected}
                toggleModal={toggleModal}
                toggleModalHasPlan={toggleModalHasPlan}
                toggleModalChangePlan={toggleModalChangePlan}
                enabledPlan={enabledPlan}
              />
            ))
          : 'No hay planes actualmente en esta categoría.'}
      </div>

      <ModalPayPlans
        handlePaypal={handlePaypal}
        handleSubmit={handleSubmit}
        handleMethod={handleMethod}
        method={inputs.method}
        url={url}
        loadingPayment={loadingPayment}
        open={open}
        toggle={toggleModal}
        currentLang={currentLang}
        country={countryCode}
        plan={inputs.plan}
        labels={labels}
        message={message}
      />
      <ModalLoginRegister
        open={openLogin}
        toggle={toggleModal}
        labels={labels}
      />
      <ModalHasPlan
        open={openHasPlan}
        toggle={toggleModalHasPlan}
        labels={labels}
      />
      <ModalChangePlan
        open={openChangePlan}
        toggle={toggleModalChangePlan}
        labels={labels}
      />
    </div>
  );
};

export default Plans;
