import React, { useEffect, useState } from 'react';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { ListContent } from '../components/premiumContent';

const ContenidoProLayout = props => {
  const [contenido, setcontenido] = useState(null);
  const [lista, setLista] = useState([]);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    let mounted = true;
    setFilter(true);
    console.debug('props.data', props.data, mounted);
    try {
      if (props.error) {
        setcontenido(<Error msg={props.error} />);
      } else if (mounted && props.data && !filter) {
        console.debug('categoria', props.categoria);
        if (props.categoria) {
          if (props.categoria === 'all' || !props.categoria) {
            setLista(props.data);
          } else {
            const dataFilter = props.data.filter(
              dataList => dataList.categoria === props.categoria
            );
            setLista(dataFilter);
          }
        }
      } else {
        setcontenido(null);
      }
    } catch {
      setcontenido(null);
    } finally {
      setFilter(false);
    }
    return () => (mounted = false);
  }, [props.categoria, props.data]);

  useEffect(() => {
    const setContent = () => {
      props.loading || filter
        ? setcontenido(
            <div className="d-flex flex-column justify-content-center align-items-center p-5">
              <Loading iconColor="amarillo" />
            </div>
          )
        : setcontenido(
            <div className="contenidos-pro-container">
              <header className="contenidos-pro-header">
                <Header
                  to="/menu"
                  previous={`/contenido-pro/${props.categoria}`}
                  logo="blanco"
                />
                {(props.categoria === 'all' || props.categoria) && (
                  <>
                    <h2 className="">{props.title ? props.title : 'NULL'}</h2>
                    <p className="pb-3">
                      {props.description ? props.description : 'NULL'}
                    </p>
                  </>
                )}
              </header>
              {props.categoria !== 'all' && (
                <h2 className="p-3 text-center font-weight-bold">
                  {props.categoria}
                </h2>
              )}
              <ListContent data={lista} />
            </div>
          );
    };
    setContent();
  }, [filter, props.loading, lista]);

  return (
    <BaseLayout title={`(defi)² - ${props.title}`}>
      <section className="background-step-default">{contenido}</section>
    </BaseLayout>
  );
};

export default ContenidoProLayout;
