import React from 'react';
import { Link } from 'gatsby';

const StepsSvgDesktop = ({ unlocked }) => {
  return (
    <svg
      className="step-indicator-svg-desktop d-none d-xl-block"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="20 120 1300 560"
    >
      <g id="lineas">
        <g id="L1">
          <rect
            x="84.2"
            className={unlocked.includes(1) ? 'step-black' : 'step-grey'}
            y="399.3"
            width="53.6"
            height="3"
          />
          <polygon
            className={unlocked.includes(1) ? 'step-black' : 'step-grey'}
            points="132.6,407.7 130.6,405.5 135.5,400.8 130.6,396.2 132.6,394 139.9,400.8 		"
          />
        </g>
        <path
          id="L2"
          className={unlocked.includes(2) ? 'step-teal' : 'step-grey'}
          d="M152.2,401.7l-0.1-3c58.1-2.2,104.1-9.3,136.6-21.1c26.2-9.5,44.3-22.4,53.9-38.3l2.6,1.6
		c-4.7,7.8-11.3,14.9-19.7,21.1c-9.5,7-21.5,13.2-35.7,18.4C256.9,392.3,210.6,399.5,152.2,401.7z"
        />
        <path
          id="L3"
          className={unlocked.includes(3) ? 'step-teal' : 'step-grey'}
          d="M345.1,340.9l-2.6-1.6c5.9-9.7,8.7-20.6,8.7-33.3c0-18.9-7.3-36-20.6-48.4l2-2.2
		c13.7,12.7,21.6,31.1,21.6,50.6C354.3,319.2,351.3,330.6,345.1,340.9z"
        />
        <path
          id="L4"
          className={unlocked.includes(4) ? 'step-teal' : 'step-grey'}
          d="M244.9,258.1l-2-2.2c12.2-11.4,28.4-17.6,45.8-17.6c16.4,0,32.1,6.1,44,17.2l-2,2.2
		c-11.4-10.6-26.3-16.4-42-16.4C272.1,241.2,256.5,247.2,244.9,258.1z"
        />
        <path
          id="L5"
          className={unlocked.includes(5) ? 'step-teal' : 'step-grey'}
          d="M230,340.5c-5.6-9.6-8.3-20.9-8.3-34.5c0-19.6,7.5-37.4,21.2-50.1l2,2.2c-13,12.2-20.2,29.2-20.2,47.9
		c0,13.1,2.6,23.9,7.9,33L230,340.5z"
        />
        <g id="L6">
          <path
            className={unlocked.includes(6) ? 'step-teal' : 'step-grey'}
            d="M257.5,368c-1.8-1.5-3.6-2.9-5.4-4.3c-8.1-6.4-15.8-12.4-22.1-23.2l2.6-1.5c6,10.4,13.5,16.2,21.3,22.4
			c1.8,1.4,3.6,2.8,5.5,4.3L257.5,368z"
          />
          <polygon
            className={unlocked.includes(6) ? 'step-teal' : 'step-grey'}
            points="250.2,368.9 250,365.9 256.7,365.5 255.8,358.7 258.8,358.3 260.2,368.3 		"
          />
          <path
            className={unlocked.includes(6) ? 'step-teal' : 'step-grey'}
            d="M400.2,404.8c-59.5-1.3-105.3-12.3-136.2-32.7l1.7-2.5c30.4,20.1,75.7,31,134.6,32.2L400.2,404.8z"
          />
        </g>
        <path
          id="L7"
          className={unlocked.includes(7) ? 'step-blue' : 'step-grey'}
          d="M400,405v-3c77.9,0,125.6-16,164.7-55.1l2.1,2.1C527.1,388.8,478.8,405,400,405z"
        />
        <path
          id="L8"
          className={unlocked.includes(8) ? 'step-blue' : 'step-grey'}
          d="M566.8,349l-2.1-2.1c16.9-16.9,25.4-36.3,25.4-57.7c0-4.7-0.4-9.5-1.2-14.1l3-0.5
		c0.9,4.8,1.3,9.7,1.3,14.6C593.1,311.5,584.3,331.6,566.8,349z"
        />
        <path
          id="L9"
          className={unlocked.includes(9) ? 'step-blue' : 'step-grey'}
          d="M588.9,275.1c-4.6-25.8-21.7-47.9-45.6-59.1l1.3-2.7c24.8,11.6,42.5,34.5,47.3,61.3L588.9,275.1z"
        />
        <path
          id="L10"
          className={unlocked.includes(10) ? 'step-blue' : 'step-grey'}
          d="M475,216.3l-1.3-2.7c11.2-5.4,23.1-8.2,35.3-8.2c12.4,0,24.4,2.7,35.5,7.9l-1.3,2.7
		c-10.8-5.1-22.3-7.6-34.3-7.6C497.2,208.4,485.7,211.1,475,216.3z"
        />
        <path
          id="L11"
          className={unlocked.includes(11) ? 'step-blue' : 'step-grey'}
          d="M430.1,275.3l-3-0.5c4.8-26.5,22.2-49.4,46.5-61.2l1.3,2.7C451.5,227.6,434.7,249.7,430.1,275.3z"
        />
        <path
          id="L12"
          className={unlocked.includes(12) ? 'step-blue' : 'step-grey'}
          d="M452,346.1c-16.1-16.7-23.3-34.1-23.3-56.2c0-4.9,0.4-9.8,1.3-14.6l-3-0.5c-0.9,5-1.4,10.1-1.4,15.1
		c0,23.1,7.5,41.1,24.3,58.5c0,0,0.1,0.1,0.1,0.1L452,346.1z"
        />
        <g id="L13">
          <path
            className={unlocked.includes(13) ? 'step-blue' : 'step-grey'}
            d="M640.8,414.9c-66.5,0-115.8-12.5-155.2-39.4l1.7-2.5c38.9,26.5,87.6,38.9,153.5,38.9V414.9z"
          />
          <polygon
            className={unlocked.includes(13) ? 'step-blue' : 'step-grey'}
            points="471.6,373.4 471.1,370.5 477.8,369.4 476.2,362.8 479.1,362.1 481.5,371.8 		"
          />
          <path
            className={unlocked.includes(13) ? 'step-blue' : 'step-grey'}
            d="M452.2,346.3c-0.1-0.1-0.1-0.1-0.2-0.2l-1.9,2.3c8.3,8.6,16.6,15.3,28.7,23.4l1.7-2.5
			C468.5,361.4,460.3,354.7,452.2,346.3z"
          />
        </g>
        <path
          id="L14"
          className={unlocked.includes(14) ? 'step-magenta' : 'step-grey'}
          d="M640.8,414.9v-3c37.6,0,80-5.9,116.4-16.2c42.7-12.1,75.6-29.5,95-50.3l2.2,2
		c-19.8,21.3-53.2,39-96.4,51.2C721.4,409,678.7,414.9,640.8,414.9z"
        />
        <path
          id="L15"
          className={unlocked.includes(15) ? 'step-magenta' : 'step-grey'}
          d="M854.4,347.5l-2.2-2c18-19.3,27.2-41.7,27.2-66.5c0-5.9-0.5-11.9-1.5-17.6l3-0.5
		c1,5.9,1.6,12.1,1.6,18.2C882.4,304.5,872.9,327.6,854.4,347.5z"
        />
        <path
          id="L16"
          className={unlocked.includes(16) ? 'step-magenta' : 'step-grey'}
          d="M877.8,261.3c-5.3-30-23.5-55.4-50.1-69.8l1.4-2.6c27.4,14.9,46.2,41.1,51.6,72L877.8,261.3z"
        />
        <path
          id="L17"
          className={unlocked.includes(17) ? 'step-magenta' : 'step-grey'}
          d="M732.8,191.8l-1.5-2.6c15-8.3,31.9-12.6,49.1-12.6c17.1,0,33.9,4.2,48.7,12.3l-1.4,2.6
		c-14.3-7.8-30.7-11.9-47.3-11.9C763.8,179.5,747.3,183.8,732.8,191.8z"
        />
        <path
          id="L18"
          className={unlocked.includes(18) ? 'step-magenta' : 'step-grey'}
          d="M683,261.7l-3-0.5c5.3-30.7,24.1-57,51.4-72l1.5,2.6C706.3,206.4,688.1,231.9,683,261.7z"
        />
        <path
          id="L19"
          className={unlocked.includes(19) ? 'step-magenta' : 'step-grey'}
          d="M706.1,347.2c-19.1-19.8-27.6-40.9-27.6-68.3c0-6,0.5-11.9,1.5-17.7l3,0.5c-1,5.6-1.5,11.4-1.5,17.2
		c0,26.6,8.3,47,26.8,66.2L706.1,347.2z"
        />
        <g id="L20">
          <path
            className={unlocked.includes(20) ? 'step-magenta' : 'step-grey'}
            d="M909.9,423.6c-63.7-3.8-119-19.1-164.2-45.5l1.5-2.6c44.8,26.2,99.6,41.3,162.9,45.1L909.9,423.6z"
          />
          <path
            className={unlocked.includes(20) ? 'step-magenta' : 'step-grey'}
            d="M741.2,375.6c-15.4-10.3-25.6-18.5-35.1-28.3l2.2-2.1c9.3,9.7,19.4,17.8,34.6,27.9L741.2,375.6z"
          />
          <polygon
            className={unlocked.includes(20) ? 'step-magenta' : 'step-grey'}
            points="733.9,377.2 733.5,374.2 740.2,373.1 738.6,366.5 741.5,365.8 743.8,375.6 		"
          />
        </g>
        <path
          id="L21"
          className={unlocked.includes(21) ? 'step-magenta' : 'step-grey'}
          d="M942.8,424.6c-11.6,0-23.7-0.5-32.9-1l0.2-3c13,0.7,32,1.4,46.8,0.7l0.2,3
		C952.6,424.5,947.7,424.6,942.8,424.6z"
        />
        <path
          id="L22"
          className={unlocked.includes(22) ? 'step-red' : 'step-grey'}
          d="M957.2,424.3l-0.2-3c86.4-5.9,179.6-55.3,221.6-117.4l2.5,1.7C1138.6,368.4,1044.5,418.3,957.2,424.3
		z"
        />
        <path
          id="L23"
          className={unlocked.includes(23) ? 'step-red' : 'step-grey'}
          d="M1181,305.6l-2.5-1.7c14.6-21.6,22-43.3,22-64.5c0-1.8-0.1-3.7-0.2-5.5l3-0.2
		c0.1,1.9,0.2,3.8,0.2,5.7C1203.6,261.2,1196,283.5,1181,305.6z"
        />
        <path
          id="L24"
          className={unlocked.includes(24) ? 'step-red' : 'step-grey'}
          d="M1200.4,233.9c-1.3-23.7-11.1-46.2-27.6-63.2l2.2-2.1c17,17.5,27.1,40.7,28.4,65.2L1200.4,233.9z"
        />
        <path
          id="L25"
          className={unlocked.includes(25) ? 'step-red' : 'step-grey'}
          d="M1172.9,170.7c-19-19.7-45-30.5-73.1-30.5v-3c28.9,0,55.7,11.2,75.3,31.4L1172.9,170.7z"
        />
        <path
          id="L26"
          className={unlocked.includes(26) ? 'step-red' : 'step-grey'}
          d="M1027.4,171.2l-2.2-2.1c19.5-20.6,46-32,74.5-32v3C1072.1,140.2,1046.4,151.2,1027.4,171.2z"
        />
        <path
          id="L27"
          className={unlocked.includes(27) ? 'step-red' : 'step-grey'}
          d="M1000.4,233.4l-3-0.2c1.4-23.9,11.3-46.7,27.8-64.1l2.2,2.1C1011.4,188.1,1001.8,210.2,1000.4,233.4z
		"
        />
        <path
          id="L28"
          className={unlocked.includes(28) ? 'step-red' : 'step-grey'}
          d="M1020,305c-15.5-21.5-22.8-42.4-22.8-65.6c0-2.1,0.1-4.2,0.2-6.2l3,0.2c-0.1,2-0.2,4-0.2,6
		c0,22.5,7.1,42.8,22.2,63.8L1020,305z"
        />
        <g id="L29">
          <path
            className={unlocked.includes(29) ? 'step-red' : 'step-grey'}
            d="M1167.8,445.4c-18.5-21.5-43.6-42.5-67.8-62.9c-10.6-8.9-21.5-18.1-31.5-27l2-2.2
			c9.9,8.9,20.4,17.6,31.4,26.9c24.3,20.4,49.5,41.6,68.2,63.2L1167.8,445.4z"
          />
          <path
            className={unlocked.includes(29) ? 'step-red' : 'step-grey'}
            d="M1064.2,352.1c-15.3-13.9-31.3-29.1-44.2-47l2.4-1.8c12.8,17.7,28.7,32.7,43.8,46.6L1064.2,352.1z"
          />
          <polygon
            className={unlocked.includes(29) ? 'step-red' : 'step-grey'}
            points="1056.8,352.5 1056.8,349.5 1063.6,349.5 1063,342.7 1066,342.5 1066.9,352.5 		"
          />
        </g>
        <path
          id="L30"
          className={unlocked.includes(30) ? 'step-orange' : 'step-grey'}
          d="M1191.6,483.1c-5.4-13.2-13.4-25.8-23.8-37.6l2.3-2c10.5,12.1,18.7,25,24.3,38.5L1191.6,483.1z"
        />
        <path
          id="L31"
          className={unlocked.includes(31) ? 'step-orange' : 'step-grey'}
          d="M1203.5,527.9h-3c0-15.5-3-30.6-8.9-44.9l2.8-1.1C1200.5,496.5,1203.5,512,1203.5,527.9z"
        />
        <path
          id="L32"
          className={unlocked.includes(32) ? 'step-orange' : 'step-grey'}
          d="M1187.9,582l-2.5-1.6c9.1-14.4,14.3-31,15.1-48.1c0.1-1.4,0.1-2.9,0.1-4.4h3c0,1.5,0,3-0.1,4.5
		C1202.7,550,1197.3,567.1,1187.9,582z"
        />
        <path
          id="L33"
          className={unlocked.includes(33) ? 'step-orange' : 'step-grey'}
          d="M1150.8,616.8l-1.4-2.6c14.6-8.1,27.1-19.7,36-33.8l2.5,1.6C1178.7,596.4,1165.9,608.5,1150.8,616.8z
		"
        />
        <path
          id="L34"
          className={unlocked.includes(34) ? 'step-orange' : 'step-grey'}
          d="M1100.4,629.6v-3c17.2,0,34.1-4.3,48.9-12.5l1.4,2.6C1135.5,625.1,1118.1,629.6,1100.4,629.6z"
        />
        <path
          id="L35"
          className={unlocked.includes(35) ? 'step-orange' : 'step-grey'}
          d="M1100.4,629.6c-17.1,0-34.2-4.6-49.3-13.2l1.5-2.6c14.7,8.4,31.2,12.8,47.8,12.8V629.6z"
        />
        <path
          id="L36"
          className={unlocked.includes(36) ? 'step-orange' : 'step-grey'}
          d="M1051.1,616.4c-14.8-8.4-27.4-20.6-36.5-35l2.5-1.6c8.8,14,21.1,25.8,35.5,34L1051.1,616.4z"
        />
        <path
          id="L37"
          className={unlocked.includes(37) ? 'step-orange' : 'step-grey'}
          d="M1014.5,581.4c-9.3-14.7-14.6-31.7-15.4-49l3-0.1c0.8,16.8,6,33.2,15,47.5L1014.5,581.4z"
        />
        <path
          id="L38"
          className={unlocked.includes(38) ? 'step-orange' : 'step-grey'}
          d="M999.1,532.4c-0.1-1.7-0.1-3.3-0.1-5c0-15.8,3.1-31.2,9.2-45.7l2.8,1.2c-6,14.1-9,29.1-9,44.5
		c0,1.6,0,3.2,0.1,4.8L999.1,532.4z"
        />
        <path
          id="L39"
          className={unlocked.includes(39) ? 'step-orange' : 'step-grey'}
          d="M1011,482.9l-2.8-1.2c6.6-15.6,16.7-30.3,30.1-43.9l2.1,2.1C1027.3,453.2,1017.4,467.7,1011,482.9z"
        />
        <g id="L40">
          <path
            className={unlocked.includes(40) ? 'step-orange' : 'step-grey'}
            d="M1151.9,380.5l-0.5-3c10.5-1.7,21.3-2.6,32.1-2.6c43.8,0,73.2,0.3,73.5,0.3l0,3c-0.3,0-29.7-0.3-73.4-0.3
			C1172.8,378,1162.2,378.8,1151.9,380.5z"
          />
          <path
            className={unlocked.includes(40) ? 'step-orange' : 'step-grey'}
            d="M1040.4,439.9l-2.1-2.1c37.7-38.3,90-57.9,108.3-59.4l0.3,3C1131.2,382.7,1078.4,401.4,1040.4,439.9z"
          />
          <polygon
            className={unlocked.includes(40) ? 'step-orange' : 'step-grey'}
            points="1142.2,387.1 1140,385.1 1144.6,380.1 1139.2,375.9 1141.1,373.5 1149,379.7 		"
          />
        </g>
        <g id="L41">
          <rect
            x="1257"
            y="375.3"
            className={unlocked.includes(41) ? 'step-black' : 'step-grey'}
            width="28.7"
            height="3"
          />
          <polygon
            className={unlocked.includes(41) ? 'step-black' : 'step-grey'}
            points="1280.6,383.6 1278.5,381.4 1283.5,376.8 1278.5,372.1 1280.6,369.9 1287.9,376.8 		"
          />
        </g>
      </g>
      <g id="puntos">
        <Link to={unlocked.includes(3) ? '/tarea/3' : '#'}>
          <path
            id="C2"
            className={unlocked.includes(3) ? 'step-teal' : 'step-grey'}
            d="M288.1,354.7c27.2,0,49.3-22.1,49.3-49.3c0-27.2-22.1-49.3-49.3-49.3c-27.2,0-49.3,22.1-49.3,49.3
		C238.8,332.7,260.8,354.7,288.1,354.7"
          />
        </Link>
        <Link to={unlocked.includes(7) ? '/tarea/7' : '#'}>
          <path
            id="C3"
            className={unlocked.includes(7) ? 'step-blue' : 'step-grey'}
            d="M509.2,349.9c33.5,0,60.7-27.2,60.7-60.7s-27.2-60.7-60.7-60.7s-60.7,27.2-60.7,60.7
		S475.6,349.9,509.2,349.9"
          />
        </Link>
        <Link to={unlocked.includes(15) ? '/tarea/15' : '#'}>
          <path
            id="C4"
            className={unlocked.includes(14) ? 'step-magenta' : 'step-grey'}
            d="M780.5,343.7c36,0,65.2-29.2,65.2-65.2s-29.2-65.2-65.2-65.2c-36,0-65.2,29.2-65.2,65.2
		S744.5,343.7,780.5,343.7"
          />
        </Link>
        <Link to={unlocked.includes(23) ? '/tarea/23' : '#'}>
          <path
            id="C5"
            className={unlocked.includes(22) ? 'step-red' : 'step-grey'}
            d="M1099.8,304.7c36,0,65.2-29.2,65.2-65.2s-29.2-65.2-65.2-65.2c-36,0-65.2,29.2-65.2,65.2
		S1063.7,304.7,1099.8,304.7"
          />
        </Link>
        <Link to={unlocked.includes(30) ? '/tarea/30' : '#'}>
          <path
            id="C6"
            className={unlocked.includes(30) ? 'step-orange' : 'step-grey'}
            d="M1100.9,592.3c36,0,65.2-29.2,65.2-65.2s-29.2-65.2-65.2-65.2s-65.2,29.2-65.2,65.2
		S1064.9,592.3,1100.9,592.3"
          />
        </Link>
        <Link to={unlocked.includes(1) ? '/tarea/1' : '#'}>
          <path
            id="E1"
            className={unlocked.includes(1) ? 'step-black' : 'step-grey'}
            d="M90.5,412.3c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5S79,394.5,79,400.8
		C79,407.2,84.1,412.3,90.5,412.3"
          />
          <path
            id="N1"
            className="step-white"
            d="M91.7,403.1v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4
		H91.7z"
          />
        </Link>
        <Link to={unlocked.includes(2) ? '/tarea/2' : '#'}>
          <path
            id="E2"
            className={unlocked.includes(2) ? 'step-black' : 'step-grey'}
            d="M156,412.3c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5s-11.5,5.1-11.5,11.5
		C144.5,407.2,149.6,412.3,156,412.3"
          />
          <path
            id="N2"
            className="step-white"
            d="M155.3,403.1l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6
		c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H155.3z"
          />
        </Link>
        <Link to={unlocked.includes(3) ? '/tarea/3' : '#'}>
          <path
            id="E3"
            className={unlocked.includes(3) ? 'step-teal' : 'step-grey'}
            d="M344.2,351.1c6.5,0,11.7-5.2,11.7-11.7c0-6.5-5.2-11.7-11.7-11.7c-6.5,0-11.7,5.2-11.7,11.7
		C332.5,345.9,337.8,351.1,344.2,351.1"
          />
          <path
            id="N3"
            className="step-white"
            d="M347.6,341.3c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6
		c-1.9,0-3.2,1.1-3.2,2.9h1.6c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9
		c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8C346.1,343.9,347.6,343,347.6,341.3
		"
          />
        </Link>
        <Link to={unlocked.includes(4) ? '/tarea/4' : '#'}>
          <path
            id="E4"
            className={unlocked.includes(4) ? 'step-teal' : 'step-grey'}
            d="M332.2,268c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C320.7,262.8,325.9,268,332.2,268"
          />
          <path
            id="N4"
            className="step-white"
            d="M330.2,257.7l2.9-4h0v4L330.2,257.7L330.2,257.7z M335.8,257.7h-1.2v-5.5h-2.1l-4,5.6v1.3h4.5v2h1.6
		v-2h1.2V257.7z"
          />
        </Link>
        <Link to={unlocked.includes(5) ? '/tarea/5' : '#'}>
          <path
            id="E5"
            className={unlocked.includes(5) ? 'step-teal' : 'step-grey'}
            d="M243.9,268c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C232.4,262.8,237.6,268,243.9,268"
          />
          <path
            id="N5"
            className="step-white"
            d="M244.1,255.3c-0.6,0-1.1,0.1-1.6,0.3l0.2-2.1h4.1v-1.4h-5.4l-0.4,4.5l1.4,0.5c0.4-0.3,0.9-0.5,1.6-0.5
		c1,0,1.8,0.5,1.8,1.6c0,1-0.8,1.5-1.8,1.5c-1,0-1.8-0.4-1.8-1.4l-1.6,0c0,1.7,1.5,2.8,3.4,2.8c1.9,0,3.4-1.1,3.4-2.9
		C247.3,256.3,245.9,255.3,244.1,255.3"
          />
        </Link>
        <Link to={unlocked.includes(6) ? '/tarea/6' : '#'}>
          <path
            id="E6"
            className={unlocked.includes(6) ? 'step-teal' : 'step-grey'}
            d="M230.7,351.1c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C219.2,346,224.4,351.1,230.7,351.1"
          />
          <path
            id="N6"
            className="step-white"
            d="M230.9,342.9c-0.7,0-1.2-0.3-1.5-0.8h0c-0.6-1.3,0.4-2.2,1.5-2.2c1,0,1.6,0.6,1.6,1.5
		C232.5,342.3,231.9,342.9,230.9,342.9 M231.1,338.6c-0.9,0-1.7,0.4-2.2,1c0-2.1,0.8-3,2-3c0.8,0,1.4,0.4,1.4,1.1h1.6
		c0-1.5-1.2-2.5-3-2.5c-2.2,0-3.6,1.6-3.6,4.6c0,2.9,1.3,4.5,3.5,4.5c1.9,0,3.2-1.2,3.2-2.9C234.1,339.6,232.8,338.5,231.1,338.6"
          />
        </Link>
        <Link to={unlocked.includes(7) ? '/tarea/7' : '#'}>
          <path
            id="E7"
            className={unlocked.includes(7) ? 'step-blue' : 'step-grey'}
            d="M397.6,414.2c6.4,0,11.5-5.1,11.5-11.5s-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		S391.2,414.2,397.6,414.2"
          />
          <polygon
            id="N7"
            className="step-white"
            points="395.4,407.3 397.2,407.3 401,399.6 401,398.4 394.2,398.4 394.2,399.7 399.2,399.7 
		399.2,399.7 	"
          />
        </Link>
        <Link to={unlocked.includes(8) ? '/tarea/8' : '#'}>
          <path
            id="E8"
            className={unlocked.includes(8) ? 'step-blue' : 'step-grey'}
            d="M566.8,359.5c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C555.3,354.4,560.5,359.5,566.8,359.5"
          />
          <path
            id="N8"
            className="step-white"
            d="M566.8,351.4c-1.1,0-1.8-0.5-1.8-1.4c0-0.9,0.7-1.4,1.8-1.4c1.1,0,1.8,0.5,1.8,1.4
		C568.6,350.9,567.9,351.4,566.8,351.4 M566.8,344.9c1,0,1.5,0.5,1.5,1.2c0,0.7-0.6,1.2-1.5,1.2c-0.9,0-1.5-0.5-1.5-1.2
		C565.3,345.4,565.9,344.9,566.8,344.9 M568.8,347.9c0.6-0.4,1-1,1-1.8c0-1.6-1.4-2.5-3.1-2.5c-1.7,0-3.1,0.9-3.1,2.5
		c0,0.8,0.4,1.4,1,1.8c-0.8,0.4-1.3,1.1-1.3,2.1c0,1.8,1.4,2.7,3.4,2.7c2,0,3.4-0.9,3.4-2.7C570.2,349,569.6,348.3,568.8,347.9"
          />
        </Link>
        <Link to={unlocked.includes(9) ? '/tarea/9' : '#'}>
          <path
            id="E9"
            className={unlocked.includes(9) ? 'step-blue' : 'step-grey'}
            d="M590.3,286.4c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C578.8,281.2,583.9,286.4,590.3,286.4"
          />
          <path
            id="N9"
            className="step-white"
            d="M590,274.9c-1,0-1.7-0.6-1.7-1.5c0-0.9,0.7-1.5,1.7-1.5c0.7,0,1.3,0.3,1.6,0.8
		C592.3,274,591.2,274.9,590,274.9 M590,270.4c-2,0-3.2,1.2-3.2,2.9c0,1.7,1.3,2.8,3.1,2.8c0.9,0,1.7-0.4,2.2-1c0,2.1-0.8,3-2.1,3
		c-0.8,0-1.4-0.4-1.4-1.1H587c0,1.5,1.2,2.5,3,2.5c2.2,0,3.6-1.6,3.6-4.6C593.6,271.9,592.3,270.4,590,270.4"
          />
        </Link>
        <Link to={unlocked.includes(10) ? '/tarea/10' : '#'}>
          <path
            id="E10"
            className={unlocked.includes(10) ? 'step-blue' : 'step-grey'}
            d="M544.8,226.4c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5s-11.5,5.1-11.5,11.5
		C533.3,221.3,538.5,226.4,544.8,226.4"
          />
          <path
            id="N10"
            className="step-white"
            d="M548.5,218.2c-1.2,0-2-1-2-3.2c0-2.1,0.7-3.2,2-3.2c1.2,0,1.9,1.1,1.9,3.2
		C550.4,217.2,549.7,218.2,548.5,218.2 M548.5,219.6c2.2,0,3.5-1.6,3.5-4.5c0-2.9-1.3-4.6-3.5-4.6c-2.2,0-3.5,1.6-3.5,4.6
		C544.9,218,546.3,219.6,548.5,219.6 M542.3,218.2v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9
		v-1.4H542.3z"
          />
        </Link>
        <Link to={unlocked.includes(11) ? '/tarea/11' : '#'}>
          <path
            id="E11"
            className={unlocked.includes(11) ? 'step-blue' : 'step-grey'}
            d="M473.8,226.4c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C462.3,221.3,467.5,226.4,473.8,226.4"
          />
          <path
            id="N11"
            className="step-white"
            d="M478.6,218.2v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4
		H478.6z M471.3,218.2v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H471.3z"
          />
        </Link>
        <Link to={unlocked.includes(12) ? '/tarea/12' : '#'}>
          <path
            id="E12"
            className={unlocked.includes(12) ? 'step-blue' : 'step-grey'}
            d="M428.3,286.4c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C416.8,281.2,422,286.4,428.3,286.4"
          />
          <path
            id="N12"
            className="step-white"
            d="M431.3,278.1l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6
		c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H431.3z M425.9,278.1v-7.6h-1.2
		c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H425.9z"
          />
        </Link>
        <Link to={unlocked.includes(13) ? '/tarea/13' : '#'}>
          <path
            id="E13"
            className={unlocked.includes(13) ? 'step-blue' : 'step-grey'}
            d="M452,359.5c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C440.5,354.4,445.6,359.5,452,359.5"
          />
          <path
            id="N13"
            className="step-white"
            d="M459,350.1c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9
		h1.6c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9c1.1,0,1.9,0.4,1.9,1.3
		c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8C457.5,352.7,459,351.7,459,350.1 M449.5,351.3v-7.6h-1.2
		c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H449.5z"
          />
        </Link>
        <Link to={unlocked.includes(14) ? '/tarea/14' : '#'}>
          <path
            id="E14"
            className={unlocked.includes(14) ? 'step-blue' : 'step-grey'}
            d="M640.3,424.8c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C628.8,419.7,634,424.8,640.3,424.8"
          />
          <path
            id="N14"
            className="step-white"
            d="M642,414.5l2.9-4h0v4L642,414.5L642,414.5z M647.6,414.5h-1.2V409h-2.1l-4,5.6v1.3h4.5v2h1.6v-2h1.2
		V414.5z M637.9,416.6V409h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H637.9z"
          />
        </Link>
        <Link to={unlocked.includes(15) ? '/tarea/15' : '#'}>
          <path
            id="E15"
            className={unlocked.includes(15) ? 'step-magenta' : 'step-grey'}
            d="M853.5,357.6c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C842,352.4,847.1,357.6,853.5,357.6"
          />
          <path
            id="N15"
            className="step-white"
            d="M857.3,344.9c-0.6,0-1.1,0.1-1.6,0.3l0.2-2.1h4.1v-1.4h-5.4l-0.4,4.5l1.4,0.5
		c0.4-0.3,0.9-0.5,1.6-0.5c1,0,1.8,0.5,1.8,1.6c0,1-0.8,1.5-1.8,1.5c-1,0-1.8-0.4-1.8-1.4l-1.6,0c0,1.7,1.5,2.8,3.4,2.8
		c1.9,0,3.4-1.1,3.4-2.9C860.5,345.9,859.1,344.9,857.3,344.9 M851,349.3v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3
		c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H851z"
          />
        </Link>
        <Link to={unlocked.includes(16) ? '/tarea/16' : '#'}>
          <path
            id="E16"
            className={unlocked.includes(16) ? 'step-magenta' : 'step-grey'}
            d="M878.7,272.5c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C867.2,267.4,872.4,272.5,878.7,272.5"
          />
          <path
            id="N16"
            className="step-white"
            d="M882.6,264.3c-0.7,0-1.2-0.3-1.5-0.8h0c-0.6-1.3,0.4-2.2,1.5-2.2c1,0,1.6,0.6,1.6,1.5
		C884.2,263.7,883.6,264.3,882.6,264.3 M882.8,260c-0.9,0-1.7,0.4-2.2,1c0-2.1,0.8-3,2-3c0.8,0,1.4,0.4,1.4,1.1h1.6
		c0-1.5-1.2-2.5-3-2.5c-2.2,0-3.6,1.6-3.6,4.6c0,2.9,1.3,4.5,3.5,4.5c1.9,0,3.2-1.2,3.2-2.9C885.8,261.1,884.5,260,882.8,260
		 M876.2,264.3v-7.6H875c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H876.2z"
          />
        </Link>
        <Link to={unlocked.includes(17) ? '/tarea/17' : '#'}>
          <path
            id="E17"
            className={unlocked.includes(17) ? 'step-magenta' : 'step-grey'}
            d="M828.3,201.7c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C816.8,196.5,822,201.7,828.3,201.7"
          />
          <path
            id="N17"
            className="step-white"
            d="M829.8,194.8h1.7l3.8-7.7v-1.3h-6.8v1.4h5v0L829.8,194.8z M825.8,193.4v-7.6h-1.2
		c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H825.8z"
          />
        </Link>
        <Link to={unlocked.includes(18) ? '/tarea/18' : '#'}>
          <path
            id="E18"
            className={unlocked.includes(18) ? 'step-magenta' : 'step-grey'}
            d="M732.5,201.7c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C721,196.5,726.1,201.7,732.5,201.7"
          />
          <path
            id="N18"
            className="step-white"
            d="M736.1,193.5c-1.1,0-1.8-0.5-1.8-1.4c0-0.9,0.7-1.4,1.8-1.4c1.1,0,1.8,0.5,1.8,1.4
		C737.9,193,737.2,193.5,736.1,193.5 M736.1,187c0.9,0,1.5,0.5,1.5,1.2c0,0.7-0.6,1.2-1.5,1.2c-0.9,0-1.5-0.5-1.5-1.2
		C734.6,187.6,735.2,187,736.1,187 M738.2,190.1c0.6-0.4,1-1,1-1.8c0-1.6-1.4-2.5-3.1-2.5c-1.7,0-3.1,0.9-3.1,2.5
		c0,0.8,0.4,1.4,1,1.8c-0.8,0.4-1.3,1.1-1.3,2.1c0,1.8,1.4,2.7,3.4,2.7c2,0,3.4-0.9,3.4-2.7C739.5,191.2,739,190.5,738.2,190.1
		 M730,193.4v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H730z"
          />
        </Link>
        <Link to={unlocked.includes(19) ? '/tarea/19' : '#'}>
          <path
            id="E19"
            className={unlocked.includes(19) ? 'step-magenta' : 'step-grey'}
            d="M681.2,272.5c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C669.7,267.4,674.9,272.5,681.2,272.5"
          />
          <path
            id="N19"
            className="step-white"
            d="M684.7,261c-1,0-1.7-0.6-1.7-1.5c0-0.9,0.7-1.5,1.7-1.5c0.7,0,1.3,0.3,1.6,0.8
		C686.9,260.1,685.8,261,684.7,261 M684.6,256.6c-2,0-3.2,1.2-3.2,2.9c0,1.7,1.3,2.8,3.1,2.8c1,0,1.7-0.4,2.2-1c0,2.1-0.8,3-2.1,3
		c-0.8,0-1.4-0.4-1.4-1.1h-1.6c0,1.5,1.2,2.5,3,2.5c2.2,0,3.6-1.6,3.6-4.6C688.3,258.1,686.9,256.6,684.6,256.6 M678.7,264.3v-7.6
		h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4H678.7z"
          />
        </Link>
        <Link to={unlocked.includes(20) ? '/tarea/20' : '#'}>
          <path
            id="E20"
            className={unlocked.includes(20) ? 'step-magenta' : 'step-grey'}
            d="M706.9,357.3c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C695.4,352.2,700.5,357.3,706.9,357.3"
          />
          <path
            id="N20"
            className="step-white"
            d="M710.5,349.1c-1.2,0-2-1-2-3.2c0-2.1,0.7-3.2,2-3.2c1.2,0,1.9,1.1,1.9,3.2
		C712.5,348.1,711.8,349.1,710.5,349.1 M710.5,350.5c2.2,0,3.5-1.6,3.5-4.5c0-2.9-1.3-4.6-3.5-4.6c-2.2,0-3.5,1.6-3.5,4.6
		C707,348.9,708.3,350.5,710.5,350.5 M702.5,349.1l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6
		c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H702.5z"
          />
        </Link>
        <Link to={unlocked.includes(21) ? '/tarea/21' : '#'}>
          <g id="E21">
            <path
              className={unlocked.includes(21) ? 'step-magenta' : 'step-grey'}
              d="M909.4,403.5L909.4,403.5l-2.5,2.7c0,0-0.7,0.7-1.6,0.3l-3.4-1.5l-1.2,3.5c0,0-0.3,0.9-1.3,1l-3.7,0.1
			l0.3,3.7c0,0,0.1,1-0.8,1.4l-3.4,1.6l1.8,3.3c0,0,0.5,0.9-0.2,1.6l-2.4,2.8l3,2.2c0,0,0.8,0.6,0.5,1.6l-1.1,3.6l3.6,0.8
			c0,0,1,0.2,1.1,1.2l0.4,3.7l3.6-0.7c0,0,1-0.2,1.5,0.7l1.9,3.2l3-2.1c0,0,0.8-0.6,1.6,0l3,2.1l1.9-3.2c0,0,0.5-0.9,1.5-0.7
			l3.6,0.7l0.4-3.7c0,0,0.1-1,1.1-1.2l3.6-0.8l-1.1-3.6c0,0-0.3-1,0.5-1.6l3-2.2l-2.4-2.8c0,0-0.7-0.8-0.2-1.6l1.8-3.3l-3.4-1.6
			c0,0-0.9-0.4-0.8-1.4l0.3-3.7l-3.7-0.1c0,0-1,0-1.3-1l-1.2-3.5l-3.4,1.5c0,0-0.9,0.4-1.6-0.3L909.4,403.5z"
            />
            <path
              className={unlocked.includes(21) ? 'step-red' : 'step-grey'}
              d="M913.5,441.5l-3.8-2.7c-0.3-0.2-0.6,0-0.7,0l-3.8,2.7l-2.4-4c-0.2-0.3-0.5-0.3-0.6-0.3l-4.5,0.9l-0.5-4.6
			c0-0.4-0.4-0.5-0.4-0.5l-4.5-1l1.4-4.4c0.1-0.4-0.2-0.6-0.2-0.7l-3.7-2.8l3-3.5c0.2-0.3,0.1-0.6,0.1-0.7l-2.2-4l4.2-1.9
			c0.4-0.2,0.3-0.6,0.3-0.6l-0.4-4.6l4.6-0.1c0.4,0,0.5-0.3,0.5-0.4l1.5-4.4l4.2,1.8c0.3,0.1,0.6-0.1,0.7-0.2l3.1-3.4l3.1,3.4
			c0.3,0.3,0.6,0.1,0.7,0.1l4.2-1.8l1.5,4.4c0.1,0.3,0.5,0.4,0.6,0.4l4.6,0.1l-0.4,4.6c0,0.4,0.3,0.5,0.3,0.6l4.2,1.9l-2.2,4
			c-0.2,0.3,0,0.6,0.1,0.7l3,3.5l-3.7,2.8c-0.3,0.2-0.2,0.6-0.2,0.6l1.4,4.4l-4.5,1c-0.4,0.1-0.4,0.5-0.4,0.5l-0.5,4.6l-4.5-0.9
			c-0.4-0.1-0.6,0.2-0.6,0.3L913.5,441.5z M909.5,437c0.4,0,0.8,0.1,1.3,0.4l2.3,1.6l1.4-2.4c0.3-0.5,1.1-1.3,2.4-1.1l2.7,0.5
			l0.3-2.8c0.1-0.6,0.5-1.7,1.7-1.9l2.7-0.6l-0.8-2.7c-0.2-0.6-0.2-1.7,0.8-2.5l2.2-1.7l-1.9-2.1c-0.4-0.4-0.9-1.5-0.3-2.6l1.4-2.5
			l-2.5-1.2c-0.5-0.2-1.4-1-1.3-2.2l0.3-2.8l-2.8,0c-0.6,0-1.7-0.3-2.1-1.5l-0.9-2.7l-2.6,1.1c-0.5,0.2-1.7,0.4-2.5-0.5l-1.9-2.1
			l-1.9,2.1c-0.4,0.4-1.4,1-2.5,0.5l-2.6-1.1l-0.9,2.7c-0.2,0.6-0.8,1.5-2.1,1.5l-2.8,0l0.3,2.8c0.1,0.6-0.2,1.7-1.3,2.2l-2.5,1.2
			l1.4,2.5c0.3,0.5,0.6,1.6-0.3,2.6l-1.9,2.1l2.2,1.7c0.5,0.4,1.2,1.3,0.8,2.5l-0.8,2.7l2.7,0.6c0.6,0.1,1.6,0.7,1.7,1.9l0.3,2.8
			l2.7-0.5c0.6-0.1,1.7,0,2.4,1.1l1.4,2.4l2.3-1.6C908.4,437.3,908.9,437,909.5,437z"
            />
          </g>
          <path
            id="N21"
            className="step-white"
            d="M914.6,425.8v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4
		H914.6z M905.4,425.8l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6c0-1.1,0.7-1.6,1.7-1.6
		c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H905.4z"
          />
        </Link>
        <Link to={unlocked.includes(22) ? '/tarea/22' : '#'}>
          <g id="E22">
            <path
              className={unlocked.includes(22) ? 'step-magenta' : 'step-grey'}
              d="M956.8,433.7c6.4,0,11.5-5.2,11.5-11.5s-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.2-11.5,11.5
			S950.4,433.7,956.8,433.7"
            />
            <path
              className="step-grey-dark"
              d="M956.5,439.2c-4.3,0-8.6-1.7-11.8-4.9c-6.5-6.5-6.5-17.3,0.2-23.9l1.2,1.2c-6,6-6.1,15.6-0.2,21.5
			c4.7,4.7,11.9,5.7,17.8,2.5l0.8,1.5C962,438.5,959.2,439.2,956.5,439.2z"
            />
            <polygon
              className="step-grey-dark"
              points="946.9,414.8 945.2,414.8 945.2,411.5 941.8,411.5 941.8,409.8 946.9,409.8 		"
            />
            <path
              className="step-grey-dark"
              d="M968.6,434l-1.2-1.2c6-6,6.1-15.6,0.2-21.5c-4.7-4.7-11.9-5.7-17.8-2.5l-0.8-1.5c6.6-3.5,14.6-2.4,19.8,2.8
			C975.4,416.7,975.3,427.4,968.6,434z"
            />
            <polygon
              className="step-grey-dark"
              points="966.7,434.6 966.7,429.6 968.4,429.6 968.4,432.9 971.7,432.9 971.7,434.6 		"
            />
          </g>
          <path
            id="N22"
            className="step-white"
            d="M959.7,425.2l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6
		c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H959.7z M952.4,425.2l2.2-1.9
		c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3
		c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H952.4z"
          />
        </Link>
        <Link to={unlocked.includes(23) ? '/tarea/23' : '#'}>
          <path
            id="E23"
            className={unlocked.includes(23) ? 'step-red' : 'step-grey'}
            d="M1178.5,315.8c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1167,310.6,1172.2,315.8,1178.5,315.8"
          />
          <path
            id="N23"
            className="step-white"
            d="M1185.5,306.3c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6
		c-1.9,0-3.2,1.1-3.2,2.9h1.6c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9
		c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8
		C1184,308.9,1185.5,307.9,1185.5,306.3 M1174.1,307.5l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3
		h1.6c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H1174.1z"
          />
        </Link>
        <Link to={unlocked.includes(24) ? '/tarea/24' : '#'}>
          <path
            id="E24"
            className={unlocked.includes(24) ? 'step-red' : 'step-grey'}
            d="M1200.7,245.7c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1189.2,240.6,1194.4,245.7,1200.7,245.7"
          />
          <path
            id="N24"
            className="step-white"
            d="M1202.3,235.4l2.9-4h0v4L1202.3,235.4L1202.3,235.4z M1208,235.4h-1.2v-5.5h-2.1l-4,5.6v1.3h4.5v2
		h1.6v-2h1.2V235.4z M1196.3,237.5l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6
		c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H1196.3z"
          />
        </Link>
        <Link to={unlocked.includes(25) ? '/tarea/25' : '#'}>
          <path
            id="E25"
            className={unlocked.includes(25) ? 'step-red' : 'step-grey'}
            d="M1174,181.6c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1162.5,176.4,1167.6,181.6,1174,181.6"
          />
          <path
            id="N25"
            className="step-white"
            d="M1177.8,168.9c-0.6,0-1.1,0.1-1.6,0.3l0.2-2.1h4.1v-1.4h-5.4l-0.4,4.5l1.4,0.5
		c0.4-0.3,0.9-0.5,1.6-0.5c1,0,1.8,0.5,1.8,1.6c0,1-0.8,1.5-1.8,1.5c-1,0-1.8-0.4-1.8-1.4l-1.6,0c0,1.7,1.5,2.8,3.4,2.8
		c1.9,0,3.4-1.1,3.4-2.9C1181,169.9,1179.6,168.9,1177.8,168.9 M1169.6,173.3l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7
		c-1.9,0-3.3,1.1-3.3,3h1.6c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H1169.6z"
          />
        </Link>
        <Link to={unlocked.includes(26) ? '/tarea/26' : '#'}>
          <path
            id="E26"
            className={unlocked.includes(26) ? 'step-red' : 'step-grey'}
            d="M1099.8,150.3c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1088.3,145.2,1093.4,150.3,1099.8,150.3"
          />
          <path
            id="N26"
            className="step-white"
            d="M1103.6,142.1c-0.7,0-1.2-0.3-1.5-0.8h0c-0.6-1.3,0.4-2.2,1.5-2.2c1,0,1.6,0.6,1.6,1.5
		C1105.2,141.5,1104.6,142.1,1103.6,142.1 M1103.8,137.8c-0.9,0-1.7,0.4-2.2,1c0-2.1,0.8-3,2-3c0.8,0,1.4,0.4,1.4,1.1h1.6
		c0-1.5-1.2-2.5-3-2.5c-2.2,0-3.6,1.6-3.6,4.6c0,2.9,1.3,4.5,3.5,4.5c1.9,0,3.2-1.2,3.2-2.9C1106.8,138.9,1105.5,137.8,1103.8,137.8
		 M1095.4,142.1l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6c0-1.1,0.7-1.6,1.7-1.6
		c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H1095.4z"
          />
        </Link>
        <Link to={unlocked.includes(27) ? '/tarea/27' : '#'}>
          <path
            id="E27"
            className={unlocked.includes(27) ? 'step-red' : 'step-grey'}
            d="M1026.5,181.6c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1015,176.4,1020.1,181.6,1026.5,181.6"
          />
          <path
            id="N27"
            className="step-white"
            d="M1028,174.7h1.7l3.8-7.7v-1.3h-6.8v1.4h5v0L1028,174.7z M1022.1,173.3l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1
		c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1
		h6.6v-1.4H1022.1z"
          />
        </Link>
        <Link to={unlocked.includes(28) ? '/tarea/28' : '#'}>
          <path
            id="E28"
            className={unlocked.includes(28) ? 'step-red' : 'step-grey'}
            d="M999.3,245.5c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C987.8,240.4,992.9,245.5,999.3,245.5"
          />
          <path
            id="N28"
            className="step-white"
            d="M1003,237.4c-1.1,0-1.8-0.5-1.8-1.4c0-0.9,0.7-1.4,1.8-1.4c1.1,0,1.8,0.5,1.8,1.4
		C1004.7,236.9,1004,237.4,1003,237.4 M1003,230.9c0.9,0,1.5,0.5,1.5,1.2c0,0.7-0.6,1.2-1.5,1.2c-0.9,0-1.5-0.5-1.5-1.2
		C1001.4,231.4,1002,230.9,1003,230.9 M1005,233.9c0.6-0.4,1-1,1-1.8c0-1.6-1.4-2.5-3.1-2.5c-1.7,0-3.1,0.9-3.1,2.5
		c0,0.8,0.4,1.4,1,1.8c-0.8,0.4-1.3,1.1-1.3,2.1c0,1.8,1.4,2.7,3.4,2.7c2,0,3.4-0.9,3.4-2.7C1006.3,235,1005.8,234.3,1005,233.9
		 M994.9,237.3l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6c0-1.1,0.7-1.6,1.7-1.6
		c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H994.9z"
          />
        </Link>
        <Link to={unlocked.includes(29) ? '/tarea/29' : '#'}>
          <path
            id="E29"
            className={unlocked.includes(29) ? 'step-red' : 'step-grey'}
            d="M1020.6,315.8c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1009.1,310.6,1014.2,315.8,1020.6,315.8"
          />
          <path
            id="N29"
            className="step-white"
            d="M1024,304.3c-1,0-1.7-0.6-1.7-1.5c0-0.9,0.7-1.5,1.7-1.5c0.7,0,1.3,0.3,1.6,0.8
		C1026.2,303.4,1025.2,304.3,1024,304.3 M1024,299.8c-2,0-3.2,1.2-3.2,2.9c0,1.7,1.3,2.8,3.1,2.8c0.9,0,1.7-0.4,2.2-1
		c0,2.1-0.8,3-2.1,3c-0.8,0-1.4-0.4-1.4-1.1h-1.6c0,1.5,1.2,2.5,3,2.5c2.2,0,3.6-1.6,3.6-4.6C1027.6,301.3,1026.3,299.8,1024,299.8
		 M1016.2,307.5l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6c0-1.1,0.7-1.6,1.7-1.6
		c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H1016.2z"
          />
        </Link>
        <Link to={unlocked.includes(30) ? '/tarea/30' : '#'}>
          <path
            id="E30"
            className={unlocked.includes(30) ? 'step-orange' : 'step-grey'}
            d="M1163.7,450.7c6.4,0,11.5-5.1,11.5-11.5s-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		S1157.3,450.7,1163.7,450.7"
          />
          <path
            id="N30"
            className="step-white"
            d="M1167.3,442.4c-1.2,0-2-1-2-3.2c0-2.1,0.7-3.2,2-3.2c1.2,0,1.9,1.1,1.9,3.2
		C1169.3,441.4,1168.6,442.4,1167.3,442.4 M1167.3,443.8c2.2,0,3.5-1.6,3.5-4.5c0-2.9-1.3-4.6-3.5-4.6c-2.2,0-3.5,1.6-3.5,4.6
		C1163.8,442.2,1165.1,443.8,1167.3,443.8 M1163.3,441.2c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6
		c-1.9,0-3.2,1.1-3.2,2.9h1.6c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9
		c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8
		C1161.8,443.8,1163.3,442.8,1163.3,441.2"
          />
        </Link>
        <Link to={unlocked.includes(31) ? '/tarea/31' : '#'}>
          <path
            id="E31"
            className={unlocked.includes(31) ? 'step-orange' : 'step-grey'}
            d="M1191.8,494.2c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1180.3,489,1185.5,494.2,1191.8,494.2"
          />
          <path
            id="N31"
            className="step-white"
            d="M1196.6,485.9v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9v-1.4
		H1196.6z M1191.5,484.7c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9h1.6
		c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2
		c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8C1190,487.3,1191.5,486.3,1191.5,484.7"
          />
        </Link>
        <Link to={unlocked.includes(32) ? '/tarea/32' : '#'}>
          <path
            id="E32"
            className={unlocked.includes(32) ? 'step-orange' : 'step-grey'}
            d="M1201.2,542.9c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1189.7,537.7,1194.8,542.9,1201.2,542.9"
          />
          <path
            id="N32"
            className="step-white"
            d="M1204.1,534.6l2.2-1.9c1.1-1,1.7-1.9,1.7-3.1c0-1.7-1.3-2.7-3.1-2.7c-1.9,0-3.3,1.1-3.3,3h1.6
		c0-1.1,0.7-1.6,1.7-1.6c0.9,0,1.6,0.4,1.6,1.3c0,0.8-0.5,1.5-1.3,2.2l-3.5,3.1v1.1h6.6v-1.4H1204.1z M1200.8,533.5
		c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9h1.6c0-1.1,0.7-1.5,1.6-1.5
		c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4
		h-1.6c0,1.8,1.5,2.8,3.4,2.8C1199.3,536,1200.8,535.1,1200.8,533.5"
          />
        </Link>
        <Link to={unlocked.includes(33) ? '/tarea/33' : '#'}>
          <path
            id="E33"
            className={unlocked.includes(33) ? 'step-orange' : 'step-grey'}
            d="M1185.8,592.3c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1174.3,587.1,1179.5,592.3,1185.8,592.3"
          />
          <path
            id="N33"
            className="step-white"
            d="M1192.8,582.8c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6
		c-1.9,0-3.2,1.1-3.2,2.9h1.6c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9
		c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8
		C1191.3,585.4,1192.8,584.4,1192.8,582.8 M1185.5,582.8c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6
		c-1.9,0-3.2,1.1-3.2,2.9h1.6c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9
		c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8
		C1184,585.4,1185.5,584.4,1185.5,582.8"
          />
        </Link>
        <Link to={unlocked.includes(34) ? '/tarea/34' : '#'}>
          <path
            id="E34"
            className={unlocked.includes(34) ? 'step-orange' : 'step-grey'}
            d="M1149.7,627.2c6.4,0,11.5-5.1,11.5-11.5s-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		S1143.3,627.2,1149.7,627.2"
          />
          <path
            id="N34"
            className="step-white"
            d="M1151.3,616.9l2.9-4h0v4L1151.3,616.9L1151.3,616.9z M1156.9,616.9h-1.2v-5.5h-2.1l-4,5.6v1.3h4.5v2
		h1.6v-2h1.2V616.9z M1149.3,617.8c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9h1.6
		c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2
		c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8C1147.8,620.4,1149.3,619.4,1149.3,617.8"
          />
        </Link>
        <Link to={unlocked.includes(35) ? '/tarea/35' : '#'}>
          <path
            id="E35"
            className={unlocked.includes(35) ? 'step-orange' : 'step-grey'}
            d="M1100.9,640c6.4,0,11.5-5.1,11.5-11.5s-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		S1094.6,640,1100.9,640"
          />
          <path
            id="N35"
            className="step-white"
            d="M1104.7,627.3c-0.6,0-1.1,0.1-1.6,0.3l0.2-2.1h4.1v-1.4h-5.4l-0.4,4.5l1.4,0.5
		c0.4-0.3,0.9-0.5,1.6-0.5c1,0,1.8,0.5,1.8,1.6c0,1-0.8,1.5-1.8,1.5c-1,0-1.8-0.4-1.8-1.4l-1.6,0c0,1.7,1.5,2.8,3.4,2.8
		c1.9,0,3.4-1.1,3.4-2.9C1108,628.3,1106.5,627.3,1104.7,627.3 M1100.6,630.6c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9
		c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9h1.6c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3
		h0.9c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8
		C1099.1,633.2,1100.6,632.2,1100.6,630.6"
          />
        </Link>
        <Link to={unlocked.includes(36) ? '/tarea/36' : '#'}>
          <g id="E36">
            <path
              className={unlocked.includes(36) ? 'step-orange' : 'step-grey'}
              d="M1051.5,626.1c6.4,0,11.5-5.2,11.5-11.5s-5.1-11.5-11.5-11.5s-11.5,5.2-11.5,11.5S1045.1,626.1,1051.5,626.1"
            />
            <path
              className={unlocked.includes(36) ? 'step-grey-dark' : 'step-grey'}
              d="M1068.2,614.9l-1.7,0c0.1-8.5-6.5-15.4-14.8-15.6c-6.6-0.1-12.5,4.1-14.6,10.5l-1.6-0.5
			c2.3-7.1,8.8-11.8,16.2-11.7C1061,597.7,1068.4,605.5,1068.2,614.9z"
            />
            <polygon
              className={unlocked.includes(36) ? 'step-grey-dark' : 'step-grey'}
              points="1067.2,616.7 1063.7,613.1 1065,611.9 1067.3,614.3 1069.7,612 1070.9,613.2 		"
            />
            <path
              className={unlocked.includes(36) ? 'step-grey-dark' : 'step-grey'}
              d="M1051.5,631.7c-0.1,0-0.2,0-0.3,0c-9.2-0.2-16.6-7.9-16.5-17.3l1.7,0c-0.1,8.5,6.5,15.4,14.8,15.6
			c0.1,0,0.2,0,0.3,0c6.5,0,12.3-4.2,14.3-10.5l1.6,0.5C1065.2,627,1058.8,631.7,1051.5,631.7z"
            />
            <polygon
              className={unlocked.includes(36) ? 'step-grey-dark' : 'step-grey'}
              points="1038,617.4 1035.7,615 1033.3,617.3 1032.1,616 1035.7,612.5 1039.2,616.2 		"
            />
          </g>
          <path
            id="N36"
            className="step-white"
            d="M1055.3,617.7c-0.7,0-1.2-0.3-1.5-0.8h0c-0.6-1.3,0.4-2.2,1.5-2.2c1,0,1.6,0.6,1.6,1.5
		C1057,617.1,1056.3,617.7,1055.3,617.7 M1055.5,613.4c-0.9,0-1.7,0.4-2.2,1c0-2.1,0.8-3,2-3c0.8,0,1.4,0.4,1.4,1.1h1.6
		c0-1.5-1.2-2.5-3-2.5c-2.2,0-3.6,1.6-3.6,4.6c0,2.9,1.3,4.5,3.5,4.5c1.9,0,3.2-1.2,3.2-2.9C1058.6,614.4,1057.3,613.3,1055.5,613.4
		 M1051.2,616.5c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9h1.6
		c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2
		c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8C1049.7,619.1,1051.2,618.1,1051.2,616.5"
          />
        </Link>
        <Link to={unlocked.includes(37) ? '/tarea/37' : '#'}>
          <path
            id="E37"
            className={unlocked.includes(37) ? 'step-orange' : 'step-grey'}
            d="M1015.6,592.3c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1004.1,587.1,1009.2,592.3,1015.6,592.3"
          />
          <path
            id="N37"
            className="step-white"
            d="M1017.1,585.4h1.7l3.8-7.7v-1.3h-6.8v1.3h5v0L1017.1,585.4z M1015.3,582.8c0-1-0.5-1.7-1.3-2.1
		c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9h1.6c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2
		c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2c-1,0-1.8-0.4-1.8-1.4h-1.6
		c0,1.8,1.5,2.8,3.4,2.8C1013.8,585.4,1015.3,584.4,1015.3,582.8"
          />
        </Link>
        <Link to={unlocked.includes(38) ? '/tarea/38' : '#'}>
          <path
            id="E38"
            className={unlocked.includes(38) ? 'step-orange' : 'step-grey'}
            d="M1000.7,542.9c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C989.2,537.7,994.3,542.9,1000.7,542.9"
          />
          <path
            id="N38"
            className="step-white"
            d="M1004.3,534.7c-1.1,0-1.8-0.5-1.8-1.4c0-0.9,0.7-1.4,1.8-1.4c1.1,0,1.8,0.5,1.8,1.4
		C1006.1,534.2,1005.4,534.7,1004.3,534.7 M1004.3,528.2c0.9,0,1.5,0.5,1.5,1.2c0,0.7-0.6,1.2-1.5,1.2c-0.9,0-1.5-0.5-1.5-1.2
		C1002.8,528.8,1003.4,528.2,1004.3,528.2 M1006.4,531.3c0.6-0.4,1-1,1-1.8c0-1.6-1.4-2.5-3.1-2.5c-1.7,0-3.1,0.9-3.1,2.5
		c0,0.8,0.4,1.4,1,1.8c-0.8,0.4-1.3,1.1-1.3,2.1c0,1.8,1.4,2.7,3.4,2.7c2,0,3.4-0.9,3.4-2.7C1007.7,532.4,1007.2,531.7,1006.4,531.3
		 M1000.4,533.5c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9h1.6
		c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2H996v1.3h0.9c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2
		c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8C998.9,536,1000.4,535.1,1000.4,533.5"
          />
        </Link>
        <Link to={unlocked.includes(39) ? '/tarea/39' : '#'}>
          <g id="E39">
            <path
              className={unlocked.includes(39) ? 'step-orange' : 'step-grey'}
              d="M1010,494.2c6.4,0,11.5-5.2,11.5-11.5s-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.2-11.5,11.5
			S1003.7,494.2,1010,494.2"
            />
            <path
              className={unlocked.includes(39) ? 'step-grey-dark' : 'step-grey'}
              d="M1026.8,482.9l-1.7,0c0.1-8.5-6.5-15.4-14.8-15.6c-6.6-0.1-12.5,4.1-14.6,10.5l-1.6-0.5
			c2.3-7.1,8.9-11.9,16.2-11.7C1019.6,465.8,1026.9,473.5,1026.8,482.9z"
            />
            <polygon
              className={unlocked.includes(39) ? 'step-grey-dark' : 'step-grey'}
              points="1025.8,484.8 1022.3,481.1 1023.5,479.9 1025.8,482.3 1028.2,480 1029.4,481.2 		"
            />
            <path
              className={unlocked.includes(39) ? 'step-grey-dark' : 'step-grey'}
              d="M1010,499.7c-0.1,0-0.2,0-0.3,0c-9.2-0.2-16.6-7.9-16.5-17.3l1.7,0c-0.1,8.5,6.5,15.4,14.8,15.6
			c6.6,0.2,12.5-4.1,14.6-10.5l1.6,0.5C1023.7,495.1,1017.3,499.7,1010,499.7z"
            />
            <polygon
              className={unlocked.includes(39) ? 'step-grey-dark' : 'step-grey'}
              points="996.6,485.4 994.2,483 991.8,485.3 990.6,484.1 994.3,480.6 997.8,484.2 		"
            />
          </g>
          <path
            id="N39"
            className="step-white"
            d="M1013.5,482.4c-1,0-1.7-0.6-1.7-1.5c0-0.9,0.7-1.5,1.7-1.5c0.7,0,1.3,0.3,1.6,0.8
		C1015.7,481.6,1014.6,482.4,1013.5,482.4 M1013.4,478c-2,0-3.2,1.2-3.2,2.9c0,1.7,1.3,2.8,3.1,2.8c0.9,0,1.7-0.4,2.2-1
		c0,2.1-0.8,3-2.1,3c-0.8,0-1.4-0.4-1.4-1.1h-1.6c0,1.5,1.2,2.5,3,2.5c2.2,0,3.6-1.6,3.6-4.6C1017.1,479.5,1015.7,478,1013.4,478
		 M1009.7,484.5c0-1-0.5-1.7-1.3-2.1c0.6-0.4,1.1-1,1.1-1.9c0-1.6-1.4-2.6-3.2-2.6c-1.9,0-3.2,1.1-3.2,2.9h1.6
		c0-1.1,0.7-1.5,1.6-1.5c0.9,0,1.6,0.4,1.6,1.2c0,0.8-0.8,1.2-1.8,1.2h-0.8v1.3h0.9c1.1,0,1.9,0.4,1.9,1.3c0,0.8-0.7,1.2-1.8,1.2
		c-1,0-1.8-0.4-1.8-1.4h-1.6c0,1.8,1.5,2.8,3.4,2.8C1008.2,487.1,1009.7,486.1,1009.7,484.5"
          />
        </Link>
        <Link to={unlocked.includes(40) ? '/tarea/40' : '#'}>
          <path
            id="E40"
            className={unlocked.includes(40) ? 'step-orange' : 'step-grey'}
            d="M1039.2,450.3c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5s-11.5,5.1-11.5,11.5
		C1027.7,445.1,1032.8,450.3,1039.2,450.3"
          />
          <path
            id="N40"
            className="step-white"
            d="M1042.8,442.1c-1.2,0-2-1-2-3.2c0-2.1,0.7-3.2,2-3.2c1.2,0,1.9,1.1,1.9,3.2
		C1044.8,441,1044.1,442.1,1042.8,442.1 M1042.8,443.5c2.2,0,3.5-1.6,3.5-4.5c0-2.9-1.3-4.6-3.5-4.6c-2.2,0-3.5,1.6-3.5,4.6
		C1039.3,441.9,1040.6,443.5,1042.8,443.5 M1033.5,440l2.9-4h0v4L1033.5,440L1033.5,440z M1039.1,440h-1.2v-5.5h-2.1l-4,5.6v1.3h4.5
		v2h1.6v-2h1.2V440z"
          />
        </Link>
        <Link to={unlocked.includes(41) ? '/tarea/41' : '#'}>
          <path
            id="E41"
            className={unlocked.includes(41) ? 'step-black' : 'step-grey'}
            d="M1250.5,387.2c6.4,0,11.5-5.1,11.5-11.5c0-6.4-5.1-11.5-11.5-11.5c-6.4,0-11.5,5.1-11.5,11.5
		C1239,382.1,1244.2,387.2,1250.5,387.2"
          />
          <path
            id="N41"
            className="step-white"
            d="M1255.4,379v-7.6h-1.2c-0.6,0.8-1.4,1.1-2.7,1.1v1.3c1.1,0,1.7-0.1,2.3-0.3v5.5h-2.3v1.4h5.9V379
		H1255.4z M1244.8,376.9l2.9-4h0v4L1244.8,376.9L1244.8,376.9z M1250.5,376.9h-1.2v-5.5h-2.1l-4,5.6v1.3h4.5v2h1.6v-2h1.2V376.9z"
          />
        </Link>
      </g>
      <g id="texto">
        <path
          id="T2"
          className="step-white"
          d="M326.5,304c0,0-0.1,0-0.1,0h-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6v-1.5h-1.6v6.8h1.7v-4.1c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.9-0.3,1.4-0.3V304z
		 M316.2,309.5c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.2,0,0.5,0,0.7,0.1c0.2,0,0.5,0.1,0.7,0.2
		v0.8c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.5,0.2-0.8,0.2C316.7,309.8,316.4,309.7,316.2,309.5 M317.9,310.7
		c0.4-0.2,0.8-0.5,1.1-0.9l0,0.4c0,0.2,0.2,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.8,0.2c0.2,0,0.5,0,0.8-0.1v-1.5c-0.1,0-0.3-0.1-0.3-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.4v-2.1c0-0.9-0.3-1.6-0.8-2.1c-0.5-0.5-1.3-0.7-2.3-0.7c-1,0-2,0.3-2.8,0.9l0.5,1.1
		c0.4-0.3,0.7-0.4,1.1-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1v0.4c-0.2-0.1-0.5-0.2-0.8-0.2
		c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-0.9,0.4c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.3-0.2,0.6-0.2,0.9
		c0,0.3,0.1,0.6,0.2,0.9s0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2C317,311.1,317.5,311,317.9,310.7
		 M306.8,308.9c0.2,0.4,0.4,0.8,0.7,1.1s0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.5,0.3c0.8,0,1.4-0.2,1.9-0.5c0.5-0.3,0.9-0.8,1.2-1.3
		l-1.7-0.5c-0.1,0.2-0.3,0.4-0.6,0.6c-0.3,0.2-0.6,0.2-0.9,0.2c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4
		c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.3,0.2-0.5,0.4-0.6c0.2-0.2,0.3-0.3,0.6-0.4
		c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.6l1.7-0.5c-0.3-0.5-0.7-0.9-1.2-1.3
		c-0.5-0.3-1.2-0.5-1.9-0.5c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.8c-0.3,0.3-0.5,0.7-0.7,1.1c-0.2,0.4-0.2,0.9-0.2,1.4
		C306.6,308,306.7,308.4,306.8,308.9 M305.1,301.4h-1.7v1.7h1.7V301.4z M305.1,304.1h-1.7v6.8h1.7V304.1z M300.4,310.9v-5.1h1.7
		v-1.3h-1.7V304c0-0.8,0.3-1.2,0.9-1.2c0.1,0,0.3,0,0.5,0.1c0.2,0,0.3,0.1,0.5,0.2l0.4-1.3c-0.6-0.3-1.1-0.4-1.7-0.4
		c-0.7,0-1.3,0.2-1.7,0.7c-0.4,0.5-0.6,1.2-0.6,2v0.4h-0.9v1.3h0.9v5.1H300.4z M296.3,301.4h-1.7v1.7h1.7V301.4z M296.3,304.1h-1.7
		v6.8h1.7V304.1z M292.7,306.7c0-0.3,0-0.7-0.1-1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.4-0.5-0.6-0.6c-0.3-0.2-0.6-0.2-1-0.2
		c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.2-0.8,0.6-1.1,1v-1.3h-1.6v6.8h1.7v-4c0.1-0.2,0.2-0.4,0.3-0.6s0.3-0.3,0.4-0.4
		c0.2-0.1,0.3-0.2,0.5-0.3s0.4-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.3,0.6,0.3,1.2v3.8h1.7V306.7z M280.4,309.4
		c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0.1-0.8c0.1-0.3,0.2-0.5,0.4-0.7
		c0.2-0.2,0.3-0.4,0.6-0.5c0.2-0.1,0.5-0.2,0.7-0.2c0.4,0,0.7,0.1,1,0.4c0.3,0.2,0.6,0.6,0.7,1v1.6c-0.1,0.2-0.1,0.3-0.3,0.4
		c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0.1-0.4,0.1-0.5,0.1C280.8,309.6,280.6,309.5,280.4,309.4 M281.8,310.6
		c0.4-0.2,0.7-0.5,0.9-0.9v0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0.1-0.5,0.1-0.8,0.1
		c-0.4,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.7-0.4-1-0.8l-0.9,0.9c0.4,0.5,0.8,0.8,1.3,1c0.5,0.2,1.1,0.4,1.9,0.4c0.5,0,1-0.1,1.5-0.2
		c0.5-0.1,0.9-0.4,1.2-0.6s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.4v-6.5H283v1.2c-0.3-0.4-0.6-0.7-1-1c-0.4-0.2-0.9-0.4-1.4-0.4
		c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.4-1,0.8c-0.3,0.3-0.5,0.7-0.6,1.1c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.1,0.9,0.2,1.3
		c0.1,0.4,0.4,0.8,0.6,1.1c0.3,0.3,0.6,0.6,1,0.7c0.4,0.2,0.8,0.3,1.2,0.3C281,311,281.4,310.9,281.8,310.6 M276,301.4h-1.7v1.7h1.7
		V301.4z M276,304.1h-1.7v6.8h1.7V304.1z M272,310.5c0.5-0.4,0.7-0.9,0.7-1.6c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.5
		c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.7-0.2-1.2-0.4c-0.3-0.1-0.5-0.1-0.6-0.2c-0.2-0.1-0.3-0.1-0.4-0.2s-0.2-0.1-0.2-0.2
		c0-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.5,0.3-0.6c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6,0.1,1,0.2c0.3,0.1,0.7,0.3,1,0.6l0.7-1.1
		c-0.4-0.3-0.8-0.6-1.2-0.7c-0.4-0.1-0.9-0.2-1.4-0.2c-0.4,0-0.7,0.1-1.1,0.2s-0.6,0.3-0.9,0.4c-0.2,0.2-0.4,0.4-0.6,0.7
		c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.3,0.8,0.4c0.3,0.1,0.7,0.2,1.1,0.4
		c0.5,0.1,0.9,0.3,1.1,0.4c0.2,0.1,0.4,0.3,0.4,0.5c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.8-0.1-1.2-0.2
		c-0.4-0.2-0.8-0.4-1.2-0.7l-0.6,1.1c0.4,0.3,0.9,0.6,1.4,0.8c0.5,0.2,1.1,0.3,1.7,0.3C270.8,311.1,271.5,310.9,272,310.5
		 M260.7,306.9c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1
		c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.6,1.2H260.7z M264.4,310.6c0.6-0.3,1-0.8,1.2-1.3l-1.5-0.4c-0.1,0.3-0.3,0.5-0.6,0.7
		c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.2-0.4-0.2-0.7h5.3
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.5-0.1-0.9-0.2-1.3c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.8
		c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.8c-0.3,0.3-0.6,0.7-0.7,1.1c-0.2,0.4-0.2,0.9-0.2,1.4
		c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.5,0.3
		C263.2,311.1,263.9,310.9,264.4,310.6 M252.5,303.3h2.2c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.3,0.5
		s0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.5,0.1h-2.2V303.3z
		 M252.5,310.9v-3.1h1.8l1.9,3.1h2l-2.1-3.5c0.5-0.2,0.9-0.6,1.2-1.1c0.3-0.5,0.4-1,0.4-1.6c0-0.4-0.1-0.7-0.2-1.1
		c-0.1-0.4-0.4-0.7-0.6-1c-0.3-0.3-0.6-0.5-0.9-0.7c-0.4-0.2-0.8-0.3-1.2-0.3h-4.1v9.2H252.5z"
        />
        <path
          id="T3"
          className="step-white"
          d="M541.8,288.4c0,0-0.1,0-0.1,0h-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6v-1.5h-1.6v6.8h1.7v-4.1c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.9-0.3,1.4-0.3
		V288.4z M531.5,293.9c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.2,0,0.5,0,0.7,0.1
		c0.2,0,0.5,0.1,0.7,0.2v0.8c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.5,0.2-0.8,0.2
		C532,294.1,531.7,294.1,531.5,293.9 M533.2,295.1c0.4-0.2,0.8-0.5,1.1-0.9l0,0.4c0,0.2,0.2,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.8,0.2
		c0.2,0,0.5,0,0.8-0.1v-1.5c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.1c0-0.9-0.3-1.6-0.8-2.1c-0.5-0.5-1.3-0.7-2.3-0.7
		c-1,0-2,0.3-2.8,0.9l0.5,1.1c0.4-0.3,0.7-0.4,1.1-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1v0.4
		c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-0.9,0.4c-0.3,0.2-0.5,0.4-0.6,0.7
		c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9s0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2
		C532.3,295.4,532.8,295.3,533.2,295.1 M522.8,295.3h5.7v-1.2h-3.8l3.8-4.5v-1.1h-5.5v1.2h3.6l-3.8,4.5V295.3z M521.3,285.8h-1.7
		v1.7h1.7V285.8z M521.3,288.5h-1.7v6.8h1.7V288.5z M518,293.6c-0.1,0-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.1-0.5,0.1
		c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.3-0.2-0.5v-3.3h1.4v-1.3h-1.4v-2.2h-1.7v2.2h-0.9v1.3h0.9v3.9c0,0.3,0,0.6,0.1,0.8
		c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.2,0.9-0.3
		L518,293.6z M508,293.9c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.2,0,0.5,0,0.7,0.1
		c0.2,0,0.5,0.1,0.7,0.2v0.8c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.5,0.2-0.8,0.2
		C508.6,294.1,508.3,294.1,508,293.9 M509.8,295.1c0.4-0.2,0.8-0.5,1.1-0.9l0,0.4c0,0.2,0.2,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.8,0.2
		c0.2,0,0.5,0,0.8-0.1v-1.5c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.1c0-0.9-0.3-1.6-0.8-2.1c-0.5-0.5-1.3-0.7-2.3-0.7
		c-1,0-2,0.3-2.8,0.9l0.5,1.1c0.4-0.3,0.7-0.4,1.1-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1v0.4
		c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-0.9,0.4c-0.3,0.2-0.5,0.4-0.6,0.7
		c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9s0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2
		C508.9,295.4,509.3,295.3,509.8,295.1 M500.4,293.6c-0.3-0.3-0.6-0.6-0.7-1V291c0.1-0.2,0.1-0.3,0.3-0.5c0.1-0.1,0.2-0.3,0.4-0.4
		s0.3-0.2,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6s0.1,0.5,0.1,0.8
		c0,0.3,0,0.5-0.1,0.8c-0.1,0.3-0.2,0.5-0.4,0.7s-0.3,0.3-0.6,0.5c-0.2,0.1-0.5,0.2-0.7,0.2C501.1,293.9,500.7,293.8,500.4,293.6
		 M503.2,295.2c0.4-0.2,0.7-0.4,0.9-0.8c0.3-0.3,0.5-0.7,0.6-1.1c0.1-0.4,0.2-0.9,0.2-1.4c0-0.5-0.1-0.9-0.2-1.4
		c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.3-0.3c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.7,0.5-1,0.9v-1.2H498
		v9.6h1.7v-4c0.2,0.4,0.5,0.7,0.9,1c0.4,0.2,0.9,0.4,1.4,0.4C502.5,295.4,502.9,295.3,503.2,295.2 M496.1,291c0-0.3,0-0.7-0.1-1
		c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.4-0.5-0.6-0.6c-0.3-0.2-0.6-0.2-1-0.2c-0.5,0-1,0.1-1.5,0.4c-0.4,0.2-0.8,0.6-1.1,1.1
		c-0.1-0.4-0.3-0.8-0.6-1c-0.3-0.3-0.8-0.4-1.3-0.4c-0.6,0-1.1,0.1-1.5,0.4c-0.4,0.2-0.8,0.6-1,1v-1.3h-1.6v6.8h1.7v-4
		c0.1-0.4,0.3-0.8,0.7-1c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.3,0.6,0.3,1.2v3.8h1.7v-4c0.1-0.4,0.3-0.8,0.7-1
		c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.3,0.6,0.3,1.2v3.8h1.7V291z M479.4,293.7v-2.4h3.9v-1.5h-3.9v-2.2h4.5
		v-1.6h-6.3v9.2h6.4v-1.6H479.4z"
        />
        <path
          id="T4"
          className="step-white"
          d="M802.1,278c0,0-0.1,0-0.1,0h-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6v-1.5H798v6.8h1.7v-4.1c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.9-0.3,1.4-0.3V278z
		 M796.2,275.4h-1.7v1.7h1.7V275.4z M796.2,278.1h-1.7v6.8h1.7V278.1z M792.6,280.6c0-0.3,0-0.7-0.1-1c-0.1-0.3-0.2-0.6-0.3-0.9
		c-0.1-0.3-0.4-0.5-0.6-0.6c-0.3-0.2-0.6-0.2-1-0.2c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.2-0.8,0.6-1.1,1v-1.3h-1.6v6.8h1.7v-4
		c0.1-0.2,0.2-0.4,0.3-0.6s0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.4
		c0.2,0.3,0.3,0.6,0.3,1.2v3.8h1.7V280.6z M784.3,275.4h-1.7v1.7h1.7V275.4z M784.3,278.1h-1.7v6.8h1.7V278.1z M779.6,284.9v-5.1
		h1.7v-1.3h-1.7V278c0-0.8,0.3-1.2,0.9-1.2c0.1,0,0.3,0,0.5,0.1c0.2,0,0.3,0.1,0.5,0.2l0.4-1.3c-0.6-0.3-1.1-0.4-1.7-0.4
		c-0.7,0-1.3,0.2-1.7,0.7c-0.4,0.5-0.6,1.2-0.6,2v0.4H777v1.3h0.9v5.1H779.6z M770.8,280.9c0-0.3,0.1-0.5,0.2-0.7
		c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.6,1.2H770.8z
		 M774.5,284.5c0.6-0.3,1-0.8,1.2-1.3l-1.5-0.4c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.7-0.1
		s-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.2-0.4-0.2-0.7h5.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.5-0.1-0.9-0.2-1.3
		c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.8c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.8
		c-0.3,0.3-0.6,0.7-0.7,1.1c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.1,0.9,0.3,1.3s0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.1,0.8
		c0.4,0.2,0.9,0.3,1.5,0.3C773.3,285,774,284.8,774.5,284.5 M765.9,281.5c-0.1,0.4-0.3,0.7-0.5,1c-0.2,0.3-0.5,0.5-0.9,0.6
		c-0.3,0.2-0.7,0.2-1.2,0.2h-1.6v-6.1h1.6c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.4,0.9,0.6c0.2,0.3,0.4,0.6,0.5,1
		c0.1,0.4,0.2,0.8,0.2,1.2C766,280.7,766,281.1,765.9,281.5 M763.3,284.9c0.7,0,1.3-0.1,1.9-0.3c0.6-0.2,1-0.5,1.4-0.9
		c0.4-0.4,0.7-0.9,0.9-1.5c0.2-0.6,0.3-1.2,0.3-1.9c0-0.6-0.1-1.2-0.3-1.8s-0.5-1-0.9-1.5c-0.4-0.4-0.9-0.7-1.4-1
		c-0.6-0.2-1.2-0.4-2-0.4h-3.4v9.2H763.3z"
        />
        <g id="T5_1_">
          <path
            className="step-white"
            d="M1115.7,237.3c0.2,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0c0.6,0,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.7,0.8-1.2
			l2.9-8.2h-1.6l-1.6,5.3l-1.8-5.3h-1.8l2.7,6.8c-0.1,0.3-0.2,0.5-0.2,0.7c-0.1,0.2-0.2,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.3-0.1-0.4-0.1V237.3z M1111.4,227.6c0,0-0.1,0-0.1,0h-0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6v-1.5h-1.6v6.8h1.7v-4.1
			c0.2-0.4,0.5-0.7,0.9-0.9s0.9-0.3,1.4-0.3V227.6z M1101.1,233c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.8
			c0.3-0.2,0.7-0.3,1.1-0.3c0.2,0,0.5,0,0.7,0.1c0.2,0,0.5,0.1,0.7,0.2v0.8c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.4,0.3-0.6,0.4
			c-0.3,0.1-0.5,0.2-0.8,0.2C1101.6,233.3,1101.3,233.2,1101.1,233 M1102.8,234.3c0.4-0.2,0.8-0.5,1.1-0.9l0,0.4
			c0,0.2,0.2,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.8,0.2c0.2,0,0.5,0,0.8-0.1V233c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.1
			c0-0.9-0.3-1.6-0.8-2.1c-0.5-0.5-1.3-0.7-2.3-0.7c-1,0-2,0.3-2.8,0.9l0.5,1.1c0.4-0.3,0.7-0.4,1.1-0.6c0.3-0.1,0.7-0.2,1.1-0.2
			c0.5,0,0.9,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1v0.4c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8,0.1-1.2,0.2
			c-0.4,0.1-0.7,0.3-0.9,0.4c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.5,0.7
			s0.5,0.3,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2C1101.9,234.6,1102.4,234.5,1102.8,234.3 M1092.9,230.5c0-0.3,0.1-0.5,0.2-0.7
			c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.6,1.2H1092.9
			z M1096.6,234.1c0.5-0.3,1-0.8,1.2-1.3l-1.5-0.4c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.7-0.1
			s-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.2-0.4-0.2-0.7h5.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.5-0.1-0.9-0.2-1.3
			c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.8c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.8
			c-0.3,0.3-0.5,0.7-0.7,1.1c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.1,0.8
			c0.4,0.2,0.9,0.3,1.5,0.3C1095.4,234.6,1096.1,234.4,1096.6,234.1 M1085.3,233c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.6
			c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0.1-0.8c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.3-0.3,0.6-0.5c0.2-0.1,0.5-0.2,0.7-0.2
			c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.3,0.4,0.4c0.1,0.2,0.2,0.3,0.3,0.5v1.6c-0.1,0.2-0.2,0.3-0.3,0.5
			c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0.1-0.3,0.1-0.5,0.1C1085.8,233.1,1085.5,233.1,1085.3,233
			 M1086.9,234.3c0.4-0.2,0.8-0.5,1-1l0,0.4c0,0.3,0.1,0.5,0.3,0.6c0.2,0.2,0.5,0.2,0.8,0.2c0.2,0,0.4,0,0.7-0.1V233
			c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4V225h-1.7v3.9c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.9-0.4-1.4-0.4
			c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.7,0.4-1,0.8c-0.3,0.3-0.5,0.7-0.6,1.1c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.1,1,0.2,1.4
			c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.6,0.6,1,0.8c0.4,0.2,0.8,0.3,1.3,0.3C1086.1,234.6,1086.5,234.5,1086.9,234.3 M1080.8,225.3
			h-1.8v9.2h1.8V225.3z"
          />
          <path
            id="T5"
            className="step-white"
            d="M1149.2,243.2c0,0-0.1,0-0.1,0h-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
			c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6v-1.5h-1.6v6.8h1.7V246c0.2-0.4,0.5-0.7,0.9-0.9s0.9-0.3,1.4-0.3V243.2z
			 M1138.9,248.6c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.2,0,0.5,0,0.7,0.1
			c0.2,0,0.5,0.1,0.7,0.2v0.8c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.5,0.2-0.8,0.2
			C1139.4,248.9,1139.1,248.8,1138.9,248.6 M1140.6,249.9c0.4-0.2,0.8-0.5,1.1-0.9l0,0.4c0,0.2,0.2,0.4,0.3,0.6
			c0.2,0.1,0.4,0.2,0.8,0.2c0.2,0,0.5,0,0.8-0.1v-1.5c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4V246c0-0.9-0.3-1.6-0.8-2.1
			c-0.5-0.5-1.3-0.7-2.3-0.7c-1,0-2,0.3-2.8,0.9l0.5,1.1c0.4-0.3,0.7-0.4,1.1-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.1,0.4
			c0.3,0.2,0.4,0.6,0.4,1v0.4c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-0.9,0.4
			c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.3,0.7,0.5
			s0.6,0.2,0.9,0.2C1139.7,250.2,1140.2,250.1,1140.6,249.9 M1130.2,250.1h5.7v-1.2h-3.8l3.8-4.5v-1.1h-5.5v1.2h3.6l-3.8,4.5V250.1z
			 M1128.7,240.6h-1.7v1.7h1.7V240.6z M1128.7,243.3h-1.7v6.8h1.7V243.3z M1122.5,248.5c0,0.5,0.1,1,0.4,1.2
			c0.3,0.3,0.7,0.4,1.3,0.4c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.6-0.1,0.8-0.3l-0.2-1.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1
			c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.6v-7.3h-1.7V248.5z M1116.3,248.6c-0.2-0.2-0.3-0.4-0.3-0.7
			c0-0.3,0.1-0.6,0.4-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.2,0,0.5,0,0.7,0.1c0.2,0,0.5,0.1,0.7,0.2v0.8c0,0.2-0.1,0.3-0.3,0.5
			c-0.1,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.5,0.2-0.8,0.2C1116.8,248.9,1116.5,248.8,1116.3,248.6 M1118,249.9c0.4-0.2,0.8-0.5,1.1-0.9
			l0,0.4c0,0.2,0.2,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.8,0.2c0.2,0,0.5,0,0.8-0.1v-1.5c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4
			V246c0-0.9-0.3-1.6-0.8-2.1c-0.5-0.5-1.3-0.7-2.3-0.7c-1,0-2,0.3-2.8,0.9l0.5,1.1c0.4-0.3,0.7-0.4,1.1-0.6
			c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1v0.4c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-0.9-0.1
			c-0.4,0-0.8,0.1-1.2,0.2s-0.7,0.3-0.9,0.4c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9
			c0.1,0.3,0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.3,0.7,0.5s0.6,0.2,0.9,0.2C1117.1,250.2,1117.6,250.1,1118,249.9 M1110,249.9
			c0.5-0.2,0.9-0.6,1.2-1.1l0,0.5c0,0.3,0.1,0.4,0.3,0.6c0.2,0.1,0.4,0.2,0.8,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4-0.1v-1.5
			c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-4.8h-1.7v4.2c-0.2,0.4-0.5,0.7-0.8,0.9c-0.3,0.2-0.6,0.3-1,0.3
			c-0.8,0-1.1-0.5-1.1-1.6v-3.9h-1.7v4.3c0,0.9,0.2,1.6,0.5,2c0.4,0.5,0.9,0.7,1.6,0.7C1109,250.2,1109.6,250.1,1110,249.9
			 M1104.8,248.4c-0.1,0-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.1-0.5,0.1c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.3-0.2-0.5v-3.3h1.4v-1.3
			h-1.4v-2.2h-1.7v2.2h-0.9v1.3h0.9v3.9c0,0.3,0,0.6,0.1,0.8s0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.7,0.1
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.2,0.9-0.3L1104.8,248.4z M1095,248.4c-0.3-0.3-0.6-0.6-0.7-1v-1.6c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.5-0.1,0.8c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.3-0.6,0.5
			c-0.2,0.1-0.5,0.2-0.7,0.2C1095.7,248.7,1095.4,248.6,1095,248.4 M1097.9,249.9c0.4-0.2,0.7-0.4,0.9-0.8c0.3-0.3,0.5-0.7,0.6-1.1
			c0.1-0.4,0.2-0.9,0.2-1.4c0-0.5-0.1-0.9-0.2-1.4c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.3-0.3
			c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.7,0.5-1,0.9v-1.2h-1.5v9.6h1.7v-4c0.2,0.4,0.5,0.7,0.9,1c0.4,0.2,0.9,0.4,1.4,0.4
			C1097.1,250.2,1097.5,250.1,1097.9,249.9 M1085.9,246.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.3-0.3,0.5-0.3
			c0.2-0.1,0.4-0.1,0.7-0.1c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.6,1.2H1085.9z M1089.6,249.7c0.5-0.3,1-0.8,1.2-1.3l-1.5-0.4
			c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5
			c-0.1-0.2-0.2-0.4-0.2-0.7h5.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.5-0.1-0.9-0.2-1.3c-0.2-0.4-0.4-0.8-0.7-1.1
			c-0.3-0.3-0.7-0.6-1.1-0.8c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.8s-0.5,0.7-0.7,1.1
			c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.5,0.3
			C1088.4,250.2,1089.1,250,1089.6,249.7 M1076.7,248c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.5,0.3
			c0.8,0,1.4-0.2,1.9-0.5c0.5-0.3,0.9-0.8,1.2-1.3l-1.7-0.5c-0.1,0.2-0.3,0.4-0.6,0.6c-0.3,0.2-0.6,0.2-0.9,0.2
			c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8
			c0.1-0.3,0.2-0.5,0.4-0.6c0.2-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.6
			l1.7-0.5c-0.3-0.5-0.7-0.9-1.2-1.3c-0.5-0.3-1.2-0.5-1.9-0.5c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.8
			c-0.3,0.3-0.6,0.7-0.7,1.1c-0.2,0.4-0.2,0.9-0.2,1.4C1076.5,247.1,1076.5,247.6,1076.7,248 M1075,245.8c0-0.3,0-0.7-0.1-1
			c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.4-0.5-0.6-0.6c-0.3-0.2-0.6-0.2-1-0.2c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.2-0.8,0.6-1.1,1v-1.3
			h-1.6v6.8h1.7v-4c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.5-0.1
			c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.3,0.7,0.3,1.2v3.8h1.7V245.8z M1062,245.9c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.3-0.3,0.6-0.4
			c0.2-0.1,0.5-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.5,0.1,0.8
			c0,0.3,0,0.6-0.1,0.8c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1
			c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.8C1061.9,246.4,1061.9,246.1,1062,245.9
			 M1065.1,249.9c0.4-0.2,0.8-0.4,1.1-0.8c0.3-0.3,0.5-0.7,0.7-1.1c0.2-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-0.9-0.2-1.4
			c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.8c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.8
			c-0.3,0.3-0.5,0.7-0.7,1.1c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.1,0.9,0.2,1.3c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.6,1.1,0.8
			c0.4,0.2,0.9,0.3,1.5,0.3C1064.2,250.2,1064.7,250.1,1065.1,249.9 M1051.2,247.1c0.2,0.6,0.5,1.1,0.9,1.5c0.4,0.4,0.9,0.8,1.4,1.1
			c0.5,0.3,1.1,0.4,1.8,0.4c0.4,0,0.8,0,1.1-0.1c0.4-0.1,0.8-0.2,1.1-0.4c0.3-0.2,0.7-0.4,0.9-0.7c0.3-0.3,0.5-0.6,0.6-0.9l-1.5-0.8
			c-0.1,0.3-0.2,0.5-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4,0-0.8-0.1-1.1-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.4-0.6-0.6-1c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0.1-0.7,0.2-1.1c0.1-0.4,0.3-0.7,0.5-1
			c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.7-0.3,1.2-0.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7l1.4-0.9c-0.3-0.6-0.8-1.1-1.4-1.5c-0.6-0.4-1.4-0.6-2.2-0.6c-0.7,0-1.4,0.1-1.9,0.4
			c-0.6,0.3-1,0.6-1.4,1.1c-0.4,0.4-0.7,0.9-0.9,1.5c-0.2,0.5-0.3,1.1-0.3,1.7C1050.9,246,1051,246.6,1051.2,247.1"
          />
        </g>
        <path
          id="T6"
          className="step-white"
          d="M1144.8,526.7c0,0-0.1,0-0.1,0h-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6v-1.5h-1.6v6.8h1.7v-4.1c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.9-0.3,1.4-0.3
		V526.7z M1134.5,532.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.2,0,0.5,0,0.7,0.1
		c0.2,0,0.5,0.1,0.7,0.2v0.8c0,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.5,0.2-0.8,0.2
		C1135,532.5,1134.7,532.4,1134.5,532.2 M1136.2,533.5c0.4-0.2,0.8-0.5,1.1-0.9l0,0.4c0,0.2,0.2,0.4,0.3,0.6
		c0.2,0.1,0.4,0.2,0.8,0.2c0.2,0,0.5,0,0.8-0.1v-1.5c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-2.1c0-0.9-0.3-1.6-0.8-2.1
		c-0.5-0.5-1.3-0.7-2.3-0.7c-1,0-2,0.3-2.8,0.9l0.5,1.1c0.4-0.3,0.7-0.4,1.1-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.1,0.4
		c0.3,0.2,0.4,0.6,0.4,1v0.4c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.1-0.7,0.3-0.9,0.4
		c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.3,0.7,0.5
		c0.3,0.1,0.6,0.2,0.9,0.2C1135.3,533.8,1135.8,533.7,1136.2,533.5 M1131.4,531.9c-0.1,0-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.1-0.5,0.1
		s-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.3h1.4v-1.3h-1.4v-2.2h-1.7v2.2h-0.9v1.3h0.9v3.9c0,0.3,0,0.6,0.1,0.8
		c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.7,0.1c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.2,0.9-0.3
		L1131.4,531.9z M1125.9,529.4c0-0.3,0-0.7-0.1-1c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.4-0.5-0.6-0.6c-0.3-0.2-0.6-0.2-1-0.2
		c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.2-0.8,0.6-1.1,1v-1.3h-1.6v6.8h1.7v-4c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.3,0.4-0.4
		c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.3,0.6,0.3,1.2v3.8h1.7V529.4z M1112.8,529.7
		c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.5,0,0.9,0.2,1.2,0.5
		c0.3,0.3,0.5,0.7,0.6,1.2H1112.8z M1116.6,533.3c0.6-0.3,1-0.8,1.2-1.3l-1.5-0.4c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-1,0.3
		c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.2-0.4-0.2-0.7h5.3c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3
		c0-0.5-0.1-0.9-0.2-1.3s-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.8c-0.4-0.2-0.9-0.3-1.5-0.3c-0.6,0-1.1,0.1-1.5,0.3
		c-0.4,0.2-0.8,0.4-1.1,0.8s-0.6,0.7-0.7,1.1c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.4,0.8,0.7,1.1
		s0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.5,0.3C1115.3,533.8,1116,533.6,1116.6,533.3 M1109.6,529.4c0-0.3,0-0.7-0.1-1
		c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.4-0.5-0.6-0.6c-0.3-0.2-0.6-0.2-1-0.2c-0.5,0-1,0.1-1.5,0.4c-0.4,0.2-0.8,0.6-1.1,1.1
		c-0.1-0.4-0.3-0.8-0.6-1c-0.3-0.3-0.8-0.4-1.3-0.4c-0.6,0-1.1,0.1-1.5,0.4c-0.4,0.2-0.8,0.6-1,1v-1.3h-1.6v6.8h1.7v-4
		c0.1-0.4,0.3-0.8,0.7-1c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.3,0.6,0.3,1.2v3.8h1.7v-4c0.1-0.4,0.3-0.8,0.7-1
		c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.6,0.1,0.8,0.4c0.2,0.3,0.3,0.6,0.3,1.2v3.8h1.7V529.4z M1097.1,524.1h-1.7v1.7h1.7V524.1z
		 M1097.1,526.8h-1.7v6.8h1.7V526.8z M1094.1,526.7c0,0-0.1,0-0.1,0h-0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6v-1.5h-1.6v6.8h1.7v-4.1c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.9-0.3,1.4-0.3
		V526.7z M1083.3,529.7c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1
		c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.6,1.2H1083.3z M1087,533.3c0.5-0.3,1-0.8,1.2-1.3l-1.5-0.4c-0.1,0.3-0.3,0.5-0.6,0.7
		c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.2-0.4-0.2-0.7h5.3
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.5-0.1-0.9-0.2-1.3s-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.8c-0.4-0.2-0.9-0.3-1.5-0.3
		c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.4-1.1,0.8s-0.6,0.7-0.7,1.1c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.1,0.9,0.3,1.3
		s0.4,0.8,0.7,1.1s0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.5,0.3C1085.8,533.8,1086.5,533.6,1087,533.3 M1075.8,531.9
		c-0.3-0.3-0.6-0.6-0.7-1v-1.6c0.1-0.2,0.1-0.3,0.3-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.1
		c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.5-0.1,0.8
		c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.3-0.6,0.5c-0.2,0.1-0.5,0.2-0.7,0.2C1076.4,532.3,1076.1,532.2,1075.8,531.9
		 M1078.6,533.5c0.4-0.2,0.7-0.4,0.9-0.8c0.3-0.3,0.5-0.7,0.6-1.1c0.1-0.4,0.2-0.9,0.2-1.4c0-0.5-0.1-0.9-0.2-1.4
		c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.6-0.6-1-0.8c-0.4-0.2-0.8-0.3-1.3-0.3c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.7,0.5-1,0.9v-1.2
		h-1.5v9.6h1.7v-4c0.2,0.4,0.5,0.7,0.9,1c0.4,0.2,0.9,0.4,1.4,0.4C1077.8,533.8,1078.2,533.7,1078.6,533.5 M1065.4,526.8l2.4,3.4
		l-2.5,3.4h1.8l1.5-2.1l0.1-0.2l0.1,0.2l1.5,2.1h1.8l-2.5-3.4l2.4-3.4h-1.8l-1.5,2.1l-0.1,0.2l-0.1-0.2l-1.5-2.1H1065.4z
		 M1059.9,532.1v-2.4h3.9v-1.5h-3.9V526h4.5v-1.6h-6.3v9.2h6.4v-1.6H1059.9z"
        />
      </g>
    </svg>
  );
};

export default StepsSvgDesktop;
