import React from 'react';
import { Button } from 'reactstrap';

const ButtonRemoveUser = ({ user, email, toggleModal }) => {
  return (
    <Button
      color="link"
      onClick={() => toggleModal(user, email)}
      className="pt-1"
    >
      <i className="far fa-window-close user-del" />
    </Button>
  );
};

export default ButtonRemoveUser;
