import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ModalUnsubscribePlan from './ModalUnsubscribePlan';

const MyBilling = ({ data, labels, loading, currentLang }) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };

  const DateF = ({ date }) => {
    const dt = new Date(date);
    console.debug('date', date, dt);
    return (
      <>
        {`${dt.getDate().toString().padStart(2, '0')}-${(dt.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${dt
          .getFullYear()
          .toString()
          .padStart(4, '0')} ${dt
          .getHours()
          .toString()
          .padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`}
      </>
    );
  };

  return (
    <div className="container pl-4 pr-4 pt-2 pb-2">
      {!loading && data ? (
        data && data !== {} && data.description ? (
          <>
            <h5 className="font-weight-bold">
              {labels.billingMyPlan ? labels.billingMyPlan : 'NULL'}
            </h5>
            <h6 className="mb-0">
              {labels.payMethodMyPlan ? labels.payMethodMyPlan : 'NULL'}
            </h6>
            <div className="mb-2">
              <span className="font-weight-bold">{data.method}</span>
            </div>
            <h6 className="mb-0">
              {labels.periodBillingMyPlan ? labels.periodBillingMyPlan : 'NULL'}
            </h6>
            <div>
              <span className="font-weight-bold">
                {data.months_of_duration < 12
                  ? labels.monthlyMyPlan
                  : labels.yearlyMyPlan}
              </span>
            </div>
            <h6 className="mb-0">
              {labels.endDatePlan ? labels.endDatePlan : 'NULL'}
            </h6>
            <div className="mb-2">
              <span className="font-weight-bold">
                {data.end_date ? <DateF date={data.end_date} /> : ''}
              </span>
            </div>
            <div>
              {data.active && (
                <Button
                  className="btn-plan-yellow font-weight-bold text-uppercase text-center mt-2"
                  onClick={() => toggleModal(!open)}
                >
                  {labels.unsubscribeBillingPlan || 'NULL'}
                </Button>
              )}
            </div>

            <ModalUnsubscribePlan
              open={open}
              toggle={toggleModal}
              labels={labels}
            />
          </>
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </div>
  );
};

export default MyBilling;
