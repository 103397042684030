import React from 'react';
import MarkdownView from 'react-showdown';
import TitleIcon from '../task/TitleIcon';

const Section = ({ title, icon, theme, content }) => {
  return (
    <div className="detail-task-section">
      <>
        <TitleIcon icon={icon} theme={theme} />
        <span className="detail-task-section-title">{title}</span>
      </>
      <>
        <MarkdownView
          className="detail-task-section-content"
          markdown={content}
          options={{ openLinksInNewWindow: true }}
        />
      </>
    </div>
  );
};

export default Section;
