import React from 'react';
import MarkdownView from 'react-showdown';
import { MethodologyList } from './index';

const Methodology = ({ data }) => {
  const { title, sub_title, methodology_list } = data;
  return (
    <div className="methodology p-3">
      <div className="h4 text-center">{title}</div>
      <div className="text-center">
        <MarkdownView
          markdown={sub_title}
          className="before-starting-content"
        />
      </div>
      <hr className="landing-hr m-2 p-1" />

      {methodology_list && methodology_list.length > 0 && (
        <MethodologyList data={methodology_list} />
      )}
    </div>
  );
};

export default Methodology;
