import React, { useState, useEffect } from 'react';

import DiscoverButton from './DiscoverButton';
import Card from './card';
import TitleIcon from '../../task/TitleIcon';

const HowTo = ({
  interface: { title, nextButton },
  content,
  theme,
  currentLang,
  proRole,
}) => {
  const [hasDone, setHasDone] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const onClickNextStep = () => {
    setCurrentStep(currentStep + 1);
    const hideButton = currentStep >= content.length - 1 ? true : false;
    console.log(currentStep, content.length, hideButton);
    setHasDone(hideButton);
  };

  useEffect(() => {
    content.length <= 1 && setHasDone(true);
  }, [hasDone, setHasDone]);

  const renderCard = step => {
    const last = content[content.length - 1].letter;
    return (
      <Card
        key={step.letter}
        last={last}
        {...step}
        theme={theme}
        currentLang={currentLang}
        proRole={proRole}
      />
    );
  };
  return (
    <div className="how-to-container">
      <div className="how-to-content">
        <div className="how-to-title">
          <TitleIcon icon="howto" theme="black" />
          <span className="detail-task-section-title">{title}</span>
        </div>
        <div>
          {content &&
            content.slice(0, currentStep).map(step => renderCard(step))}
          {!hasDone && (
            <DiscoverButton label={nextButton} onClick={onClickNextStep} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HowTo;
