import React from 'react';

import BaseLayout from './BaseLayout';
import { Error, Loading, Header } from '../components/layout';
import { Container } from '../components/glossary';

const GlosarioLayout = ({ data, loading, error, letter }) => {
  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <Container data={data} letter={letter} />
    );
  }
  return (
    <BaseLayout title="(defi)² - Glosario">
      <div className="p-2">
        <Header to="/menu" previous={`/glosario/${letter}`} logo="amarillo" />
        <div className="container-fluid p-3">{content}</div>
      </div>
    </BaseLayout>
  );
};

export default GlosarioLayout;
