import React from 'react';

const Paper = props => {
  const {
    author,
    year,
    title_paper,
    title_journal,
    volume,
    pages,
    doi,
    url,
  } = props;
  return (
    <li>
      <div className="quote">
        <span className="paper">
          {
            // autor
            author && author
          }
          {
            // año
            year && ` (${year}). `
          }
          {
            // titulo de paper
            title_paper && `${title_paper}. `
          }
          {
            // titulo de journal
            title_journal && <em>{`${title_journal}, `}</em>
          }
          {
            // volume
            volume && `${volume}, `
          }
          {
            // paginas
            pages && `${pages}. `
          }
          {
            // doi
            doi && `doi: ${doi}.`
          }
        </span>
        {
          // url
          url && (
            <a className="quote-url" href={url}>
              Link
            </a>
          )
        }
      </div>
    </li>
  );
};

export default Paper;
