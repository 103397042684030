import React, { useEffect, useState } from 'react';

import BaseLayout from './BaseLayout';
import { Error, Footer, Header, Loading } from '../components/layout';

import { Plans } from '../components/plans';

const PlanesLayout = ({
  data,
  labels,
  loading,
  error,
  currentLang,
  enabledPlan = null,
}) => {
  const [content, setContent] = useState('');
  const [ok, setOk] = useState(false);
  // let content = null;
  useEffect(() => {
    if (error) {
      setContent(<Error msg={error} />);
      setOk(true);
    } else {
      setContent(
        loading ? (
          <div className="p-5 text-center">
            <Loading iconColor="blanco" />
          </div>
        ) : (
          <Plans
            data={data}
            labels={labels}
            loading={loading}
            currentLang={currentLang}
            enabledPlan={enabledPlan}
          />
        )
      );
      setOk(true);
    }
  }, [loading, data]);
  return (
    <BaseLayout title="(defi)² - Planes">
      <div className="plans background-defi-default">
        <Header to="/menu" previous={'/planes'} logo="blanco" />
        {ok && content}
      </div>
      <Footer />
    </BaseLayout>
  );
};

export default PlanesLayout;
