import React, { useState } from 'react';
import { Button } from 'reactstrap';

import { useAddUserGroup } from '../../hooks';
import ModalAddUser from './ModalAddUser';

const ButtonAddUser = ({ data, labels, setSearchData }) => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    handleChange,
    message,
    email,
    loadingHook,
  } = useAddUserGroup({ setOpen, setSearchData });

  const toggleModal = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="text-center">
        <Button color="link" onClick={() => toggleModal()} className="user-add">
          {labels.addUserTitleGroupAccount
            ? labels.addUserTitleGroupAccount
            : 'NULL'}
        </Button>
      </div>
      <ModalAddUser
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        open={open}
        toggle={toggleModal}
        plan={data}
        email={email}
        labels={labels}
        message={message}
        loadingHook={loadingHook}
      />
    </>
  );
};

export default ButtonAddUser;
