import React from 'react';
import MarkdownView from 'react-showdown';

const WhyList = ({ data }) => {
  return (
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-12 col-lg-6">
          <ul className="list-unstyled">
            {data &&
              data.map((why, key) => (
                <li className="text-left" key={key}>
                  <div className="icon-why">
                    {why.icon && (
                      <img
                        src={process.env.STRAPI_URL + why.icon.url}
                        className="img-result rounded-circle mx-auto d-block"
                        alt="why list"
                      />
                    )}
                  </div>
                  <div className="text-why text-left font-weight-bold">
                    {why.title}
                  </div>
                  <div className="text-why">
                    <MarkdownView
                      markdown={why.sub_title}
                      className="before-starting-content"
                    />
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WhyList;
