import React from 'react';

const Title = ({ name }) => {
  return (
    <div className="detail-task-card-header-title">
      {name.split(' ').length > 1 ? (
        <>
          <h5 className="text-center">{name.split(' ')[0]}</h5>
          <h5 className="bold text-center">
            {name
              .split(' ')
              .map((val, i) => {
                if (i === 0) {
                  return '';
                }
                return val;
              })
              .join(' ')}
          </h5>
        </>
      ) : (
        <h5 className="bold">{name}</h5>
      )}
    </div>
  );
};

export default Title;
