import React from 'react';
import { Button, Form, FormGroup, Input, Label, Alert } from 'reactstrap';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { Link } from 'gatsby';

import { Loading } from '../../components/layout';

import { useCheckInForm } from '../../hooks';
import MarkdownView from 'react-showdown';

const CheckInForm = ({ labels, currentLang }) => {
  const {
    inputs,
    message,
    error,
    loading,
    registerOk,
    handleInputChange,
    handleSubmit,
    handleVerifyCaptcha,
  } = useCheckInForm({ labels, currentLang });

  return (
    <div className="password-recovery-container pt-5">
      <h2 className="font-weight-bold text-center">
        {labels.titleCheckIn || 'NULL'}
      </h2>
      <p>{labels.subtitle}</p>
      {!registerOk && (
        <>
          <Form onSubmit={handleSubmit}>
            <Label>{labels.nameCheckIn || 'NULL'}</Label>
            <FormGroup>
              <Input
                type="text"
                name="name"
                required
                value={inputs.name}
                disabled={loading}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>{labels.emailCheckIn || 'NULL'}</Label>
              <Input
                type="email"
                name="email"
                required
                value={inputs.email}
                disabled={loading}
                onChange={handleInputChange}
              />
            </FormGroup>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
              <GoogleReCaptcha onVerify={handleVerifyCaptcha} />
            </GoogleReCaptchaProvider>
            <div className="text-center">
              {loading ? (
                <Loading />
              ) : (
                <Button
                  type="submit"
                  className="btn-general-white text-uppercase"
                >
                  {labels.buttonCheckIn || 'NULL'}
                </Button>
              )}
            </div>
          </Form>
          <div className="registro-terms col-12">
            <p className="">
              {labels.hasAccountLabel || 'hasAccountLabel'}&nbsp;
              <Link to="/login">
                {labels.linkLoginLabel || 'linkLoginLabel'}
              </Link>
            </p>
          </div>
        </>
      )}
      {message ? (
        <Alert color="info" className="mt-2 text-center">
          {message}
        </Alert>
      ) : (
        ''
      )}

      {error ? (
        <Alert color="danger" className="mt-2 text-center">
          {error}
        </Alert>
      ) : (
        ''
      )}
      <MarkdownView
        className="registro-terms"
        markdown={labels.acceptTerms}
        options={{ openLinksInNewWindow: true }}
      />
    </div>
  );
};

export default CheckInForm;
