import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Loading } from '../../components/layout';

import { useMyData } from '../../hooks';

const Profile = ({ interfaceContent, data }) => {
  const {
    inputs,
    message,
    loading,
    handleInputChange,
    handleSubmit,
  } = useMyData({ data: data, labels: interfaceContent });
  return (
    <div className="container pl-4 pr-4 pt-0 pb-1">
      <h2 className="text-center font-weight-bold">
        {interfaceContent.myProfileTitleCount || 'NULL'}
      </h2>

      <h5 className="font-weight-bold mb-2 pl-2">
        {interfaceContent.myProfileCount || 'NULL'}
      </h5>

      <Form onSubmit={handleSubmit}>
        <Label className="mb-0 pl-2">
          {interfaceContent.myNameCount || 'NULL'}
        </Label>
        <FormGroup>
          <Input
            className="only-border-btn pt-0 pl-2"
            type="text"
            name="name"
            required
            value={inputs.name}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label className="mb-0 pl-2">
            {interfaceContent.myEmailCount || 'NULL'}
          </Label>
          <Input
            className="only-border-btn pt-0 pl-2"
            type="email"
            name="email"
            value={inputs.email}
            onChange={handleInputChange}
            disabled
          />
        </FormGroup>
        {loading ? (
          <Loading iconColor="amarillo" className="text-center" />
        ) : (
          <Button type="submit" className="btn-mycount-yellow">
            {interfaceContent.myProfileSave || 'NULL'}
          </Button>
        )}
      </Form>
    </div>
  );
};

export default Profile;
