import React from 'react';

import MenuItem from './MenuItem';
import GlossaryMenuItem from './GlossaryMenuItem';
import LoginMenuItem from './LoginMenuItem';
import LoginMenuItemTop from './LoginMenuItemTop';

const Container = ({
  data,
  glossary,
  menuStore,
  loginMenu,
  isLogged,
  config,
}) => {
  return (
    <div className="d-flex flex-column align-items-start pl-3">
      {loginMenu && (
        <LoginMenuItemTop loginMenu={loginMenu} isLogged={isLogged} />
      )}
      {data &&
        data.map(({ title, uid, subMenu }, i) => {
          let isCollapse = false;
          if (!menuStore.lastUid) {
            isCollapse = i === 0 ? true : false;
          } else {
            isCollapse = menuStore.lastUid === uid ? true : false;
          }
          return (
            <MenuItem
              key={i}
              title={title}
              uid={uid}
              subItems={subMenu}
              isCollapse={isCollapse}
              subMenuId={menuStore.lastId}
            />
          );
        })}
      {glossary && (
        <GlossaryMenuItem
          title={glossary.title}
          uid={glossary.uid}
          subItems={glossary.subMenu}
        />
      )}
      {loginMenu && (
        <LoginMenuItem
          loginMenu={loginMenu}
          isLogged={isLogged}
          config={config}
        />
      )}
    </div>
  );
};

export default Container;
