import React from 'react';

import BaseLayout from './BaseLayout';
import { Footer, Loading, Error } from '../components/layout';
import { Logo, Carousel } from '../components/splash';

const SplashLayout = ({ loading, data, error, onChangeLanguage }) => {
  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading />
      </div>
    ) : (
      <div className="splash-container">
        <Carousel data={data} onChangeLanguage={onChangeLanguage} />
      </div>
    );
  }

  return (
    <BaseLayout title="(defi)² - Discovery Driven, Empathy Enabled, Failure Fueled, Igniting Impact">
      <section className="splash background-defi-default">
        <div className="container">
          <Logo />
          {content}
        </div>
      </section>
      <Footer />
    </BaseLayout>
  );
};

export default SplashLayout;
