import React from 'react';

import BaseLayout from './BaseLayout';
import { Header, Footer, Loading, Error } from '../components/layout';
import { Title, Content, Button } from '../components/beforeStarting';

const AntesComenzarLayout = ({ loading, data, error, toLastStepTask }) => {
  const { interfaces, contents } = data;
  let content = null;
  if (error) {
    content = <Error msg={error} />;
  } else {
    content = loading ? (
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <Loading iconColor="amarillo" />
      </div>
    ) : (
      <div className="container before-starting-container">
        <Title title={contents.title} />
        <Content paragraph={contents.paragraph} />
        <Button label={interfaces.btn} toLastStepTask={toLastStepTask} />
      </div>
    );
  }
  return (
    <BaseLayout title="(defi)² - antes de comenzar">
      <div
        className="d-flex flex-column background-proceso"
        style={{ minHeight: '90vh' }}
      >
        <Header to="/menu" previous="/antes-de-comenzar" logo="amarillo" />
        {content}
      </div>

      <Footer />
    </BaseLayout>
  );
};

export default AntesComenzarLayout;
