export const tareasSinDetalle = [22, 36, 39];
export const maxPreviewTextLength = 400;
export const stepsColors = [
  { number: 1, color: 'black' },
  { number: 2, color: 'teal' },
  { number: 3, color: 'blue' },
  { number: 4, color: 'magenta' },
  { number: 5, color: 'red' },
  { number: 6, color: 'orange' },
];
