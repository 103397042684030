import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import MarkdownView from 'react-showdown';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import Logo from '../layout/Logo';

const ModalCongratulations = ({ open, toggle, labels }) => {
  const [title, setTitle] = useState(null);
  const [logo, setLogo] = useState(null);
  const logosConf = useSelector(({ config }) => config.logos);

  useEffect(() => {
    setTitle(labels.CongratTitleModal || '');
    if (logosConf) {
      setLogo(process.env.STRAPI_URL + logosConf.blanco.url);
    }
  }, []);

  return (
    <Modal isOpen={open} toggle={toggle} className="congrat-modal modal-lg">
      <ModalHeader
        className="text-center pt-1 pb-1"
        toggle={toggle}
      ></ModalHeader>
      <ModalBody>
        <div className="text-center">
          <Logo icono="blanco" />
        </div>

        {title && (
          <div
            className={`text-center font-weight-bold h1 ${
              title.charAt(0) === '¡' ? 'first-white' : ''
            } ${title.charAt(title.length - 1) === '!' ? 'last-white' : ''}`}
          >
            {title.replace('¡', '').replace('!', '')}
          </div>
        )}

        {labels.CongratTitleModal && (
          <div className="text-center">{labels.CongratSubTitleModal}</div>
        )}

        <div className="congrat-line"></div>
        <MarkdownView
          markdown={labels.CongratContentModal || 'CongratContentModal'}
          className="before-starting-content text-center"
        />
        {labels.CongratBtnModal && labels.CongratBtnModal.length > 1 && (
          <div className="text-center p-1 pb-3">
            <Link
              className="btn btn-general-yellow text-uppercase"
              to="#"
              onClick={() => toggle()}
            >
              {labels.CongratBtnModal}
            </Link>
          </div>
        )}
      </ModalBody>
      {/* <ModalFooter>
        <div className="text-justify font-italic">
        </div>
      </ModalFooter> */}
    </Modal>
  );
};

export default ModalCongratulations;
